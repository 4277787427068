import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ModalService } from '@app/shared/components/modal/modal.service';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { setCurrencyPairName } from '@app/authenticated/trading-platform/store/trading-platform.actions';
import { TradingPlatformState } from '@app/authenticated/trading-platform/store/trading-platform.state';
import { selectCurrencyPair } from '@app/authenticated/trading-platform/store/trading-platform.selectors';
import { Balances } from '@app/shared/store/balances/balances-facade.service';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { ContextTradeCurrencyModalComponent } from '@app/shared/components/bar/context-trade-currency-modal/context-trade-currency-modal.component';
import { CurrencyPairSelectedDto } from '@app/generated/models/currency-pair-selected-dto';

const CURRENCY_MODAL_ID = 'tradeCurrencyModal';

@Component({
  selector: 'app-context-trade-currency-bar',
  templateUrl: './context-trade-currency-bar.component.html',
  styleUrls: ['./context-trade-currency-bar.component.scss'],
})
export class ContextTradeCurrencyBarComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  currencyPair$ = this.store.select(selectCurrencyPair);

  currencyBalancesForSelectedPair$ = combineLatest([
    this.currencyPair$,
    this.balancesFacade.currencyBalancesWithEquivalents$,
  ]).pipe(
    map(([currencyPair, balances]) => {
      const currencyBalancesForSelectedPair = currencyPair
        ? balances
            .filter(({ name }) => name === currencyPair.firstCurrency || name === currencyPair.secondCurrency)
            .sort((a, b) => {
              return a.name === currencyPair.firstCurrency || b.name === currencyPair.firstCurrency ? 1 : 0;
            })
        : null;
      return currencyBalancesForSelectedPair?.length ? currencyBalancesForSelectedPair : null;
    }),
  );

  private unsubscribe$ = new Subject<void>();
  private eventSubscription = Subscription.EMPTY;

  constructor(
    private store: Store<TradingPlatformState>,
    private modalService: ModalService,
    private guiParams: GuiParams,
    private userInfo: UserInfo,
    private balancesFacade: Balances,
  ) {}

  ngOnInit(): void {
    this.guiParams.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;

      if (this.isLoggedIn) {
        this.balancesFacade.load();
      }
    });

    this.eventSubscription = this.userInfo.eventEmitter.subscribe((data) => {
      this.balancesFacade.load();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.eventSubscription.unsubscribe();
  }

  openModal() {
    this.modalService.openDynamicModal(CURRENCY_MODAL_ID, ContextTradeCurrencyModalComponent, {
      balances: this.balancesFacade.currencyBalancesWithEquivalents$,
    });
  }

  setCurrencyPair(currencyPair: CurrencyPairSelectedDto) {
    this.store.dispatch(setCurrencyPairName({ currencyPairName: currencyPair.name }));
  }
}
