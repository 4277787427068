import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Location } from '@angular/common';
import { ApiModule } from './generated/api.module';
import { environment } from './environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { supportedLanguages } from '@app-shared/const/locales.const';
import { ToastrModule } from 'ngx-toastr';
import { NgxCookiebotComponent, NgxCookiebotConfig, ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AlertMessageComponent } from '@app/shared/components/alert-message/alert-message.component';
import { default as pkg } from 'package.json';
import { BasicLayoutComponent } from '@app/layout/basic-layout/basic-layout.component';
import { DefaultLayoutComponent } from '@app/layout/default-layout/default-layout.component';
import { SseListenerService } from '@app/shared/services/sse-listener.service';
import { SecuredLayoutComponent } from '@app/layout/secured-layout/secured-layout.component';
import { SentryErrorHandlerService } from '@app/shared/services/sentry-error-handler.service';
import { AddHeadersInterceptor } from '@app/shared/interceptors/add-headers.interceptor';
import { DepositWithdrawalHttpInterceptor } from '@app/authenticated/deposits-withdrawals/interceptors/deposit-withdrawal.interceptor';
import { TradingPlatformLayoutComponent } from '@app/layout/trading-platform-layout/trading-platform-layout.component';
import { SecurityHttpInterceptor } from '@app/authenticated/security/interceptor/security.interceptor';

declare global {
  interface Window {
    cmConfig: { gtmId: string };
  }
}

const isBrowser = typeof window !== 'undefined';

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, environment.baseUrl + '/assets/i18n/', '.json?v=' + pkg.version);

class AppCookiebotConfig extends NgxCookiebotConfig {
  blockingMode = 'auto';
  cbId = '04440ac0-2c61-4103-9aff-7cb59de69ccc';
  loadScript = true;
  cdn = 'eu';
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    BasicLayoutComponent,
    SecuredLayoutComponent,
    TradingPlatformLayoutComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ApiModule.forRoot({ rootUrl: environment.apiRoot }),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: TimeagoIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate: any, location: any, settings: any) =>
          new ManualParserLoader(
            translate,
            location,
            settings,
            supportedLanguages.map((language) => language.key),
            'ROUTE',
          ),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      cacheMechanism: CacheMechanism.LocalStorage,
    }),
    ToastrModule.forRoot({
      timeOut: 20000,
      closeButton: true,
      preventDuplicates: true,
      enableHtml: true,
      resetTimeoutOnDuplicate: true,
      toastComponent: AlertMessageComponent,
    }),
  ],
  providers: [
    // GTM ID in index.html
    { provide: 'googleTagManagerId', useValue: isBrowser ? window.cmConfig.gtmId : 'GTM-W489JX' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      multi: true,
    },
    ngxCookiebotProvider(AppCookiebotConfig),
    {
      provide: SseListenerService,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DepositWithdrawalHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityHttpInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
