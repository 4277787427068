import { DestroyRef, Directive, inject, Injector, Input, SimpleChanges } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { filter } from 'rxjs/operators';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseTooltipDirective } from '@app/shared/components/tooltip/base-tooltip.directive';
import {
  TOOLTIP,
  TOOLTIP_CONTENT,
  TOOLTIP_ID,
  TOOLTIP_TRIGGER,
  PlainTooltipComponent,
  TooltipContent,
} from '@app/shared/components/tooltip/plain-tooltip/plain-tooltip.component';
import { FLEXIBLE_POSITIONS, VIEWPORT_MARGIN } from '@app/shared/components/tooltip/tooltip.const';

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class PlainTooltipDirective extends BaseTooltipDirective<PlainTooltipComponent> {
  @Input() appTooltip!: TooltipContent;

  private destroyRef = inject(DestroyRef);

  private updateTooltipContent(tooltipContent: TooltipContent) {
    if (this.isTooltipAttached() && this.tooltipComponentRef?.instance.updateTooltipContent) {
      this.tooltipComponentRef.instance.updateTooltipContent(tooltipContent);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appTooltip && !changes.appTooltip.firstChange) {
      this.updateTooltipContent(changes.appTooltip.currentValue);
    }
  }

  createTooltip() {
    if (this.isTooltipAttached() || !this.appTooltip) {
      return;
    }

    if (this.overlayRef) {
      this.destroyTooltip();
    }

    let flexiblePositions = Object.values(FLEXIBLE_POSITIONS);
    if (this.tooltipPosition && this.tooltipPosition in FLEXIBLE_POSITIONS) {
      const { [this.tooltipPosition]: preferredFlexiblePosition, ...preferredFlexiblePositions } = FLEXIBLE_POSITIONS;
      flexiblePositions = [
        preferredFlexiblePosition,
        ...Object.values(preferredFlexiblePositions),
      ] as ConnectedPosition[];
    }

    const isTooltipTriggeredByClick = this.isTooltipTriggeredByClick();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions(flexiblePositions)
      .withViewportMargin(VIEWPORT_MARGIN)
      .withPush(true);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      backdropClass: 'tooltip-backdrop',
      hasBackdrop: isTooltipTriggeredByClick,
      scrollStrategy: isTooltipTriggeredByClick
        ? this.overlay.scrollStrategies.block()
        : this.overlay.scrollStrategies.close(),
    });

    this.overlayRef
      .outsidePointerEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.destroyTooltip.bind(this));

    this.overlayRef
      .keydownEvents()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event.key === 'Escape'),
      )
      .subscribe(this.destroyTooltip.bind(this));

    const tooltipInjector = Injector.create({
      providers: [
        { provide: TOOLTIP_CONTENT, useValue: this.appTooltip },
        { provide: TOOLTIP_ID, useValue: this.tooltipId },
        { provide: TOOLTIP_TRIGGER, useValue: this.elementRef },
        { provide: TOOLTIP, useValue: this.overlayRef },
      ],
      parent: this.injector,
    });

    this.tooltipComponentPortalRef = new ComponentPortal(PlainTooltipComponent, this.viewContainerRef, tooltipInjector);
    this.tooltipComponentRef = this.overlayRef.attach(this.tooltipComponentPortalRef);
  }
}
