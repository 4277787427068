import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TrackingParametersComponent } from '@app/unauthenticated/trackingParameters.component';

@NgModule({
  declarations: [TrackingParametersComponent],
  imports: [TranslateModule.forChild()],
  exports: [TrackingParametersComponent],
})
export class ReferralClickIdModule {}
