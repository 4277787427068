<button
  *ngIf="type === languageSwitchTypeEnum.footer"
  (click)="switchLanguage()"
  class="language-switch-button language-switch-button-footer"
>
  <ng-container *ngTemplateOutlet="flag"></ng-container>
  {{ currentLang !== 'cs' ? 'English' : 'Česky' }}
</button>
<button
  *ngIf="type === languageSwitchTypeEnum.menu"
  [appTooltip]="currentLang === 'cs' ? 'Česky' : 'English'"
  [disabledOnMobile]="true"
  (click)="switchLanguage()"
  class="language-switch-button"
>
  <ng-container *ngTemplateOutlet="flag"></ng-container>
</button>
<button
  *ngIf="type === languageSwitchTypeEnum.menuMobile"
  [appTooltip]="currentLang === 'cs' ? 'Česky' : 'English'"
  [tooltipVisibilityThreshold]="'lg'"
  [disabledOnMobile]="true"
  (click)="switchLanguage()"
  class="language-switch-button"
>
  <ng-container *ngTemplateOutlet="flag"></ng-container>
  <span class="link-with-icon-text">{{ currentLang !== 'cs' ? 'English' : 'Česky' }}</span>
</button>

<ng-template #flag>
  <span class="language-switch-flag">
    <svg-icon src="/assets/icons/32/bg-shape.svg" class="language-switch-flag-bg"></svg-icon>
    <app-theme-image
      [srcLight]="currentLang === 'cs' ? '/assets/icons/16/flag-czech.svg' : '/assets/icons/16/flag-english.svg'"
      [srcDark]="
        currentLang === 'cs' ? '/assets/icons/16/flag-czech-dark.svg' : '/assets/icons/16/flag-english-dark.svg'
      "
      [alt]="currentLang"
      class="language-switch-flag-icon"
    ></app-theme-image>
  </span>
</ng-template>
