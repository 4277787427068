export enum TooltipPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
  leftBottom = 'leftBottom',
  leftTop = 'leftTop',
  center = 'center',
  rightBottom = 'rightBottom',
  rightTop = 'rightTop',
}

export enum TooltipTriggerMode {
  click = 'click',
  hover = 'hover',
}

export enum TooltipPositionX {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum TooltipPositionY {
  top = 'top',
  bottom = 'bottom',
}

export enum TooltipVisibilityThreshold {
  sm = 640,
  md = 800,
  lg = 1048,
  xl = 1248,
}
