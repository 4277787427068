import { CurrencyPairWithStatsDto } from '@app/generated/models/currency-pair-with-stats-dto';

export const CURRENCY_COLOR = Object.freeze({
  CZK: '#C03221',
  EUR: '#203395',
  USDT: '#50AF95',
  BTC: '#F7931A',
  ETH: '#284775',
  ADA: '#0033AD',
  SOL: '#AC50EB',
  LTC: '#345D9D',
  XRP: '#23292F',
  DOGE: '#C3A634',
  BNB: '#F3BA2F',
  AVAX: '#E84142',
  LINK: '#2A5ADA',
  DOT: '#E6007A',
  NEAR: '#737373',
  FIL: '#0090FF',
  WIF: '#DF9B8B',
  S: '#FE9A4D',
  INJ: '#01ECFE',
  ATOM: '#01ECFE',
  UNI: '#FF007A',
  IO: '#373737',
  JTO: '#000000',
  PEPE: '#3D8130',
  SHIB: '#FFAA35',
  TRX: '#FC070C',
  POL: '#6C00F6',
  WLD: '#4C4C4C',
  USDC: '#2775CA',
  OTHER: '#97989F',
});

// This is used as a fallback in case the currencies info is not loaded into the shared store
export const CURRENCY_ROUNDING: { [key: string]: number } = Object.freeze({
  CZK: 2,
  EUR: 2,
  BTC: 8,
  USDT: 2,
  ETH: 8,
  ADA: 6,
  SOL: 8,
  LTC: 8,
  XRP: 8,
  BCH: 8,
  DASH: 8,
  DAI: 2,
  DOGE: 8,
  BNB: 8,
  AVAX: 8,
  LINK: 8,
  DOT: 8,
  NEAR: 8,
  FIL: 8,
  WIF: 8,
  S: 8,
  INJ: 8,
  ATOM: 8,
  UNI: 8,
  IO: 8,
  JTO: 8,
  PEPE: 8,
  SHIB: 8,
  TRX: 8,
  POL: 8,
  WLD: 8,
});

export const MAX_VISIBLE_CURRENCIES = 10;

export const CURRENCY_DETAIL_URL: Readonly<Partial<Record<CurrencyPairWithStatsDto['firstCurrency'], string>>> =
  Object.freeze({
    ADA: 'cardano-kurz',
    ATOM: 'cosmos-kurz',
    AVAX: 'avalanche-kurz',
    BNB: 'binance-coin-kurz',
    BTC: 'bitcoin-kurz',
    DOGE: 'dogecoin-kurz',
    DOT: 'polkadot-kurz',
    ETH: 'ethereum-kurz',
    FIL: 'filecoin-kurz',
    S: 'sonic-fantom-kurz',
    INJ: 'injective-kurz',
    IO: 'io-net-kurz',
    JTO: 'jito-kurz',
    LINK: 'chainlink-kurz',
    LTC: 'litecoin-kurz',
    NEAR: 'near-protocol-kurz',
    PEPE: 'pepe-kurz',
    POL: 'polygon-kurz',
    SHIB: 'shiba-inu-kurz',
    SOL: 'solana-kurz',
    TRX: 'tron-kurz',
    UNI: 'uniswap-kurz',
    USDT: 'usdt-kurz',
    USDC: 'usdc-kurz',
    WIF: 'dogwifhat-kurz',
    WLD: 'worldcoin-kurz',
    XRP: 'ripple-kurz',
  });
