import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { HomepageModule } from '@app/unauthenticated/homepage/homepage.module';

interface TranslatableText {
  cs: string;
  en: string;
}

interface BasePlatform {
  logo: string;
  logoDark: string;
  logoHeightPx?: number;
  name: string;
}

interface DomesticPlatform extends BasePlatform {
  fees: TranslatableText;
  costExample: TranslatableText;
}

interface ForeignPlatform extends BasePlatform {
  exchangeFees: TranslatableText;
  bankFees: TranslatableText;
  totalCost: TranslatableText;
  costExample: TranslatableText;
}
type Language = 'cs' | 'en';

@Component({
  selector: 'app-compare',
  standalone: true,
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss'],
  imports: [CommonModule, SharedModule, HomepageModule],
})
export class CompareComponent implements OnInit, OnDestroy {
  lang: Language = 'cs';
  private unsubscribe$ = new Subject<void>();

  domesticPlatforms: DomesticPlatform[] = [
    {
      logo: 'coinmate-logo.svg',
      logoDark: 'coinmate-logo.svg',
      logoHeightPx: 30,
      name: 'Coinmate',
      fees: {
        cs: '0,4 % – limitní příkaz<br />1,5 % – Rychlý nákup',
        en: '0.4 % – limit order<br />1.5 % – Quick buy',
      },
      costExample: {
        cs: '20 Kč - limitní příkaz<br />75 Kč - Rychlý nákup',
        en: '0.80 € - limit order<br />3 € - Quick buy',
      },
    },
    {
      logo: 'anycoin-logo.svg',
      logoDark: 'anycoin-logo-dark.svg',
      logoHeightPx: 40,
      name: 'Anycoin',
      fees: {
        cs: '1,64 %',
        en: '1.64 %',
      },
      costExample: {
        cs: '82 Kč',
        en: '3.28 €',
      },
    },
    {
      logo: 'simplecoin-logo.svg',
      logoDark: 'simplecoin-logo.svg',
      logoHeightPx: 25,
      name: 'Simplecoin',
      fees: {
        cs: '2,5 %',
        en: '2.5 %',
      },
      costExample: {
        cs: '125 Kč',
        en: '5 €',
      },
    },
    {
      logo: 'coinbank-logo.svg',
      logoDark: 'coinbank-logo.svg',
      logoHeightPx: 30,
      name: 'CoinBank',
      fees: {
        cs: '2,5 %',
        en: '2.5 %',
      },
      costExample: {
        cs: '125 Kč',
        en: '5 €',
      },
    },
    {
      logo: 'ccshop-logo.png',
      logoDark: 'ccshop-logo-dark.png',
      logoHeightPx: 40,
      name: 'cShop',
      fees: {
        cs: '14,2 %',
        en: '14.2 %',
      },
      costExample: {
        cs: '1410 Kč',
        en: '56 €',
      },
    },
  ];

  foreignPlatforms: ForeignPlatform[] = [
    {
      logo: 'coinmate-logo.svg',
      logoDark: 'coinmate-logo.svg',
      logoHeightPx: 30,
      name: 'Coinmate',
      exchangeFees: {
        cs: '0,4 % – limitní příkaz',
        en: '0.4 % – limit order',
      },
      bankFees: {
        cs: '0 % – směna není potřeba',
        en: '0 % – no exchange needed',
      },
      totalCost: {
        cs: '0,4 %',
        en: '0.4 %',
      },
      costExample: {
        cs: '20 Kč',
        en: '0.80 €',
      },
    },
    {
      logo: 'coinbase-logo.svg',
      logoDark: 'coinbase-logo.svg',
      logoHeightPx: 30,
      name: 'Coinbase',
      exchangeFees: {
        cs: '0,6 % – limitní příkaz',
        en: '0.6 % – limit order',
      },
      bankFees: {
        cs: '2,5 % – směna CZK/EUR v bance<br />0,2 % až 1,2 % – směna CZK/EUR přes Revolut **',
        en: '2.5 % – CZK/EUR exchange in bank<br />0.2 % to 1.2 % – CZK/EUR exchange via Revolut **',
      },
      totalCost: {
        cs: '0,8 až 3,1 %',
        en: '0.8 to 3.1 %',
      },
      costExample: {
        cs: '40 Kč až 165 Kč',
        en: '1.60 € to 6.60 €',
      },
    },
    {
      logo: 'kraken-logo.svg',
      logoDark: 'kraken-logo.svg',
      logoHeightPx: 20,
      name: 'Kraken',
      exchangeFees: {
        cs: '0,25 % – limitní příkaz',
        en: '0.25 % – limit order',
      },
      bankFees: {
        cs: '2,5 % – směna CZK/EUR v bance<br />0,2 % až 1,2 % – směna CZK/EUR přes Revolut **',
        en: '2.5 % – CZK/EUR exchange in bank<br />0.2 % to 1.2 % – CZK/EUR exchange via Revolut **',
      },
      totalCost: {
        cs: '0,45 až 2,75 %',
        en: '0.45 to 2.75 %',
      },
      costExample: {
        cs: '23 Kč až 137 Kč',
        en: '0.92 € to 5.48 €',
      },
    },
    {
      logo: 'binance-logo.svg',
      logoDark: 'binance-logo.svg',
      logoHeightPx: 30,
      name: 'Binance',
      exchangeFees: {
        cs: '0,1 % – limitní příkaz',
        en: '0.1 % – limit order',
      },
      bankFees: {
        cs: '2,5 % – směna CZK/EUR v bance<br />0,2 % až 1,2 % – směna CZK/EUR přes Revolut **',
        en: '2.5 % – CZK/EUR exchange in bank<br />0.2 % to 1.2 % – CZK/EUR exchange via Revolut **',
      },
      totalCost: {
        cs: '0,3 až 2,6 %',
        en: '0.3 to 2.6 %',
      },
      costExample: {
        cs: '15 Kč až 130 Kč',
        en: '0.60 € to 5.20 €',
      },
    },
    {
      logo: 'revolut-logo.svg',
      logoDark: 'revolut-logo-dark.svg',
      logoHeightPx: 20,
      name: 'Revolut',
      exchangeFees: {
        cs: '1,49 % – limitní příkaz',
        en: '1.49 % – limit order',
      },
      bankFees: {
        cs: '0 % – směna není potřeba',
        en: '0 % – no exchange needed',
      },
      totalCost: {
        cs: '1,49 %',
        en: '1.49 %',
      },
      costExample: {
        cs: '75 Kč',
        en: '3 €',
      },
    },
  ];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.lang = this.translateService.currentLang as Language;
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.lang = data.lang as Language));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
