import { Component, ElementRef, ViewChild } from '@angular/core';
import { teamMembers } from '@app/unauthenticated/our-story/team-member-info';

@Component({
  selector: 'app-team-member-list',
  templateUrl: './team-member-list.component.html',
  styleUrls: ['./team-member-list.component.scss'],
})
export class TeamMemberListComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  teamMembers = teamMembers;

  get scrollDistance() {
    return this.scrollContainer.nativeElement.scrollWidth / this.teamMembers.length;
  }

  moveLeft() {
    this.scrollContainer.nativeElement.scrollLeft -= this.scrollDistance;
  }

  moveRight() {
    this.scrollContainer.nativeElement.scrollLeft += this.scrollDistance;
  }
}
