<div class="homepage-partners">
  <div class="homepage-section-container">
    <section class="col-span-3 lg:col-span-1">
      <h2 class="display-h2 mb-8 lg:mb-16">{{ 'home-page.cooperations.heading' | translate }}</h2>
      <div class="items">
        <div *ngFor="let cooperation of cooperations" class="item">
          <a [href]="cooperation.href" target="_blank" rel="noopener noreferrer">
            <img
              [src]="'/assets/img/homepage/partners/' + cooperation.image"
              [alt]="cooperation.alt"
              class="item-image"
            />
          </a>
        </div>
      </div>
    </section>

    <section class="col-span-3 lg:col-span-1">
      <h2 class="display-h2 mb-8 lg:mb-16">{{ 'home-page.members-of.heading' | translate }}</h2>
      <div class="items">
        <div *ngFor="let memberOf of membersOf" class="item">
          <a [href]="memberOf.href" target="_blank" rel="noopener noreferrer">
            <img [src]="'/assets/img/homepage/partners/' + memberOf.image" [alt]="memberOf.alt" class="item-image" />
          </a>
        </div>
      </div>
    </section>

    <section class="col-span-3 lg:col-span-1">
      <h2 class="display-h2 mb-8 lg:mb-16">{{ 'home-page.certificate.heading' | translate }}</h2>
      <div class="items">
        <div *ngFor="let certificate of certificates" class="item">
          <a [href]="certificate.href" target="_blank" rel="noopener noreferrer">
            <img
              [src]="'/assets/img/homepage/partners/' + certificate.image"
              [alt]="certificate.alt"
              class="item-image"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
