<div class="relative">
  <button (click)="toggleTooltip($event)">
    <svg-icon [svgClass]="iconClasses" src="/assets/icons/icon-info.svg"></svg-icon>
  </button>
  <div
    class="help-tooltip"
    [ngStyle]="{
  'left.px': leftOffset,
    }"
    [ngClass]="{
  'active': tooltipVisible,
}"
    [innerHTML]="text | translate"
  ></div>
</div>
