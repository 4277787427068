<section class="homepage-intro">
  <div class="homepage-section-container">
    <div class="grid grid-cols-12 gap-8 lg:gap-4 items-start md:items-center">
      <div class="col-span-12 md:col-span-5 lg:col-span-6 xl:col-span-7 2xl:col-span-6">
        <h1 class="display-h1 mb-8 lg:mb-12 lg:max-w-[12ch]" [innerHTML]="'home-page.intro.heading' | translate"></h1>

        <div
          class="max-w-[24ch] display-h3 font-normal mb-3 lg:mb-6"
          [innerHTML]="'home-page.intro.text' | translate"
        ></div>

        <ng-container *ngIf="isLogged; else signUp">
          <a [routerLink]="'/quick-trade' | localize" class="cm-button cm-button-primary cm-button-large mb-4">
            {{ 'home-page.invest' | translate }}
          </a>
        </ng-container>
      </div>
      <div
        class="col-span-12 md:col-span-7 lg:col-span-6 xl:col-span-5 2xl:col-span-6 flex flex-col items-center md:items-end md:justify-center"
      >
        <div class="w-full md:max-w-[410px]">
          <app-currencies-table [isMinimal]="true"></app-currencies-table>
        </div>
      </div>
    </div>
  </div>

  <ng-template #signUp>
    <a
      [routerLink]="'/sign-up' | localize"
      class="cm-button cm-button-primary cm-button-large"
      [ngClass]="lang === 'cs' ? 'mb-4' : 'mb-[30px]'"
    >
      {{ 'home-page.create-account' | translate }}
    </a>
    <div class="flex items-end display-caption-small" *ngIf="lang === 'cs'">
      <span class="leading-none">{{ 'home-page.intro.bank-id' | translate }}</span
      >&nbsp;<svg-icon src="/assets/icons/bank-id.svg"></svg-icon>
    </div>
  </ng-template>
</section>
