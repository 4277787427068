export enum CurrencyPairStatus {
  Break = 'BREAK',
  Halt = 'HALT',
  Trading = 'TRADING',
}

export enum CurrencyEventType {
  Listing = 'LISTING',
  Delisting = 'DELISTING', // <- Removing the currency pair from the platform
  DelistingCurrency = 'DELISTING_CURRENCY', // <- Completely removing the currency from the platform
  Break = 'BREAK',
  Halt = 'HALT',
}
