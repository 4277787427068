<section class="homepage-safety">
  <div class="homepage-section-container">
    <h2 class="display-h2 mb-8 lg:mb-16" [innerHTML]="'home-page.safety.heading' | translate"></h2>
    <div class="items">
      <div *ngFor="let item of items" class="item">
        <img [src]="'/assets/img/homepage/' + item.image" [alt]="item.alt" class="item-image" />
        <div>
          <h3 class="display-h3 mb-4" [innerHTML]="item.heading | translate"></h3>
          <div class="flex flex-1 flex-col display-paragraph-large">
            <div class="flex-1 mb-2">
              <p class="max-w-[32ch]" [innerHTML]="item.text | translate"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
