import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

interface solution {
  routerLink?: string;
  link?: string;
  heading: string;
  text: string;
  image: string;
  linkLabel: string;
  alt: string;
}

@Component({
  selector: 'homepage-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss'],
})
export class SolutionsComponent implements OnInit, OnChanges {
  @Input() lang = 'cs';
  @Input() colorScheme = 'light';

  solutions: solution[] = [];

  ngOnInit() {
    this.createSolutions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang || changes.colorScheme) {
      this.createSolutions();
    }
  }

  private createSolutions() {
    this.solutions = [
      {
        routerLink: '/quick-trade',
        heading: 'home-page.solutions.solution-1.heading',
        text: 'home-page.solutions.solution-1.text',
        image: `quick-trade-${this.lang}-${this.colorScheme}`,
        linkLabel: 'home-page.solutions.solution-1.link-label',
        alt: 'quick-trade',
      },
      {
        routerLink: '/recurring-buy',
        heading: 'home-page.solutions.solution-2.heading',
        text: 'home-page.solutions.solution-2.text',
        image: `recurring-buy-${this.lang}-${this.colorScheme}`,
        linkLabel: 'home-page.solutions.solution-2.link-label',
        alt: 'recurring-buy',
      },
      {
        routerLink: '/trade',
        heading: 'home-page.solutions.solution-3.heading',
        text: 'home-page.solutions.solution-3.text',
        image: `trading-platform-${this.lang}-${this.colorScheme}`,
        linkLabel: 'home-page.solutions.solution-3.link-label',
        alt: 'trading-platform',
      },
      {
        routerLink: '/developers',
        heading: 'home-page.solutions.solution-4.heading',
        text: 'home-page.solutions.solution-4.text',
        image: `api-${this.colorScheme}`,
        linkLabel: 'home-page.solutions.solution-4.link-label',
        alt: 'api',
      },
      this.lang === 'cs' && {
        link: 'https://coinmate.io/cz/otc-desk',
        heading: 'home-page.solutions.solution-5.heading',
        text: 'home-page.solutions.solution-5.text',
        image: 'otc-desk',
        linkLabel: 'home-page.solutions.solution-5.link-label',
        alt: 'otc-desk',
      },
    ].filter(Boolean) as solution[];
  }
}
