/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrencyPairSelectedDto } from '../models/currency-pair-selected-dto';

@Injectable({
  providedIn: 'root',
})
export class SelectedPairControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSelectedCurrencyPairUsingGet
   */
  static readonly GetSelectedCurrencyPairUsingGetPath = '/frontend/users/currency-pairs/selected';

  /**
   * Get user's selected currency pair
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelectedCurrencyPairUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedCurrencyPairUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CurrencyPairSelectedDto>> {

    const rb = new RequestBuilder(this.rootUrl, SelectedPairControllerService.GetSelectedCurrencyPairUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrencyPairSelectedDto>;
      })
    );
  }

  /**
   * Get user's selected currency pair
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelectedCurrencyPairUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedCurrencyPairUsingGet(params?: {
  },
  context?: HttpContext

): Observable<CurrencyPairSelectedDto> {

    return this.getSelectedCurrencyPairUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<CurrencyPairSelectedDto>) => r.body as CurrencyPairSelectedDto)
    );
  }

  /**
   * Path part for operation selectCurrencyPairUsingPut
   */
  static readonly SelectCurrencyPairUsingPutPath = '/frontend/users/currency-pairs/selected';

  /**
   * Change the selected currency pair of the user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectCurrencyPairUsingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  selectCurrencyPairUsingPut$Response(params: {
    body: string
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CurrencyPairSelectedDto>> {

    const rb = new RequestBuilder(this.rootUrl, SelectedPairControllerService.SelectCurrencyPairUsingPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrencyPairSelectedDto>;
      })
    );
  }

  /**
   * Change the selected currency pair of the user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `selectCurrencyPairUsingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  selectCurrencyPairUsingPut(params: {
    body: string
  },
  context?: HttpContext

): Observable<CurrencyPairSelectedDto> {

    return this.selectCurrencyPairUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<CurrencyPairSelectedDto>) => r.body as CurrencyPairSelectedDto)
    );
  }

}
