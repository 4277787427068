<div class="currency-input" [class.error]="error">
  <div class="cm-info">
    <ng-content select="[content-top]"></ng-content>
  </div>
  <form [formGroup]="formGroup" class="flex justify-end flex-row overflow-hidden">
    <div class="input-container">
      <div *ngIf="readOnly && isLoading" class="loading-overlay">
        <div class="loader"></div>
      </div>
      <input
        type="text"
        inputmode="decimal"
        formControlName="amount"
        [class.strobe]="isAnimated"
        [readOnly]="readOnly"
        min="0"
        placeholder=""
        [appOnlyNumbers]="decimalCount ?? 2"
      />
    </div>
    <div class="currency-selector w-[80px]">
      <button [class.error]="error" (click)="onCurrencyButtonClick()" class="flex items-center justify-center">
        {{ currencyName }}
        <svg-icon class="ml-1 text-cm-blue-500" src="/assets/icons/icon-chevron-down.svg"></svg-icon>
      </button>
    </div>
  </form>

  <div class="cm-info">
    <div class="cm-error-message">
      <ng-content select="[error]"></ng-content>
    </div>
    <ng-content></ng-content>
  </div>
</div>
