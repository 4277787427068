<section class="homepage-benefits">
  <div class="homepage-section-container">
    <h2 class="display-h2 mb-8 lg:mb-16 sr-only">{{ 'home-page.benefits.heading' | translate }}</h2>
    <div class="items">
      <div *ngFor="let benefit of benefits" class="item">
        <img [src]="'/assets/img/homepage/' + benefit.image" [alt]="benefit.alt" class="item-image" />
        <h3 class="display-h3 mb-4" [innerHTML]="benefit.heading | translate"></h3>
        <div class="flex flex-1 flex-col display-paragraph-large">
          <div class="flex-1 mb-2">
            <p class="max-w-[26ch]" [innerHTML]="benefit.text | translate"></p>
          </div>
          <app-link-pair
            *ngIf="benefit.routerLink"
            [routerLink]="benefit.routerLink"
            [addressLabel]="'home-page.benefits.more' | translate"
            addressIcon="/assets/img/arrow-right-blue.svg"
          ></app-link-pair>
          <app-link-pair
            *ngIf="benefit.href"
            [addressHref]="benefit.href"
            [addressLabel]="'home-page.benefits.more' | translate"
            addressIcon="/assets/img/arrow-right-blue.svg"
          ></app-link-pair>
        </div>
      </div>
    </div>
  </div>
</section>
