import { Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { formatNumber } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import { Locales, UserInfoProfileService } from '@app/shared/services/user-info-profile.service';
import {
  DECIMAL_SEPARATOR_EN,
  DECIMAL_SEPARATOR_EN_REGEX,
  DECIMAL_SEPARATOR_CS,
  DECIMAL_SEPARATOR_CS_REGEX,
  NON_VISIBLE_SPACES_REGEX,
  VALID_NUMBER_FORMAT_CS_REGEX,
  VALID_NUMBER_FORMAT_EN_REGEX,
  DECIMAL_SEPARATOR_REGEX,
} from '@app/shared/const/regex.const';

const INPUT_TYPE_INSERT_FROM_PASTE = 'insertFromPaste';

@Directive({
  selector: '[appOnlyNumbers]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyNumbersTextInputDirective),
      multi: true,
    },
  ],
})
export class OnlyNumbersTextInputDirective implements OnDestroy, ControlValueAccessor {
  @Input('appOnlyNumbers') decimalPlaces = 0;

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  private unsubscribe$ = new Subject<null>();

  private validRegex = VALID_NUMBER_FORMAT_EN_REGEX;
  private localeDecimalSeparatorRegex = DECIMAL_SEPARATOR_EN_REGEX;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
  private currentValue = '';
  private numberLocale: Locales = Locales.en;
  private isUpdatingProgrammatically = false;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly userInfoProfileService: UserInfoProfileService,
  ) {
    this.userInfoProfileService.numberLocale$
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((numberLocale) => {
        this.setNumberLocale(numberLocale);
        this.applyValue(this.currentValue);
      });
  }

  writeValue(value: string): void {
    if (BigNumber(this.currentValue).eq(value)) {
      return;
    }

    this.isUpdatingProgrammatically = true;
    this.applyValue(value);
    this.isUpdatingProgrammatically = false;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * NOTE: `preventDefault` does not work for the `keydown` event in Chrome browser for Android OS.
   *
   * But it is fine for inputs that don't use a getter and setter for a value.
   * Thanks to the logic that returns a truncated value in the `input` event.
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      ((event.metaKey || event.ctrlKey) && ['a', 'c', 'x', 'v'].includes(event.key.toLowerCase()))
    ) {
      return;
    }

    const value = this.isValueDecimalSeparator(event.key) ? this.convertDecimalSeparatorToLocal(event.key) : event.key;
    const concatenatedValue = this.concatValue(value);
    const isValid = this.isValidInput(concatenatedValue);

    if (!isValid) {
      event.preventDefault();
    }
  }

  /**
   * Because preventDefault does not work for the `keydown` event in Chrome browser for Android OS.
   * The `beforeinput` event is used, which correctly supports `preventDefault in this browser.
   *
   * This is required for inputs that use a getter and setter for a value.
   *
   * In some cases, the `beforeinput` event is not fired.
   * Therefore, the `keydown` event is used, at least as a fallback method for other browsers.
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/beforeinput_event
   */
  @HostListener('beforeinput', ['$event']) onBeforeInput(event: InputEvent) {
    const eventValue = event.data ?? '';

    /**
     * If the user enters a value from the clipboard, try to adjust the value to the correct number of decimal places.
     */
    if (event.inputType === INPUT_TYPE_INSERT_FROM_PASTE) {
      // Prevents calling the `input` event for the original value
      event.preventDefault();

      const concatenatedValue = this.concatValue(eventValue);
      const truncatedValue = this.parseToValidNumber(concatenatedValue);
      const isValid = this.isValidInput(truncatedValue);

      if (isValid) {
        this.applyValue(truncatedValue);
      }
      return;
    }

    const value = this.isValueDecimalSeparator(eventValue)
      ? this.convertDecimalSeparatorToLocal(eventValue)
      : eventValue;
    const concatenatedValue = this.concatValue(value);
    const isValid = this.isValidInput(concatenatedValue);

    if (!isValid) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    if (this.isUpdatingProgrammatically) {
      return;
    }

    const eventValue = event.data ?? '';
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue = inputElement.value;
    const concatenatedValue = this.isValueDecimalSeparator(eventValue)
      ? this.concatValue(this.convertDecimalSeparatorToLocal(eventValue), true)
      : inputValue;
    this.applyValue(concatenatedValue);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private applyValue(value: null | number | string) {
    const inputElement: HTMLInputElement = this.el.nativeElement;

    if (!this.isValidInput(value)) {
      const truncatedValue = this.parseToValidNumber(value);
      this.applyValue(this.isValidInput(truncatedValue) ? truncatedValue : '');
      return;
    }

    // Save current selection
    const selectionStart = inputElement.selectionStart ?? 0;
    const selectionEnd = inputElement.selectionEnd ?? 0;

    let sanitizedValue = this.sanitizeValue(value);

    if (this.startsWithDecimalSeparator(value)) {
      sanitizedValue = '0' + value;
    }

    this.currentValue = this.sanitizeValue(sanitizedValue, true);
    const formattedValue: string = this.formatValue(sanitizedValue);

    // Use a different approach for updating the value to avoid focus issues on iOS
    if (this.isUpdatingProgrammatically) {
      const originalReadOnly = inputElement.readOnly;
      this.renderer.setAttribute(inputElement, 'readonly', 'readonly');
      this.renderer.setProperty(inputElement, 'value', formattedValue);
      if (!originalReadOnly) {
        this.renderer.removeAttribute(inputElement, 'readonly');
      }
    } else {
      this.renderer.setProperty(inputElement, 'value', formattedValue);
      this.offsetInputSelection(
        inputElement,
        selectionStart,
        selectionEnd,
        Math.max(0, formattedValue.length - String(value).length),
      );
    }

    this.onChange(this.currentValue);
  }

  private offsetInputSelection(inputElement: HTMLInputElement, origStart: number, origEnd: number, offset: number) {
    inputElement.setSelectionRange(Math.max(origStart + offset, 0), Math.max(origEnd + offset, 0));
  }

  private isValidInput(value: null | number | string): boolean {
    const sanitizedValue = this.sanitizeValue(value);
    const decimalSeparators = this.getLocaleDecimalSeparators(sanitizedValue);

    if (decimalSeparators.length === 1 && sanitizedValue.length === 1) {
      return true;
    }

    if (decimalSeparators.length > 1 || (decimalSeparators.length === 1 && this.decimalPlaces === 0)) {
      return false;
    }

    const [_integerPart, decimalPart] = sanitizedValue.split(decimalSeparators[0]);
    return (decimalPart?.length ?? 0) <= this.decimalPlaces && this.validRegex.test(sanitizedValue);
  }

  private concatValue(value: string, replace?: boolean) {
    const sanitizedValue = this.sanitizeValue(value);
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue: string = inputElement.value;
    const cursorPosition = inputElement.selectionStart ?? 0;
    const cursorPositionEnd = inputElement.selectionEnd ?? 0;

    if (replace) {
      return inputValue.slice(0, cursorPosition - 1) + sanitizedValue + inputValue.slice(cursorPositionEnd);
    }

    return inputValue.slice(0, cursorPosition) + sanitizedValue + inputValue.slice(cursorPositionEnd);
  }

  private parseToValidNumber(value: null | number | string) {
    if (value === null) {
      return null;
    }

    const sanitizedValue = this.sanitizeValue(value, true);
    const [_integerPart, decimalPart] = sanitizedValue.split(DECIMAL_SEPARATOR_EN);
    const valueBN = BigNumber(sanitizedValue);

    if (valueBN.isNaN()) {
      return '';
    }

    return valueBN.toFormat(Math.min(decimalPart?.length ?? 0, this.decimalPlaces), BigNumber.ROUND_DOWN, {
      decimalSeparator: this.numberLocale === Locales.cs ? DECIMAL_SEPARATOR_CS : DECIMAL_SEPARATOR_EN,
    });
  }

  private formatValue(value: string) {
    if (value === '') {
      return value;
    }

    const sanitizedValue = this.sanitizeValue(value, true);
    const sanitizedValueBN = new BigNumber(sanitizedValue).decimalPlaces(this.decimalPlaces, BigNumber.ROUND_DOWN);
    const [_integerPart, decimalPart] = sanitizedValue.split(DECIMAL_SEPARATOR_EN);
    const endsWithDecimalSeparator = this.endsWithDecimalSeparator(value);
    const decimalLength =
      this.decimalPlaces > 0 && endsWithDecimalSeparator ? 1 : Math.min(decimalPart?.length || 0, this.decimalPlaces);
    const formattedValue = formatNumber(
      sanitizedValueBN.toNumber(),
      this.numberLocale,
      `.${decimalLength}-${this.decimalPlaces}`,
    );

    if (this.decimalPlaces > 0 && endsWithDecimalSeparator) {
      return formattedValue.substring(0, formattedValue.length - 1);
    }

    return formattedValue;
  }

  private setNumberLocale(numberLocale: Locales) {
    this.numberLocale = numberLocale;
    this.validRegex = numberLocale === Locales.cs ? VALID_NUMBER_FORMAT_CS_REGEX : VALID_NUMBER_FORMAT_EN_REGEX;
    this.localeDecimalSeparatorRegex =
      numberLocale === Locales.cs ? DECIMAL_SEPARATOR_CS_REGEX : DECIMAL_SEPARATOR_EN_REGEX;
  }

  private sanitizeValue(value: null | number | string, normalizeDelimiter = false) {
    if (value === null) {
      return '';
    }

    const sanitizedValue = String(value).replace(NON_VISIBLE_SPACES_REGEX, '');

    if (normalizeDelimiter) {
      if (this.numberLocale === Locales.en) {
        return sanitizedValue.replace(DECIMAL_SEPARATOR_CS_REGEX, '');
      }

      return sanitizedValue.replace(DECIMAL_SEPARATOR_CS_REGEX, '.');
    }

    return sanitizedValue;
  }

  private getLocaleDecimalSeparators(value: null | number | string) {
    if (value === null) {
      return [];
    }

    return String(value).match(this.localeDecimalSeparatorRegex) || [];
  }

  private startsWithDecimalSeparator(value: null | number | string) {
    if (value === null || value === '') {
      return false;
    }

    const valueAsString = String(value);
    const localeDecimalSeparator = this.getLocaleDecimalSeparators(value).pop();
    return !!(localeDecimalSeparator && valueAsString.indexOf(localeDecimalSeparator) === 0);
  }

  private endsWithDecimalSeparator(value: null | number | string) {
    if (value === null || value === '') {
      return false;
    }

    const valueAsString = String(value);
    const localeDecimalSeparator = this.getLocaleDecimalSeparators(value).pop();
    return !!(localeDecimalSeparator && valueAsString.indexOf(localeDecimalSeparator) === valueAsString.length - 1);
  }

  private isValueDecimalSeparator(value: null | number | string) {
    if (value === null || value === '') {
      return false;
    }

    return DECIMAL_SEPARATOR_REGEX.test(String(value));
  }

  private convertDecimalSeparatorToLocal(value: string) {
    if (this.numberLocale === Locales.en) {
      return value.replace(DECIMAL_SEPARATOR_CS_REGEX, DECIMAL_SEPARATOR_EN);
    }
    return value.replace(DECIMAL_SEPARATOR_EN_REGEX, DECIMAL_SEPARATOR_CS);
  }
}
