<span class="rich-tooltip-header-title">
  <svg-icon src="/assets/icons/16/info-circle.svg" />
  <ng-content></ng-content>
</span>

<app-action-button
  *ngIf="!hideClose"
  [size]="'icon'"
  [attr.aria-label]="'shared.common.close' | translate"
  (click)="close()"
>
  <svg-icon src="/assets/icons/16/close.svg" />
</app-action-button>
