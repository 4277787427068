<div class="context-trade-currency-bar">
  <app-currency-pair-selector
    class="context-trade-currency-bar-column"
    [isLoggedIn]="isLoggedIn"
    (changePair)="setCurrencyPair($event)"
  ></app-currency-pair-selector>
  <div *ngIf="isLoggedIn" class="context-trade-currency-bar-column">
    <div class="hidden md:block">
      <a [routerLink]="'/open-orders' | localize" class="cm-button cm-button-xs">
        {{ 'submenu.open-orders' | translate }}
      </a>
    </div>
    <div
      class="context-trade-currency-bar-balances"
      *ngIf="currencyBalancesForSelectedPair$ | async as currencyBalancesForSelectedPair"
    >
      {{ 'balances' | translate }}
      <app-action-button appearance="text-small md:text-base" [size]="'xs'" (click)="openModal()">
        <ng-container *ngFor="let balance of currencyBalancesForSelectedPair">
          <span>
            <b>{{ balance.name }}:&nbsp;</b>
            <span class="font-normal">{{ balance.available | appCurrencyFloor : balance.name | async }}</span>
          </span>
        </ng-container>
      </app-action-button>
    </div>
  </div>
</div>
