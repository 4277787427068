<app-base-modal
  (closeModal)="resetFilterOptions()"
  [id]="modalId"
  [title]="'currency-pairs-for-trading' | translate"
  [size]="'large'"
  [isBodyZeroSpacing]="true"
>
  <ng-container modal-header>
    <app-search
      [searchTerm]="searchTerm"
      [searchPlaceholder]="'currency-pair-search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    >
    </app-search>
    <app-multi-choice-button-bar
      [options]="filterOptions"
      [description]="'currency-pair-filter' | translate"
      (selectedOptions)="handleSelectedOptions($event)"
    >
    </app-multi-choice-button-bar>
  </ng-container>
  <app-base-modal-body>
    <table class="min-w-full">
      <thead>
        <tr class="currencies-table-row">
          <ng-container *ngIf="isMobileViewport; else headerDesktop">
            <th class="currencies-table-cell pb-4">
              {{ headers[0] | translate }}
            </th>
            <ng-container *ngIf="headers.slice(1, headers.length) as restHeaders">
              <th class="currencies-table-cell pb-4" [colSpan]="restHeaders.length">
                <ng-container *ngFor="let header of restHeaders.reverse(); let isLast = last">
                  <span class="inline-flex items-center gap-x-6px">
                    {{ header | translate }}
                  </span>
                  <br *ngIf="!isLast" />
                </ng-container>
              </th>
            </ng-container>
          </ng-container>
          <ng-template #headerDesktop>
            <th
              *ngFor="let header of headers; let isFirst = first"
              class="currencies-table-cell pb-4"
              [ngClass]="{ 'text-left md:w-[340px]': isFirst, 'text-right': !isFirst }"
            >
              {{ header | translate }}
            </th>
          </ng-template>
        </tr>
      </thead>
      <ng-container *ngIf="listFavouritePairs() && listFavouritePairs().length">
        <ng-container *ngIf="currencies$ | async as currencies">
          <thead>
            <tr class="currencies-table-row">
              <th colspan="5" class="currencies-table-cell">
                {{ 'favourites' | translate }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr
              *ngFor="let pair of listFavouritePairs(); trackBy: trackByItemId"
              (click)="onRowClick(pair)"
              class="currencies-table-row"
            >
              <td class="currencies-table-cell">
                <div class="flex flex-col gap-8px">
                  <div class="flex items-center gap-x-8px md:gap-x-20px">
                    <button (click)="onIconClick($event, pair)">
                      <svg-icon src="/assets/icons/icon-favorites-on.svg" class="w-5 h-5"></svg-icon>
                    </button>
                    <app-currency-icon class="h-8 w-8" [currencyName]="pair.firstCurrency" [alt]="pair.name" />
                    <div class="flex flex-col gap-y-4px">
                      <span class="font-bold"> {{ pair.name | appCurrencyPairDisplay | async }}</span>
                      <div *ngIf="!isMobileViewport" class="flex flex-wrap gap-8px">
                        <app-currency-event-ribbon [currency]="currencies[pair.firstCurrency]" [currencyPair]="pair" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isMobileViewport" class="flex flex-wrap gap-8px">
                    <app-currency-event-ribbon [currency]="currencies[pair.firstCurrency]" [currencyPair]="pair" />
                  </div>
                </div>
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell">
                <app-colored-percent-change-text
                  class="block font-bold text-base lg:text-large text-right"
                  [change]="+pair.changeIn24Hours"
                ></app-colored-percent-change-text>
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell font-bold">
                <app-currency-value [value]="pair.lastPrice" [currency]="pair.secondCurrency"></app-currency-value>
              </td>
              <td *ngIf="isMobileViewport" class="currencies-table-cell">
                <ng-container
                  *ngIf="
                    getCurrencyEventByType(
                      currencies[pair.firstCurrency],
                      CurrencyEventType.Listing,
                      pair
                    ) as currencyEventListing;
                    else priceTemplate
                  "
                >
                  <span class="text-dimmed text-sm">
                    {{
                      'currencies.event.listing.countdown'
                        | translate : { date: (currencyEventListing.downtimeTo | appCountdown | async) }
                    }}
                  </span>
                </ng-container>
                <ng-template #priceTemplate>
                  <div class="flex flex-col gap-4px">
                    <div class="font-bold">
                      <app-currency-value
                        [value]="pair.lastPrice"
                        [currency]="pair.secondCurrency"
                      ></app-currency-value>
                    </div>
                    <app-colored-percent-change-text
                      class="block font-bold text-base lg:text-large text-right"
                      [change]="+pair.changeIn24Hours"
                    ></app-colored-percent-change-text>
                  </div>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <thead *ngIf="listFavouritePairs() && listFavouritePairs().length">
        <tr class="currencies-table-row h-5"></tr>
        <tr class="currencies-table-row">
          <th colspan="5" class="currencies-table-cell">
            {{ 'others' | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="filteredRestPairs && filteredRestPairs.length; else noData">
        <ng-container *ngIf="currencies$ | async as currencies">
          <tr
            *ngFor="let pair of filteredRestPairs; trackBy: trackByItemId"
            (click)="onRowClick(pair)"
            class="currencies-table-row"
          >
            <td class="currencies-table-cell">
              <div class="flex flex-col gap-8px">
                <div class="flex items-center gap-x-8px md:gap-x-20px">
                  <button (click)="onIconClick($event, pair)">
                    <svg-icon src="/assets/icons/icon-favorites.svg" class="w-5 h-5"></svg-icon>
                  </button>
                  <div class="flex items-center gap-x-8px md:gap-x-20px">
                    <app-currency-icon class="h-8 w-8" [currencyName]="pair.firstCurrency" [alt]="pair.name" />
                    <div class="flex flex-col gap-y-4px">
                      <span class="font-bold"> {{ pair.name | appCurrencyPairDisplay | async }} </span>
                      <div *ngIf="!isMobileViewport" class="flex flex-wrap gap-8px">
                        <app-currency-event-ribbon [currency]="currencies[pair.firstCurrency]" [currencyPair]="pair" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isMobileViewport" class="flex flex-wrap gap-8px">
                  <app-currency-event-ribbon [currency]="currencies[pair.firstCurrency]" [currencyPair]="pair" />
                </div>
              </div>
            </td>
            <td *ngIf="!isMobileViewport" class="currencies-table-cell">
              <app-colored-percent-change-text
                *ngIf="!getCurrencyEventByType(currencies[pair.firstCurrency], CurrencyEventType.Listing, pair)"
                class="block font-bold text-base lg:text-large text-right"
                [change]="+pair.changeIn24Hours"
              ></app-colored-percent-change-text>
            </td>
            <td *ngIf="!isMobileViewport" class="currencies-table-cell">
              <ng-container
                *ngIf="
                  getCurrencyEventByType(
                    currencies[pair.firstCurrency],
                    CurrencyEventType.Listing,
                    pair
                  ) as currencyEventListing;
                  else priceTemplate
                "
              >
                <span class="text-dimmed text-sm">
                  {{
                    'currencies.event.listing.countdown'
                      | translate : { date: (currencyEventListing.downtimeTo | appCountdown | async) }
                  }}
                </span>
              </ng-container>
              <ng-template #priceTemplate>
                <app-currency-value
                  class="font-bold"
                  [value]="pair.lastPrice"
                  [currency]="pair.secondCurrency"
                ></app-currency-value>
              </ng-template>
            </td>
            <td *ngIf="isMobileViewport" class="currencies-table-cell">
              <ng-container
                *ngIf="
                  getCurrencyEventByType(
                    currencies[pair.firstCurrency],
                    CurrencyEventType.Listing,
                    pair
                  ) as currencyEventListing;
                  else priceTemplate
                "
              >
                <span class="text-dimmed text-sm">
                  {{
                    'currencies.event.listing.countdown'
                      | translate : { date: (currencyEventListing.downtimeTo | appCountdown | async) }
                  }}
                </span>
              </ng-container>
              <ng-template #priceTemplate>
                <div class="flex flex-col gap-4px">
                  <div class="font-bold">
                    <app-currency-value [value]="pair.lastPrice" [currency]="pair.secondCurrency"></app-currency-value>
                  </div>
                  <app-colored-percent-change-text
                    class="block font-bold text-base lg:text-large text-right"
                    [change]="+pair.changeIn24Hours"
                  ></app-colored-percent-change-text>
                </div>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <ng-template #noData>
      <tr class="currencies-table-row">
        <td colspan="5" class="currencies-table-cell no-data">
          {{ (searchTerm ? 'currency-pair-not-found' : 'shared.common.no-data') | translate }}
        </td>
      </tr>
    </ng-template>
  </app-base-modal-body>
</app-base-modal>
