import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { LanguageService } from '@app/shared/services/language.service';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

const selectableCurrencies = ['CZK', 'EUR'] as const;
export type SelectableCurrency = (typeof selectableCurrencies)[number];
export const defaultCurrency: SelectableCurrency = 'CZK' as const;

const localStorageKey = 'selected-currency' as const;

@Injectable({
  providedIn: 'root',
})
export class SelectedCurrencyService implements OnDestroy {
  readonly selectedCurrency$: Observable<SelectableCurrency>;
  private readonly _selectedCurrency$: Subject<SelectableCurrency>;
  private readonly isBrowser: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(private languageService: LanguageService, @Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
    this._selectedCurrency$ = new BehaviorSubject<SelectableCurrency>(defaultCurrency);
    this.selectedCurrency$ = this._selectedCurrency$.asObservable();

    this.languageService.currentLanguage$
      .pipe(
        map((lang) => {
          if (!this.isBrowser) {
            return SelectedCurrencyService.getCurrencyForLanguage(lang);
          }

          const currency = localStorage.getItem(localStorageKey);
          return (
            SelectedCurrencyService.toSelectableCurrency(currency ?? undefined) ??
            SelectedCurrencyService.getCurrencyForLanguage(lang)
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((currency) => this._selectedCurrency$.next(currency));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  static toSelectableCurrency(currency?: string): SelectableCurrency | undefined {
    if (currency && selectableCurrencies.includes(currency as SelectableCurrency)) {
      return currency as SelectableCurrency;
    }
    return undefined;
  }

  private static getCurrencyForLanguage(language: string): SelectableCurrency {
    switch (language) {
      case 'cs':
        return 'CZK';
      case 'en':
        return 'EUR';
      default:
        return defaultCurrency;
    }
  }

  setSelectedCurrency(currency: SelectableCurrency) {
    if (!this.isBrowser) {
      return;
    }

    localStorage.setItem(localStorageKey, currency);
    this._selectedCurrency$.next(currency);
  }
}
