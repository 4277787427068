<section class="cm-section {{ getSectionClass() }}">
  <div class="cm-container">
    <div class="col">
      <h1 class="cm-section-heading" *ngIf="headingSize === 'heading'">
        {{ heading | translate }}
      </h1>
      <h2 class="cm-section-heading" *ngIf="headingSize === 'subheading'">
        {{ heading | translate }}
      </h2>
      <p class="cm-paragraph">
        {{ text | translate }}
      </p>
    </div>
    <div class="col">
      <img src="{{ imgAddress }}" alt="{{ imgAlt | translate }}" />
    </div>
  </div>
</section>
