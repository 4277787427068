import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { UsersControllerService } from '@app/generated/services/users-controller.service';
import { LogoutService } from '@app/authentication/shared/services/logout.service';
import { TrackingParametersComponent } from '@app/unauthenticated/trackingParameters.component';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ColorSchemeService } from '@app/shared/services/color-scheme.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent extends TrackingParametersComponent implements OnInit, OnDestroy {
  lang = 'cs';
  isLogged = false;
  colorScheme = 'light';

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private guiParams: GuiParams,
    private usersService: UsersControllerService,
    private colorSchemeService: ColorSchemeService,
    private logoutService: LogoutService,
  ) {
    super(platformId);
    this.colorSchemeService.getColorScheme().subscribe((colorScheme) => {
      this.colorScheme = colorScheme.toLowerCase();
    });
  }

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.lang = data.lang));
    this.guiParams.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((isLogged) => {
      this.isLogged = isLogged;
    });

    const urlParams = new URLSearchParams(this.isBrowser ? window.location.search : '');
    this.changeEmail(urlParams.get('verification'));
    this.endSession();
    this.showLogoutMessage();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private changeEmail(code: string | null) {
    if (!code) return;
    this.usersService
      .confirmChangeEmailRequestUsingPost({ body: code })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.toastrService.success(this.translateService.instant('homepage.announcements.email-changed'));
        // User is already logout out at this point on backend, we need to take this in account also on frontend as well
        this.logoutService.refreshAfterLogout();
      });
  }

  private endSession() {
    if (!this.isBrowser) {
      return;
    }

    if (window.sessionStorage.getItem('sessionExpired') === 'true') {
      this.toastrService.warning(this.translateService.instant('homepage.announcements.session-expired'), '', {
        disableTimeOut: true,
        tapToDismiss: false,
        closeButton: true,
      });
      window.sessionStorage.removeItem('sessionExpired');
    }
  }

  private showLogoutMessage() {
    if (!this.isBrowser) {
      return;
    }

    if (window.sessionStorage.getItem('loggedOut') !== 'true') return;
    this.toastrService.success(this.translateService.instant('homepage.announcements.logged-out'));
    window.sessionStorage.removeItem('loggedOut');
  }
}
