import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyDto } from '@app/generated/models/currency-dto';
import { ColorScheme, ColorSchemeService } from '@app/shared/services/color-scheme.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const ICONS_FOLDER = '/assets/crypto/color/';

@Component({
  selector: 'app-currency-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './currency-icon.component.html',
  styleUrl: './currency-icon.component.scss',
})
export class CurrencyIconComponent implements OnInit {
  @Input() currencyName: CurrencyDto['name'] | undefined;
  @Input() alt = '';

  isDarkColorScheme$: Observable<boolean> = of(false);
  currencyIcon = '';

  constructor(private colorSchemeService: ColorSchemeService) {
    this.isDarkColorScheme$ = this.colorSchemeService
      .getColorScheme()
      .pipe(map((colorScheme) => colorScheme === ColorScheme.DARK));
  }

  ngOnInit(): void {
    if (this.currencyName) {
      const icon = this.currencyName.toLowerCase();
      this.currencyIcon = `${ICONS_FOLDER}${icon}.svg`;
    }
  }
}
