<div
  class="step"
  [class.active]="((currentStep$ | async) ?? 0) >= stepNumber"
  [class.last]="isLast"
  [class.collapsable]="isCollapsable"
  [id]="'app-transaction-step-' + stepNumber"
>
  <app-step-number
    [value]="printNumber"
    [active]="((currentStep$ | async) ?? 0) >= stepNumber"
    class="number"
  ></app-step-number>
  <div class="flex items-center">
    <h3 (click)="toggleExpanded()">
      {{ title | translate }}
      <svg-icon
        *ngIf="isCollapsable"
        class="ml-1 text-cm-blue-500"
        [ngClass]="{ 'rotate-180': isExpanded }"
        src="/assets/icons/icon-chevron-down.svg"
      >
      </svg-icon>
    </h3>
    <app-help-icon class="ml-4" [leftOffset]="-300" *ngIf="help" [text]="help"></app-help-icon>
  </div>
  <div class="content">
    <ng-container *ngIf="((currentStep$ | async) ?? 0) >= stepNumber">
      <div *ngIf="!isCollapsable || isExpanded" [@simpleFadeIn]>
        <ng-content></ng-content>
      </div>
    </ng-container>
  </div>
</div>
