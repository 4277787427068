import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of, Subject, switchMap, tap } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { SkeletonComponent } from '@app/shared/components/skeleton/skeleton.component';
import { ScrolledToBottomDirective } from '@app/shared/directives/scrolled-to-bottom.directive';
import { DataTableHeaderDirective } from '@app/shared/components/data-table/data-table-header.directive';
import { DataTableColumnDirective } from '@app/shared/components/data-table/data-table-column.directive';

const DEFAULT_TABLE_CELL_PADDING_HORIZONTAL = 14;
const DEFAULT_MAX_VISIBLE_ROWS = 10;
const MOBILE_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1048;

interface Row {
  id?: number;

  [key: string]: any;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    SkeletonComponent,
    ScrolledToBottomDirective,
    TranslateModule,
  ],
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() rows: Row[] | null = null;
  @Input() datasetKey? = '';
  @Input() loading = false;
  @Input() columnWidths: (number | null)[] = [];
  @Input() minRowHeight: number = 52;
  @Input() maxVisibleRows = DEFAULT_MAX_VISIBLE_ROWS;
  @Input() emptyMessage = '';
  @Output() scrolledToBottom = new EventEmitter();

  @ContentChildren(DataTableHeaderDirective) headerTemplates!: QueryList<DataTableHeaderDirective>;
  @ContentChildren(DataTableColumnDirective) columnTemplates!: QueryList<DataTableColumnDirective>;

  @ViewChildren(DataTableColumnDirective) stickyColumns!: QueryList<DataTableColumnDirective>;
  @ViewChildren('tableBodyRow', { read: ElementRef }) tableBodyRows!: QueryList<ElementRef>;

  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;
  @ViewChild('headerContainer', { read: ElementRef }) headerContainer!: ElementRef;
  @ViewChild('bodyContainer', { read: ElementRef }) bodyContainer!: ElementRef;

  tableHeight = '';
  tableBuffer = 0;
  bodyContainerWidth: number | null = null;
  viewPortScrollbarXSize = 0;
  viewPortScrollbarYSize = 0;
  stickyColumnLeftPositions: (number | null)[] = [];
  showStickyShadow = false;
  tableLayoutUpdate$: Observable<QueryList<any>> = of();

  private unsubscribe$ = new Subject<void>();
  private tableHeightMobile = 0;
  private tableHeightTablet = 0;
  private tableHeightDesktop = 0;

  constructor() {
    this.emptyMessage = this.emptyMessage || 'shared.common.no-data';
  }

  ngOnInit() {
    this.tableHeight = 'auto';
    this.tableBuffer = this.minRowHeight * this.maxVisibleRows * 2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.loading?.currentValue && changes.rows?.currentValue?.length === 0) {
      /**
       * Resets the height of the scrollable viewport in the table if no data is available.
       */
      this.calcTableHeight(true);
      this.resyncTableComponents();
    }

    if (!changes.rows?.previousValue?.length && changes.rows?.currentValue?.length) {
      /**
       * Resets the height of the scrollable viewport in the table when data changes.
       */
      this.tableLayoutUpdate$.subscribe();
    }
  }

  ngAfterViewInit() {
    if (this.viewPort) {
      /**
       * FIX: Fixes an issue where items snap/jump back when scrolling inside table
       *
       * https://github.com/angular/components/issues/27104
       */
      const scrollable = this.viewPort.elementRef.nativeElement;
      if (scrollable && scrollable.children) {
        const spacer = scrollable.children[1];
        if (spacer && spacer.classList.contains('cdk-virtual-scroll-spacer')) {
          // Move spacer as first child
          scrollable.insertBefore(spacer, scrollable.firstChild);
        } else {
          console.error('Could not find spacer');
        }
      } else {
        console.error('Could not find scrollable');
      }

      this.tableLayoutUpdate$ = this.tableBodyRows.changes.pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        switchMap((rows) => {
          const visibleRows = this.rows?.length || 0;
          this.viewPort.setRenderedRange({ start: 0, end: this.maxVisibleRows });

          if (this.maxVisibleRows > visibleRows) {
            return of(rows).pipe(
              tap(() => {
                this.calcTableHeight(true);
              }),
              delay(1),
              tap(() => {
                this.resyncTableComponents();
                this.viewPort.checkViewportSize();
              }),
            );
          }

          return this.tableBodyRows.changes.pipe(
            take(1),
            tap(() => {
              this.calcTableHeight(true);
              this.resyncTableComponents();
            }),
            delay(1),
            tap(() => {
              this.viewPort.checkViewportSize();
            }),
            switchMap(() => {
              return this.tableBodyRows.changes.pipe(take(1));
            }),
          );
        }),
      );

      this.viewPort
        .elementScrolled()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((event) => {
          this.syncHeaderContainerPosition();
          this.syncStickyShadows();
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    of(null)
      .pipe(
        take(1),
        tap(() => {
          this.calcTableHeight();
          this.resyncTableComponents();
        }),
        delay(1),
        tap(() => {
          this.viewPort.checkViewportSize();
        }),
      )
      .subscribe();
  }

  trackByItemId(index: number, item: any) {
    return `row-${item.id ?? index}`;
  }

  private syncHeaderContainerWidth() {
    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = this.viewPort.elementRef.nativeElement;

    this.viewPort.elementRef.nativeElement.style.overflow = 'scroll';
    const viewPortWidthWithScrollbar = this.viewPort.elementRef.nativeElement.clientWidth;
    const viewPortHeightWithScrollbar = this.viewPort.elementRef.nativeElement.clientHeight;
    this.viewPort.elementRef.nativeElement.style.overflow = 'hidden';
    const viewPortWidthWithoutScrollbar = this.viewPort.elementRef.nativeElement.clientWidth;
    const viewPortHeightWithoutScrollbar = this.viewPort.elementRef.nativeElement.clientHeight;
    this.viewPort.elementRef.nativeElement.style.overflow = '';
    const bodyContainerWidth = this.bodyContainer.nativeElement.clientWidth;

    this.viewPortScrollbarXSize =
      clientWidth === scrollWidth ? 0 : viewPortWidthWithoutScrollbar - viewPortWidthWithScrollbar;
    this.viewPortScrollbarYSize =
      clientHeight === scrollHeight ? 0 : viewPortHeightWithoutScrollbar - viewPortHeightWithScrollbar;
    this.bodyContainerWidth = this.viewPortScrollbarXSize + bodyContainerWidth;
  }

  private syncHeaderContainerPosition() {
    this.headerContainer.nativeElement.scrollLeft = this.viewPort.measureScrollOffset('start');
  }

  private syncStickyColumns() {
    if (!this.columnTemplates?.length) {
      this.stickyColumnLeftPositions = [];
      return;
    }

    this.stickyColumnLeftPositions = Array.from({ length: this.columnTemplates.length }).map(() => null);

    const numberOfColumns = this.columnTemplates.length - 1;

    // Iterates columns from right to left
    for (let i = numberOfColumns; i >= 0; i--) {
      const columnWidth = this.columnWidths[i];
      // Column width must be defined
      if (!columnWidth) {
        return;
      }

      const columnTemplate = this.columnTemplates.get(i);
      // Column must be sticky and in a sequence
      if (!columnTemplate?.sticky) {
        return;
      }

      if (numberOfColumns === i) {
        this.stickyColumnLeftPositions[i] = 0;
        continue;
      }

      const previousStickyColumnLeftPosition = this.stickyColumnLeftPositions[i + 1] || 0;
      const previousColumnWidth = (this.columnWidths[i + 1] || 0) + DEFAULT_TABLE_CELL_PADDING_HORIZONTAL;
      this.stickyColumnLeftPositions[i] = previousColumnWidth + previousStickyColumnLeftPosition;
    }
  }

  private syncStickyShadows() {
    const { clientWidth, scrollWidth, scrollLeft } = this.viewPort.elementRef.nativeElement;
    this.showStickyShadow =
      this.stickyColumnLeftPositions.some((value) => value !== null) && scrollWidth > clientWidth + scrollLeft;
  }

  /**
   * Calculates the table height based on the total height of the first n rows,
   * where n is defined in the constant `maxVisibleRows`.
   * If the height has already been calculated for the current window width (i.e., no need for recalculation),
   * the calculation is skipped to avoid unnecessary computation.
   * @param resetTableHeights
   */
  private calcTableHeight(resetTableHeights?: boolean) {
    if (resetTableHeights) {
      this.tableHeightMobile = 0;
      this.tableHeightTablet = 0;
      this.tableHeightDesktop = 0;
    }
    let newTableHeight = 0;

    this.tableHeight = 'auto';
    this.tableBuffer = this.minRowHeight * this.maxVisibleRows * 2;

    const windowWidth = window.innerWidth;

    if (windowWidth < MOBILE_BREAKPOINT) {
      if (!this.tableHeightMobile) {
        this.tableHeightMobile = this.getBodyRowsHeight();
      }

      if (this.tableHeightMobile) {
        newTableHeight = this.tableHeightMobile;
      }
    }

    if (windowWidth >= MOBILE_BREAKPOINT && windowWidth < DESKTOP_BREAKPOINT) {
      if (!this.tableHeightTablet) {
        this.tableHeightTablet = this.getBodyRowsHeight();
      }

      if (this.tableHeightTablet) {
        newTableHeight = this.tableHeightTablet;
      }
    }

    if (windowWidth >= DESKTOP_BREAKPOINT) {
      if (!this.tableHeightDesktop) {
        this.tableHeightDesktop = this.getBodyRowsHeight();
      }

      if (this.tableHeightDesktop) {
        newTableHeight = this.tableHeightDesktop;
      }
    }

    if (newTableHeight) {
      this.tableHeight = `${newTableHeight + this.viewPortScrollbarXSize}px`;
      this.tableBuffer = newTableHeight * 1.5;
    }
  }

  /**
   * Returns the total height of the first n rows, where n is defined in the constant `maxVisibleRows`.
   */
  private getBodyRowsHeight() {
    const scrollOffset = this.viewPort.measureScrollOffset();
    this.viewPort.scrollToOffset(0);

    const bodyRowsHeight = this.tableBodyRows
      .toArray()
      .slice(0, this.maxVisibleRows)
      .reduce((acc, row, index, rows) => {
        const height = row.nativeElement.getBoundingClientRect().height;
        acc += height;

        return acc;
      }, 0);

    this.viewPort.scrollToOffset(scrollOffset);

    return bodyRowsHeight;
  }

  private resyncTableComponents() {
    this.syncHeaderContainerWidth();
    this.syncHeaderContainerPosition();
    this.syncStickyColumns();
    this.syncStickyShadows();
  }
}
