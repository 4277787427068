import { CurrencyDto } from '@app/generated/models/currency-dto';
import { CurrencyPairDowntimeDto } from '@app/generated/models/currency-pair-downtime-dto';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { CurrencyEventType } from '@app/shared/enums/currency.enum';

export type CurrencyEvent = {
  currencyName: CurrencyDto['name'];
  currencyPair?: CurrencyPairDowntimeDto['currencyPair'];
  downtimeFrom?: CurrencyPairDowntimeDto['downtimeFrom'];
  downtimeTo?: CurrencyPairDowntimeDto['downtimeTo'];
  type: CurrencyEventType;
};

export const getCurrencyEvent = (currency: CurrencyDto, currencyPair?: CurrencyPairDto) => {
  if (!currency?.events) {
    return [];
  }

  const now = new Date();
  const currencyEvents = currency.events
    .map((event) => ({
      ...event,
      downtimeFromDate: event.downtimeFrom ? new Date(event.downtimeFrom) : null,
      downtimeToDate: event.downtimeTo ? new Date(event.downtimeTo) : null,
    }))
    .filter((event) => (event.downtimeToDate ? event.downtimeToDate.getTime() >= now.getTime() : true))
    .sort((eventA, eventB) =>
      eventB.downtimeToDate && eventA.downtimeToDate
        ? eventB.downtimeToDate.getTime() - eventA.downtimeToDate.getTime()
        : 0,
    )
    .map((event) => {
      const eventType =
        event.type === CurrencyEventType.Delisting && event.affectsCurrency
          ? CurrencyEventType.DelistingCurrency
          : event.type;

      return {
        currencyName: currency.name,
        currencyPair: event.currencyPair,
        downtimeFrom: event.downtimeFrom,
        downtimeTo: event.downtimeTo,
        type: eventType as CurrencyEventType,
      };
    });

  if (currencyPair) {
    return currencyEvents.filter((event) => event.currencyPair === currencyPair.name);
  }

  return [...new Map(currencyEvents.map((event) => [event.type, event])).values()];
};

export const getCurrencyEventByType = (
  currency: CurrencyDto,
  currencyEventType: CurrencyEventType,
  currencyPair?: CurrencyPairDto,
) => {
  const currencyEvents = getCurrencyEvent(currency, currencyPair);
  return currencyEvents.find((event) => event.type === currencyEventType);
};

export const getCurrencyPairName = (
  firstCurrencyName: CurrencyDto['name'],
  secondCurrencyName: CurrencyDto['name'],
): CurrencyPairDto['name'] => `${firstCurrencyName}_${secondCurrencyName}`;
