import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankidButtonComponent } from './components/bankid-button/bankid-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputComponent } from '@app/authentication/shared/components/code-input/code-input.component';
import { CodeInputModule } from 'angular-code-input';
import { ReferralClickIdModule } from '@app/unauthenticated/referral-click-id.module';
import { LocalizeRouterPipe } from '@gilsdav/ngx-translate-router';

@NgModule({
  declarations: [BankidButtonComponent, CodeInputComponent],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    TranslateModule.forChild(),
    CodeInputModule,
    ReferralClickIdModule,
    LocalizeRouterPipe,
  ],
  exports: [BankidButtonComponent, CodeInputComponent],
})
export class AuthSharedModule {}
