import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AbstractComponent } from '@app-shared/components/form/abstract/abstract.component';
import { InputComponent } from '@app/shared/components/form/input/input.component';

@Component({
  selector: 'app-email',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputComponent],
  templateUrl: './email.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailComponent),
      multi: true,
    },
  ],
})
export class EmailComponent extends AbstractComponent {
  @Input() parent!: UntypedFormGroup;
  @Input() size?: 'small' | 'medium' = 'medium';

  constructor() {
    super();
  }

  public get email() {
    return this.parent.get(this.formControlName || 'email');
  }
}
