import { Component, OnInit } from '@angular/core';
import { CategorySlug, WordpressContentApiService, WpPost } from '@app/shared/services/wordpress-content-api.service';

const STATIC_MOST_VISITED_ARTICLE = {
  link: 'https://coinmate.io/cz/krypto-akademie/proc-bitcoin/',
  title: 'Proč Bitcoin',
  image: {
    url: '/assets/img/homepage/education/article-1.png',
    alt: 'Proč Bitcoin',
  },
};

@Component({
  selector: 'homepage-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent implements OnInit {
  categoryTitles = [
    'home-page.education.article-1.heading',
    'home-page.education.article-2.heading',
    'home-page.education.article-3.heading',
  ];

  articles: (WpPost | undefined)[] = [...Array(3).keys()].map(() => undefined);

  constructor(protected wordpressService: WordpressContentApiService) {}

  async ngOnInit() {
    try {
      const [cryptoBlogArticle, cryptoNewsArticle] = await Promise.all([
        this.wordpressService.getArticleContent(CategorySlug.CryptoBlog),
        this.wordpressService.getArticleContent(CategorySlug.CryptoNews),
      ]);
      this.articles = [STATIC_MOST_VISITED_ARTICLE, cryptoNewsArticle, cryptoBlogArticle].filter(Boolean);
    } catch (e) {
      this.articles = [STATIC_MOST_VISITED_ARTICLE];
    }
  }
}
