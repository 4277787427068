import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnChanges {
  @Input() videoTitle!: string;
  @Input() videoTag!: string;
  /** This ID is implicitly trusted. Do not pass any parameters from outside the application! */
  @Input() videoYoutubeId!: string;
  @Input() thumbnailSrc!: string;
  @Input() size: 'small' | 'large' = 'large';

  playing = false;
  videoUrl: SafeResourceUrl | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  /** This setter ensures the player is scrolled into view upon loading. */
  @ViewChild('player') set playerRef(elementRef: ElementRef) {
    if (elementRef !== undefined) {
      // webkit-specific method, used since Safari does not support scrollIntoView params
      if (elementRef.nativeElement.scrollIntoViewIfNeeded) {
        elementRef.nativeElement.scrollIntoViewIfNeeded();
      } else {
        elementRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }

  ngOnInit() {
    this.videoUrl = this.constructVideoUrl(this.videoYoutubeId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoYoutubeId) {
      this.stop();
      this.videoUrl = this.constructVideoUrl(this.videoYoutubeId);
    }
  }

  play() {
    this.playing = true;
  }

  stop() {
    this.playing = false;
  }

  private constructVideoUrl(videoYoutubeId: string) {
    const url = `https://www.youtube.com/embed/${videoYoutubeId}?autoplay=1&modestbranding=1&rel=0`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
