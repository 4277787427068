import { Component, Input } from '@angular/core';

@Component({
  selector: 'homepage-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent {
  @Input() lang = 'cs';
  @Input() isLogged = false;
}
