<section class="homepage-numbers">
  <div class="homepage-section-container">
    <h2 class="display-h2 text-center mb-8 lg:mb-16">{{ 'home-page.numbers.heading' | translate }}</h2>
    <div class="items">
      <ng-container *ngFor="let number of numbers">
        <div class="item">
          <div class="flex flex-col items-center lg:items-start">
            <div class="display-h1 flex mb-4 lg:mb-8">
              <span
                appAnimatedCounter
                class="value"
                [value]="number.value | translate"
                [duration]="1000"
                [startOnView]="true"
                [template]="number.template || '' | translate"
              ></span>
            </div>
            <div class="max-w-[23ch] display-paragraph-large" [innerHTML]="number.heading | translate"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
