<span
  class="step-number"
  [ngClass]="{
    'step-number-active': active,
    'step-number-completed': completed,
  }"
>
  <svg-icon src="/assets/icons/40/bg-shape.svg" class="step-number-bg"></svg-icon>
  <span class="step-number-value">{{ value }}</span>
</span>
