<div class="cm-video" [ngClass]="{ 'cm-video-small': size === 'small' }">
  <iframe
    *ngIf="playing"
    class="cm-video-player"
    type="text/html"
    title="{{ videoTitle | translate }}"
    [src]="videoUrl"
    allow="autoplay; fullscreen"
    allowfullscreen
    #player
  ></iframe>

  <div *ngIf="!playing" (click)="play()" class="cm-video-placeholder">
    <div class="cm-video-thumbnail">
      <img src="{{ thumbnailSrc }}" alt="{{ videoTitle | translate }}" />
    </div>
    <img src="/assets/img/play.svg" alt="{{ 'shared.play' | translate }}" class="cm-play-button" />
    <div *ngIf="videoTitle" class="cm-video-title">
      <p *ngIf="videoTag" class="tag">{{ videoTag | translate }}</p>
      <p class="title" [innerHTML]="videoTitle | translate"></p>
    </div>
  </div>
</div>
