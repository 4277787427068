<div class="tooltip" role="tooltip" aria-hidden="false" [attr.id]="tooltipId">
  <ng-container
    *ngIf="isTooltipContentTemplate(tooltipContent); else tooltipString"
    [ngTemplateOutlet]="tooltipContent"
  ></ng-container>

  <ng-template #tooltipString>
    {{ tooltipContent }}
  </ng-template>
</div>
