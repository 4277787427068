import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HomepageComponent } from './homepage.component';
import { SharedModule } from '@app/shared/shared.module';
import { HomepageRoutingModule } from '@app/unauthenticated/homepage/homepage-routing.module';
import { IntroComponent } from './intro/intro.component';
import { AuthSharedModule } from '@app/authentication/shared/auth-shared.module';
import { MediaComponent } from './media/media.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { NumbersComponent } from './numbers/numbers.component';
import { PartnersComponent } from './partners/partners.component';
import { SafetyComponent } from './safety/safety.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { EducationComponent } from './education/education.component';
import { OtherComponent } from './other/other.component';
import { RegisterComponent } from './register/register.component';
import { YoutubeVideoModalComponent } from '@app/unauthenticated/homepage/modals/youtube-video/youtube-video-modal.component';
import { OurStoryModule } from '@app/unauthenticated/our-story/our-story.module';
import { SkeletonComponent } from '@app/shared/components/skeleton/skeleton.component';
import { NewsletterComponent } from '@app/unauthenticated/homepage/newsletter/newsletter.component';

@NgModule({
  declarations: [
    HomepageComponent,
    IntroComponent,
    MediaComponent,
    BenefitsComponent,
    SolutionsComponent,
    NewsletterComponent,
    NumbersComponent,
    PartnersComponent,
    SafetyComponent,
    TutorialComponent,
    EducationComponent,
    OtherComponent,
    RegisterComponent,
    YoutubeVideoModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomepageRoutingModule,
    AuthSharedModule,
    OurStoryModule,
    SkeletonComponent,
    NgOptimizedImage,
  ],
  exports: [NumbersComponent],
})
export class HomepageModule {}
