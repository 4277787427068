import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { ColorScheme, ColorSchemeService } from '@app/shared/services/color-scheme.service';

const ICONS_FOLDER = '/assets/crypto/color/';

@Component({
  selector: 'app-currency-pair-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './currency-pair-icon.component.html',
  styleUrl: './currency-pair-icon.component.scss',
})
export class CurrencyPairIconComponent implements OnInit, OnChanges {
  @Input() firstCurrencyName: CurrencyPairDto['firstCurrency'] | undefined;
  @Input() secondCurrencyName: CurrencyPairDto['secondCurrency'] | undefined;

  isDarkColorScheme$: Observable<boolean> = of(false);
  firstCurrencyIcon = '';
  secondCurrencyIcon = '';

  constructor(private colorSchemeService: ColorSchemeService) {
    this.isDarkColorScheme$ = this.colorSchemeService
      .getColorScheme()
      .pipe(map((colorScheme) => colorScheme === ColorScheme.DARK));
  }

  ngOnInit() {
    if (this.firstCurrencyName && this.secondCurrencyName) {
      const firstCurrencyIcon = this.firstCurrencyName.toLowerCase();
      const secondCurrencyIcon = this.secondCurrencyName.toLowerCase();
      this.firstCurrencyIcon = `${ICONS_FOLDER}${firstCurrencyIcon}.svg`;
      this.secondCurrencyIcon = `${ICONS_FOLDER}${secondCurrencyIcon}.svg`;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.firstCurrencyName?.currentValue) {
      const firstCurrencyIcon = changes.firstCurrencyName.currentValue.toLowerCase();
      this.firstCurrencyIcon = `${ICONS_FOLDER}${firstCurrencyIcon}.svg`;
    }

    if (changes.secondCurrencyName?.currentValue) {
      const secondCurrencyIcon = changes.secondCurrencyName.currentValue.toLowerCase();
      this.secondCurrencyIcon = `${ICONS_FOLDER}${secondCurrencyIcon}.svg`;
    }
  }
}
