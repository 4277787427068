import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslatePipe } from '@ngx-translate/core';
import { ActionButtonComponent } from '@app/shared/components/form/action-button/action-button.component';
import { TooltipService } from '@app/shared/components/tooltip/tooltip.service';

@Component({
  selector: 'app-rich-tooltip-footer',
  standalone: true,
  imports: [CommonModule, ActionButtonComponent, SvgIconComponent, TranslatePipe],
  templateUrl: './rich-tooltip-footer.component.html',
  styleUrl: '../../rich-tooltip.component.scss',
  host: {
    '[class.rich-tooltip-footer]': 'true',
  },
  encapsulation: ViewEncapsulation.None,
})
export class RichTooltipFooterComponent {
  @Input() hideClose = false;

  constructor(private tooltipService: TooltipService) {}

  close() {
    this.tooltipService.closeTooltip();
  }
}
