import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
})
export class HeroSectionComponent {
  @Input() imgPlacement!: 'left' | 'right';
  @Input() headingSize!: 'heading' | 'subheading';
  @Input() heading = '';
  @Input() text = '';
  @Input() imgAddress = '';
  @Input() imgAlt = '';

  private static imgPlacementToSectionClass = {
    left: 'img-left',
    right: 'img-right',
  };

  getSectionClass() {
    return HeroSectionComponent.imgPlacementToSectionClass[this.imgPlacement];
  }
}
