<section class="homepage-newsletter">
  <div class="homepage-section-container">
    <div class="mx-auto md:max-w-[850px]">
      <div
        class="grid grid-cols-[1fr,auto] grid-rows-[auto,auto,1fr] sm:grid-cols-[auto,1fr] gap-y-16px sm:gap-x-24px sm:gap-y-20px items-center sm:items-start"
      >
        <img
          src="/assets/img/homepage/envelope.png"
          class="max-w-[90px] md:max-w-[180px] lg:max-w-full sm:row-span-3"
        />
        <h2 class="order-first sm:order-none display-h4 md:display-h2 md:mt-20px">
          {{ 'homepage.newsletter.heading' | translate }}
        </h2>
        <p class="col-span-2 sm:col-span-none sm:col-start-2 display-paragraph">
          {{ 'homepage.newsletter.text' | translate }}
        </p>
        <div class="col-span-2 sm:col-span-none sm:col-start-2 flex flex-col gap-y-8px">
          <form
            [formGroup]="newsletterForm"
            (ngSubmit)="onSubmit()"
            class="flex flex-col sm:flex-row gap-y-8px sm:gap-x-12px"
          >
            <app-input
              [size]="'small'"
              [placeholder]="'shared.common.email-placeholder' | translate"
              [formControlName]="'email'"
              [hasSuccess]="formState === ApiStateStatus.success"
              [hasError]="formState === ApiStateStatus.error"
              class="flex-1 sm:max-w-[312px]"
              type="email"
            >
              <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside></svg-icon>
            </app-input>
            <app-action-button
              *ngIf="formState !== ApiStateStatus.success"
              [facet]="'secondary'"
              [size]="'small'"
              [disabled]="newsletterForm.invalid"
              [waiting]="formState === ApiStateStatus.loading"
              [label]="(firstAttempt ? 'shared.newsletter.subscribe' : 'shared.newsletter.subscribe-again') | translate"
              [appearance]="'shrink-0'"
              type="submit"
            ></app-action-button>
          </form>
          <div *ngIf="formState === ApiStateStatus.success" class="flex gap-x-4px items-center font-bold">
            <svg-icon src="/assets/icons/16/checkmark-circle.svg"></svg-icon>
            <span class="max-w-[420px]">{{ 'shared.newsletter.success' | translate }}</span>
          </div>
          <div *ngIf="formState === ApiStateStatus.error" class="flex gap-x-4px items-center font-bold">
            <svg-icon src="/assets/icons/16/warning.svg"></svg-icon>
            <span class="max-w-[420px]">{{ 'shared.newsletter.error' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
