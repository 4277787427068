import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrackingParametersComponent } from '@app/unauthenticated/trackingParameters.component';
import { ActivatedRoute } from '@angular/router';
import { LocalizedRouterService } from '@app/shared/services/localized-router.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.scss'],
})
export class OurStoryComponent extends TrackingParametersComponent implements OnInit, OnDestroy {
  lang: string | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: LocalizedRouterService,
  ) {
    super(platformId);
  }

  ngOnInit() {
    this.route.fragment.pipe(takeUntil(this.unsubscribe$)).subscribe((fragment) => {
      if (fragment === 'support') this.router.navigateByUrl('/support');
    });
    this.setUpLangListener();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setUpLangListener() {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      this.lang = event.lang;
    });
  }
}
