import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@app/shared/services/language.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { PlainTooltipDirective } from '@app/shared/components/tooltip/plain-tooltip/plain-tooltip.directive';
import { ThemeImageComponent } from '@app/shared/components/theme-image/theme-image.component';

export enum LanguageSwitchTypeEnum {
  default = 'default',
  basic = 'basic',
  footer = 'footer',
  menu = 'menu',
  menuMobile = 'menu-mobile',
}

@Component({
  selector: 'app-language-switch',
  standalone: true,
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
  imports: [CommonModule, SvgIconComponent, PlainTooltipDirective, ThemeImageComponent],
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {
  @Input() type: LanguageSwitchTypeEnum = LanguageSwitchTypeEnum.default;
  @Output() changeLanguage = new EventEmitter<string>();

  loggedIn$ = this.guiParams.loggedIn$;
  languageSwitchTypeEnum = LanguageSwitchTypeEnum;
  currentLang = '';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private guiParams: GuiParams,
    private langService: LanguageService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => (this.currentLang = val.lang));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async switchLanguage() {
    const language = this.translateService.currentLang === 'en' ? 'cs' : 'en';
    this.loggedIn$.pipe(take(1)).subscribe(async (loggedIn: boolean) => {
      if (loggedIn) {
        this.langService.changeAndSaveLanguage(language);
        return;
      }

      this.langService.changeLanguage(language);
      // Uncomment if there are problems with translation
      // const url = this.router.url.replace(/\/(en|cs)\//, `/${language}/`);
      // await this.router.navigateByUrl(url);
    });
  }
}
