<div class="currency-event-countdown">
  <div class="currency-event-countdown-images">
    <img class="currency-event-countdown-image" src="/assets/spinner-animated.svg" alt="Coinmate spinner" />
    <app-currency-icon class="currency-event-countdown-image" [currencyName]="currencyName"></app-currency-icon>
  </div>

  <div>
    <h2 class="currency-event-countdown-title">{{ title }}</h2>
    <h3 class="currency-event-countdown-description">{{ description }}</h3>
  </div>

  <ng-container *ngIf="timeLeft$ | async as timeLeft">
    <div class="currency-event-countdown-time-parts">
      <span class="currency-event-countdown-time-part">
        <span class="currency-event-countdown-time-value">
          <span
            class="currency-event-countdown-time-value-content"
            [attr.data-previous-value]="timeLeft.previous.days"
            [attr.data-current-value]="timeLeft.current.days"
            [@slideUp]="timeLeft.current.days"
          >
            <span class="sr-only">{{ timeLeft.current.days }}</span>
          </span>
        </span>
        <span class="currency-event-countdown-time-unit">{{ timeLeft.units.days }}</span>
      </span>
      <span class="currency-event-countdown-time-part">
        <span class="currency-event-countdown-time-value">
          <span
            class="currency-event-countdown-time-value-content"
            [attr.data-previous-value]="timeLeft.previous.hours"
            [attr.data-current-value]="timeLeft.previous.hours"
            [@slideUp]="timeLeft.current.hours"
          >
            <span class="sr-only">{{ timeLeft.current.hours }}</span>
          </span>
        </span>
        <span class="currency-event-countdown-time-unit">{{ timeLeft.units.hours }}</span>
      </span>
      <span class="currency-event-countdown-time-part">
        <span class="currency-event-countdown-time-value">
          <span
            class="currency-event-countdown-time-value-content"
            [attr.data-previous-value]="timeLeft.previous.minutes"
            [attr.data-current-value]="timeLeft.current.minutes"
            [@slideUp]="timeLeft.current.minutes"
          >
            <span class="sr-only">{{ timeLeft.current.minutes }}</span>
          </span>
        </span>
        <span class="currency-event-countdown-time-unit">{{ timeLeft.units.minutes }}</span>
      </span>
      <span class="currency-event-countdown-time-part">
        <span class="currency-event-countdown-time-value">
          <span
            class="currency-event-countdown-time-value-content"
            [attr.data-previous-value]="timeLeft.previous.seconds"
            [attr.data-current-value]="timeLeft.current.seconds"
            [@slideUp]="timeLeft.current.seconds"
          >
            <span class="sr-only">{{ timeLeft.current.seconds }}</span>
          </span>
        </span>
        <span class="currency-event-countdown-time-unit">{{ timeLeft.units.seconds }}</span>
      </span>
    </div>
  </ng-container>

  <ng-content></ng-content>
</div>
