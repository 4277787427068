import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';
import { Subscription } from 'rxjs';

const getColorClassname = (value: number, decimalPlaces = 2) => {
  const changeBN = BigNumber(value).decimalPlaces(decimalPlaces);
  if (changeBN.lt(0)) return 'value-negative';
  if (changeBN.gt(0)) return 'value-positive';
  return '';
};

@Component({
  selector: 'app-colored-percent-change-text',
  template: `
    <span [ngClass]="[class, colorClassName, 'font-[inherit]']">
      {{ change | appPercentageChange : decimalPlaces | async }}
    </span>
  `,
  styleUrls: ['./colored-value-text.component.scss'],
  host: { class: 'font-[inherit]' },
})
export class ColoredPercentChangeTextComponent implements OnChanges {
  @Input() change!: number;
  @Input() class = '';
  @Input() decimalPlaces = 2;

  colorClassName = '';

  ngOnChanges() {
    this.colorClassName = getColorClassname(this.change, this.decimalPlaces);
  }
}

@Component({
  selector: 'app-colored-currency-value-text',
  template: `
    <app-currency-value
      [ngClass]="[class, colorClassName, 'font-[inherit]']"
      [value]="value"
      [currency]="currency"
    ></app-currency-value>
  `,
  styleUrls: ['./colored-value-text.component.scss'],
  host: { class: 'font-[inherit]' },
})
export class ColoredCurrencyValueTextComponent implements OnChanges, OnDestroy {
  @Input() value!: number;
  @Input() class = '';
  @Input() currency!: string;

  colorClassName = '';
  decimalPlaces = 2;

  private currenciesRoundingSubscription: Subscription;

  constructor(private currenciesFacade: Currencies) {
    this.currenciesRoundingSubscription = this.currenciesFacade.currenciesRounding$.subscribe((currenciesRounding) => {
      this.decimalPlaces = currenciesRounding[this.currency] || 2;
      this.colorClassName = getColorClassname(this.value, this.decimalPlaces);
    });
  }

  ngOnChanges() {
    this.colorClassName = getColorClassname(this.value, this.decimalPlaces);
  }

  ngOnDestroy() {
    this.currenciesRoundingSubscription.unsubscribe();
  }
}
