import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import BigNumber from 'bignumber.js';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';
import { AppCurrencyFloorPipe } from '@app/shared/pipes/app-currency-floor.pipe';
import { Locales, UserInfoProfileService } from '@app/shared/services/user-info-profile.service';
import { TooltipComponent } from '@app/shared/components/tooltip/tooltip.component';
import { TooltipPosition } from '@app/shared/components/tooltip/tooltip.enum';
import { NUMERIC_REGEX } from '@app/shared/const/regex.const';

@Component({
  selector: 'app-currency-value',
  standalone: true,
  imports: [CommonModule, TooltipComponent],
  templateUrl: './currency-value.component.html',
  host: { class: 'font-[inherit]' },
})
export class CurrencyValueComponent implements OnInit, OnChanges {
  @Input() value: number | null | undefined = 0;
  @Input() currency: string = '';
  @Input() hideCurrency: boolean = false;
  @Input() tooltipPosition: TooltipPosition = TooltipPosition.left;
  @Input() tooltipUsePopover = true;
  @Input() tooltipClass?: string;

  public value$: Observable<{
    valueFormatted: string;
    valueParts?: {
      integerPart: string;
      decimalSeparator: string;
      decimalPart: string;
    };
  } | null> = of(null);
  public tooltipText: string | null = null;

  private valueSubject = new BehaviorSubject<number | null | undefined>(this.value);
  private currencyFormatter: AppCurrencyFloorPipe;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly userInfoProfileService: UserInfoProfileService,
    private readonly currenciesFacade: Currencies,
  ) {
    this.currencyFormatter = new AppCurrencyFloorPipe(this.userInfoProfileService, this.currenciesFacade);
  }

  ngOnInit() {
    this.value$ = combineLatest([this.valueSubject, this.userInfoProfileService.numberLocale$]).pipe(
      withLatestFrom(this.currenciesFacade.currenciesRounding$),
      takeUntilDestroyed(this.destroyRef),
      map(
        ([[value, numberLocale], currenciesRounding]: [
          [number | null | undefined, Locales],
          { [key: string]: number },
        ]) => {
          if (typeof value !== 'number') {
            return null;
          }

          const valueBN = new BigNumber(value);
          const decimalPlaces = currenciesRounding[this.currency] ?? 2;
          const valueFlooredBN = valueBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);

          if (valueFlooredBN.isZero() && !valueBN.isZero()) {
            const [_, fractionalPart] = valueBN.toString().split('.');
            const leadingZerosCount = fractionalPart.match(/^0+/)?.[0].length ?? 0;

            const valueFormatted = this.formatCurrency(valueBN.toNumber(), numberLocale);
            this.tooltipText = this.formatCurrency(valueBN.toNumber(), numberLocale);

            const [__, integerPart, decimalSeparator] = valueFormatted.split(NUMERIC_REGEX);
            const decimalPart = fractionalPart.substring(0, leadingZerosCount + decimalPlaces);

            return {
              valueFormatted: valueBN.toString(),
              valueParts: {
                integerPart,
                decimalSeparator,
                decimalPart,
              },
            };
          }

          return {
            valueFormatted: this.formatCurrency(valueBN.toNumber(), numberLocale),
          };
        },
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.valueSubject.next(changes.value.currentValue);
    }
  }

  private formatCurrency(value: number, numberLocale: string) {
    return this.currencyFormatter.transformNumber(value, numberLocale, this.currency, false);
  }
}
