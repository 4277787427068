import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ColorScheme, ColorSchemeService } from '@app/shared/services/color-scheme.service';

@Component({
  selector: 'app-theme-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './theme-image.component.html',
})
export class ThemeImageComponent implements OnInit, OnChanges {
  @Input() srcLight: string = '';
  @Input() srcDark: string = '';
  @Input() alt: string = '';
  @Input() imgClass: string = '';
  @Input() height: number | undefined;

  src$ = of('');

  private destroyRef = inject(DestroyRef);
  private srcLightSubject = new BehaviorSubject<string>(this.srcLight);
  private srcDarkSubject = new BehaviorSubject<string>(this.srcDark);

  constructor(private colorSchemeService: ColorSchemeService) {}

  ngOnInit() {
    this.src$ = combineLatest([
      this.srcLightSubject,
      this.srcDarkSubject,
      this.colorSchemeService.getColorScheme(),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(([srcLight, srcDark]) => Boolean(srcLight && srcDark)),
      tap(([srcLight, srcDark]) => {
        this.preload(srcLight, srcDark);
      }),
      map(([srcLight, srcDark, colorScheme]) => {
        return colorScheme === ColorScheme.LIGHT ? srcLight : srcDark;
      }),
    );

    this.srcLightSubject.next(this.srcLight);
    this.srcDarkSubject.next(this.srcDark);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.srcLight && !changes.srcLight.firstChange) {
      this.srcLightSubject.next(changes.srcLight.currentValue);
    }

    if (changes.srcDark && !changes.srcLight.firstChange) {
      this.srcDarkSubject.next(changes.srcDark.currentValue);
    }
  }

  // Preload images for smooth image switching
  private preload(srcLight: string, srcDark: string) {
    [srcLight, srcDark]
      .filter((url) => url)
      .forEach((url) => {
        new Image().src = url;
      });
  }
}
