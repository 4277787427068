import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@angular/common';
import { Locales, UserInfoProfileService } from '@app/shared/services/user-info-profile.service';

@Pipe({
  name: 'appFloor',
})
export class AppFloorPipe implements PipeTransform, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private readonly userInfoProfileService: UserInfoProfileService) {}

  transformNumber(value: number, numberLocale: string = Locales.cs, decimalPlaces: number = 8): string {
    const valueBN = new BigNumber(value);
    const flooredValueBN = valueBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);
    const digitsInfo = ['.', decimalPlaces, '-', decimalPlaces].join('');
    return formatNumber(flooredValueBN.toNumber(), numberLocale, digitsInfo);
  }

  transform(value$: Observable<number> | number, decimalPlaces: number = 8): Observable<string> {
    if (typeof value$ === 'number') {
      value$ = of(value$);
    }

    return combineLatest([value$, this.userInfoProfileService.numberLocale$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([value, numberLocale]: [number, string]) => this.transformNumber(value, numberLocale, decimalPlaces)),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export const floor = (value: number, decimalPlaces: number = 8, thousandSeparator?: string): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return '';
  }
  const flooredValue = new BigNumber(value).decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);
  return thousandSeparator ? addThousandSeparator(flooredValue.toString(), thousandSeparator) : flooredValue.toString();
};

const addThousandSeparator = (value: string, thousandSeparator: string): string => {
  const decimalSeparator = '.';
  const parts = value.split(decimalSeparator);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return parts.join(decimalSeparator);
};
