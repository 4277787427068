import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent {
  @Input() time = '';
  @Input() heading = '';
  @Input() text = '';
  @Input() imgSrc = '';
  @Input() imgAlt = '';
}
