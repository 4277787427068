import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
interface link {
  href: string;
  image: string;
  alt: string;
}
@Component({
  selector: 'homepage-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnChanges {
  @Input() lang = 'cs';
  @Input() colorScheme = 'light';

  links: link[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang || changes.colorScheme) {
      this.createLinks();
    }
  }

  private createLinks() {
    this.links = [
      { href: 'https://cc.cz/tag/coinmate/', image: 'czechcrunch.svg', alt: 'CzechCrunch' },
      { href: 'https://www.euro.cz/vyhledavani/?qs=coinmate', image: 'euro-cz.svg', alt: 'Euro.cz' },
      {
        href: 'https://www.finmag.cz/vyhledavani?q=coinmate#gsc.tab=0&gsc.q=coinmate&gsc.page=1',
        image: 'finmag.svg',
        alt: 'Finmag',
      },
      { href: 'https://forbes.cz/tag/kryptomeny/?search=coinmate', image: 'forbes.svg', alt: 'Forbes' },
      {
        href: 'https://search.seznam.cz/s/novinky/?q=coinmate&sourceid=novinky&thru=hint-news',
        image: 'novinky.svg',
        alt: 'Novinky',
      },
      {
        href: 'https://ekonom.cz/?search%5Bwhat%5D=coinmate&p=400000_search#result',
        image: 'ekonom.svg',
        alt: 'Ekonom',
      },
      { href: 'https://www.e15.cz/tag/Coinmate/1', image: 'E15.svg', alt: 'E15' },
      {
        href: 'https://search.seznam.cz/s/zpravy/?q=coinmate&sourceid=zpravy&thru=hint-news',
        image: 'seznam-zpravy.svg',
        alt: 'Seznam Zprávy',
      },
      { href: 'https://www.youtube.com/watch?v=A6WrkrSYNPs', image: 'startupjobs.svg', alt: 'StartupJobs – SharkTalk' },
      {
        href: 'https://hn.cz/?p=000000_search&search%5Bwhat%5D=coinmate#result',
        image: 'HN.svg',
        alt: 'Hospodářské noviny',
      },
      { href: 'https://hledej.idnes.cz/clanky.aspx?q=coinmate', image: 'idnes-cz.svg', alt: 'iDNES' },
    ];
  }
}
