import { Component, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
})
export class HelpIconComponent {
  @Input() text!: string;
  @Input() leftOffset: number = -180;
  @Input() iconClasses: string = '';
  tooltipVisible = false;
  private globalClickListener: (() => void | undefined) | undefined;

  constructor(private renderer: Renderer2) {}

  toggleTooltip(event: Event): void {
    event.stopPropagation();
    this.tooltipVisible = !this.tooltipVisible;
    this.globalClickListener = this.renderer.listen('document', 'click', () => {
      this.tooltipVisible = false;
      if (this.globalClickListener) this.globalClickListener(); // stop listening to click events
    });
  }
}
