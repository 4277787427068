import { Component } from '@angular/core';

@Component({
  selector: 'homepage-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.scss'],
})
export class NumbersComponent {
  numbers = [
    {
      heading: 'home-page.numbers.number-1.heading',
      value: 'home-page.numbers.number-1.value',
      template: 'home-page.numbers.number-1.template',
    },
    {
      heading: 'home-page.numbers.number-2.heading',
      value: 'home-page.numbers.number-2.value',
      template: 'home-page.numbers.number-2.template',
    },
    {
      heading: 'home-page.numbers.number-3.heading',
      value: 'home-page.numbers.number-3.value',
      template: 'home-page.numbers.number-3.template',
    },
  ];
}
