import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { CurrencyDto } from '@app/generated/models/currency-dto';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { RibbonComponent } from '@app/shared/components/ribbon/ribbon.component';
import { RichTooltipHeaderComponent } from '@app/shared/components/tooltip/rich-tootlip/components/rich-tooltip-header/rich-tooltip-header.component';
import { RichTooltipBodyComponent } from '@app/shared/components/tooltip/rich-tootlip/components/rich-tooltip-body/rich-tooltip-body.component';
import { RichTooltipFooterComponent } from '@app/shared/components/tooltip/rich-tootlip/components/rich-tooltip-footer/rich-tooltip-footer.component';
import { RichTooltipDirective } from '@app/shared/components/tooltip/rich-tootlip/rich-tooltip.directive';
import { CurrencyEventType } from '@app/shared/enums/currency.enum';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { CurrencyEvent, getCurrencyEvent } from '@app/shared/utils/currency.util';
import { AppCurrencyPairDisplayPipe } from '@app/shared/pipes/app-currency-pair-display.pipe';
import { AppCountdownPipe } from '@app/shared/pipes/app-countdown.pipe';

@Component({
  selector: 'app-currency-event-ribbon',
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
    SvgIconComponent,
    RibbonComponent,
    RichTooltipHeaderComponent,
    RichTooltipBodyComponent,
    RichTooltipFooterComponent,
    RichTooltipDirective,
    LocalizedDatePipe,
    AppCurrencyPairDisplayPipe,
    AppCountdownPipe,
  ],
  templateUrl: './currency-event-ribbon.component.html',
  styleUrls: ['./currency-event-ribbon.component.scss'],
})
export class CurrencyEventRibbonComponent implements OnInit {
  @Input() currency!: CurrencyDto;
  @Input() currencyPair!: CurrencyPairDto;

  protected currencyEventTypeEnum = CurrencyEventType;

  currencyEvents: CurrencyEvent[] = [];

  ngOnInit() {
    this.currencyEvents = getCurrencyEvent(this.currency, this.currencyPair);
  }
}
