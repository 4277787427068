import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { combineLatest, map, Observable, of, Subject } from 'rxjs';
import BigNumber from 'bignumber.js';
import { Locales, UserInfoProfileService } from '@app/shared/services/user-info-profile.service';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'appPercentageChange',
})
export class AppPercentageChangePipe implements PipeTransform, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private readonly userInfoProfileService: UserInfoProfileService) {}

  transformNumber(value: number, numberLocale: string = Locales.cs, decimalPlaces: number = 2) {
    const valueBN = new BigNumber(value);
    const flooredValueBN = valueBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);
    const sign = flooredValueBN.gt(0) ? '+' : '';

    return `${sign}${formatNumber(flooredValueBN.toNumber(), numberLocale, `.${decimalPlaces}-${decimalPlaces}`)}%`;
  }

  transform(value$: Observable<number> | number, decimalPlaces: number = 2): Observable<string> {
    if (typeof value$ === 'number') {
      value$ = of(value$);
    }

    return combineLatest([value$, this.userInfoProfileService.numberLocale$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([value, numberLocale]: [number, string]) => {
        return this.transformNumber(value, numberLocale, decimalPlaces);
      }),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

@Pipe({
  name: 'appPercentage',
})
export class AppPercentagePipe implements PipeTransform, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private readonly userInfoProfileService: UserInfoProfileService) {}

  transformNumber(value: number, numberLocale: string = Locales.cs, decimalPlaces: number = 2) {
    const valueBN = new BigNumber(value);
    const flooredValueBN = valueBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);
    return `${formatNumber(flooredValueBN.toNumber(), numberLocale, `.${decimalPlaces}-${decimalPlaces}`)}%`;
  }

  transform(value$: Observable<number> | number, decimalPlaces: number = 2): Observable<string> {
    if (typeof value$ === 'number' || !value$) {
      value$ = of(value$);
    }

    return combineLatest([value$, this.userInfoProfileService.numberLocale$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([value, numberLocale]: [number, string]) => {
        return this.transformNumber(value, numberLocale, decimalPlaces);
      }),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
