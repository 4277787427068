import { TradeItemDto } from '@app/generated/models/trade-item-dto';
import { BestOrderItemDto } from '@app/generated/models/best-order-item-dto';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { TradeStatisticsDto } from '@app/generated/models/trade-statistics-dto';
import { OpenOrderDto } from '@app/generated/models/open-order-dto';
import { AccountDto } from '@app/generated/models/account-dto';
import { OrderFormDto } from '@app/generated/models/order-form-dto';
import { CurrencyTradeLimitDto } from '@app/generated/models/currency-trade-limit-dto';
import { OrderDirection } from '@app/authenticated/trading-platform/ui/trading-platform.enum';

export const tradingPlatformFeatureKey = 'tradingPlatform';

export interface OrderBookOrderComputed extends BestOrderItemDto {
  amountSum?: number;
  cumulativePrice?: number;
  wholePartDiffers?: boolean;
}

export interface OrderBookOrder extends OrderBookOrderComputed {
  type: OrderDirection;
  cumulativePricePercentage: number;
}

export interface OrderBook {
  asks?: BestOrderItemDto[];
  bids?: BestOrderItemDto[];
}

export interface OrderFormValues extends OrderFormDto {
  // If "force" is true, the values in the order form will be updated,
  // even if the user has changed their value or a validation error is displayed
  force?: boolean;
}

export enum ApiStateStatus {
  pending = 'pending',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export interface ApiStateBase {
  error: string | null;
  status: ApiStateStatus;
}

export type ApiStateSingle<T> = ApiStateBase & {
  data: T | null;
};

export type ApiStateCollection<T> = ApiStateBase & {
  data: T[] | null;
};

//region Currency Trade Limits
export type CurrencyTradeLimitsState = ApiStateCollection<CurrencyTradeLimitDto>;

export const initialCurrencyTradeLimitsState: CurrencyTradeLimitsState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region LastTradesForSelectedPairState
export type LastTradesForSelectedPairState = ApiStateCollection<TradeItemDto>;

export const initialLastTradesForSelectedPairState: LastTradesForSelectedPairState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region OpenOrdersForSelectedPairState
export type OpenOrdersForSelectedPairState = ApiStateCollection<OpenOrderDto>;

export const initialOpenOrdersForSelectedPairState: OpenOrdersForSelectedPairState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region OrderBookForSelectedPairState
export type OrderBookForSelectedPairState = ApiStateSingle<OrderBook>;

export const initialOrderBookForSelectedPairState: OrderBookForSelectedPairState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region Trades History
export type TradesHistoryForSelectedPairState = ApiStateCollection<TradeItemDto>;

export const initialTradesHistoryForSelectedPairState: TradesHistoryForSelectedPairState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region Trade Statistics
export type TradesStatisticsState = ApiStateSingle<TradeStatisticsDto>;

export const initialTradesStatisticsState: TradesStatisticsState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region Create order
export type OrderFormValuesState = {
  buy: ApiStateSingle<OrderFormValues>;
  sell: ApiStateSingle<OrderFormValues>;
};

export const initialOrderFormValuesState: OrderFormValuesState = {
  buy: {
    data: null,
    error: null,
    status: ApiStateStatus.pending,
  },
  sell: {
    data: null,
    error: null,
    status: ApiStateStatus.pending,
  },
};
//endregion

//region TradingPlatformState
export interface TradingPlatformState {
  // TODO: Temporary solution. It should be stored in its own store, such as UserInfo
  account: AccountDto | null;
  currencyPairName: CurrencyPairDto['name'] | null;
  currencyTradeLimits: CurrencyTradeLimitsState;
  lastTrades: LastTradesForSelectedPairState;
  openOrders: OpenOrdersForSelectedPairState;
  orderBook: OrderBookForSelectedPairState;
  tradesHistory: TradesHistoryForSelectedPairState;
  tradesStatistics: TradesStatisticsState;
  orderFormValues: OrderFormValuesState;
}

export const initialTradingPlatformState: TradingPlatformState = {
  // TODO: Temporary solution. It should be stored in its own store, such as UserInfo
  account: null,
  currencyPairName: null,
  currencyTradeLimits: initialCurrencyTradeLimitsState,
  lastTrades: initialLastTradesForSelectedPairState,
  openOrders: initialOpenOrdersForSelectedPairState,
  orderBook: initialOrderBookForSelectedPairState,
  tradesHistory: initialTradesHistoryForSelectedPairState,
  tradesStatistics: initialTradesStatisticsState,
  orderFormValues: initialOrderFormValuesState,
};
//endregion
