import { Component, Input, OnDestroy } from '@angular/core';
import { CurrencyDto, CurrencyPairWithStatsDto } from '@app/generated/models';
import { CurrencyPairs } from '@app/shared/store/currency-pairs/currency-pairs-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LanguageService } from '@app/shared/services/language.service';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';
import { SelectableCurrency, SelectedCurrencyService } from '@app/shared/services/selected-currency.service';
import { CurrencyEventType, CurrencyPairStatus } from '@app/shared/enums/currency.enum';
import { getCurrencyEventByType } from '@app/shared/utils/currency.util';
import { CURRENCY_DETAIL_URL } from '@app/shared/const/currencies';

interface CurrencyData {
  name: string;
  code: string;
  price: number;
  priceCurrency: string;
  change: number;
  currency: CurrencyDto;
}

const PREFERRED_PAIRS_ORDER = ['BTC', 'SOL', 'XRP'];

@Component({
  selector: 'app-currencies-table',
  templateUrl: './currencies-table.component.html',
  styleUrls: ['./currencies-table.component.scss'],
})
export class CurrenciesTableComponent implements OnDestroy {
  @Input() isMinimal = false;

  currencyData$: Observable<CurrencyData[]>;
  selectedCurrency$: Observable<SelectableCurrency>;
  displayedColumns = ['icon', 'name', 'currency', 'price', 'change', 'buy'];
  lang = '';

  protected readonly CurrencyEventType = CurrencyEventType;
  protected readonly CurrencyPairStatus = CurrencyPairStatus;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private currencies: Currencies,
    private currencyPairs: CurrencyPairs,
    private selectedCurrency: SelectedCurrencyService,
  ) {
    this.selectedCurrency$ = selectedCurrency.selectedCurrency$;
    this.lang = this.translate.currentLang;
    this.currencyData$ = this.setupCurrencyData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByItemId(index: number, currency: any) {
    return currency ? `row-${currency.code}` : `row-skeleton-${index}`;
  }

  limit(array: any[]): any[] {
    return this.isMinimal ? array.slice(0, 3) : array;
  }

  onChangeCurrency(event: Event): void {
    const nextCurrency = SelectedCurrencyService.toSelectableCurrency((event.target as HTMLSelectElement).value);
    if (nextCurrency) {
      this.selectedCurrency.setSelectedCurrency(nextCurrency);
    }
  }

  isDataEmpty(data: CurrencyData[]): boolean {
    return data.filter(Boolean).length === 0;
  }

  protected readonly getCurrencyEventByType = getCurrencyEventByType;

  private setupCurrencyData(): Observable<CurrencyData[]> {
    return combineLatest([
      this.currencies.currencies$,
      this.currencyPairs.currencyPairsWithStats$,
      this.selectedCurrency$,
      this.languageService.currentLanguage$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([currencies, currencyPairs, currencyChoice]) => ({
        currencies,
        pairs: currencyPairs
          .filter((currencyPair: CurrencyPairWithStatsDto) => currencyPair.secondCurrency === currencyChoice)
          .sort((a, b) => {
            const indexOfA = PREFERRED_PAIRS_ORDER.indexOf(a.firstCurrency);
            const indexOfB = PREFERRED_PAIRS_ORDER.indexOf(b.firstCurrency);
            return (
              (indexOfA === -1 ? PREFERRED_PAIRS_ORDER.length : indexOfA) -
              (indexOfB === -1 ? PREFERRED_PAIRS_ORDER.length : indexOfB)
            );
          }),
        currency: this.translate.instant('currencies.units.' + currencyChoice),
      })),
      map(({ currencies, pairs, currency }) => {
        if (!pairs.length) {
          return this.limit(Array(7).fill(null));
        }

        return this.limit(
          pairs.map((pair: CurrencyPairWithStatsDto) => {
            const currencyCode = pair.firstCurrency;
            const currency = currencies.find((currency) => currency.name === currencyCode);

            return {
              currency,
              name: this.translate.instant('crypto.' + currencyCode),
              url: `https://coinmate.io/cz/${CURRENCY_DETAIL_URL[currencyCode]}/`,
              code: currencyCode,
              price: pair.lastPrice,
              priceCurrency: pair.secondCurrency,
              change: pair.changeIn24Hours,
              status: pair.status,
            };
          }),
        );
      }),
    );
  }
}
