<ng-container *ngIf="currencies$ | async as currencies">
  <ng-container *ngIf="currenciesLimited$ | async as currenciesLimited">
    <div *ngIf="currencies.length > currenciesLimited.length" class="show-more-currencies">
      <app-action-button [label]="buttonLabel" [facet]="'tertiary'" (click)="onClick()"></app-action-button>

      <div class="visible-count">
        {{ 'currencies.visible-count' | translate : { limit: currenciesLimited.length, total: currencies.length } }}
      </div>
    </div>
  </ng-container>
</ng-container>
