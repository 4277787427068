import { NgModule } from '@angular/core';
import { PlainTooltipDirective } from '@app/shared/components/tooltip/plain-tooltip/plain-tooltip.directive';
import { RichTooltipDirective } from '@app/shared/components/tooltip/rich-tootlip/rich-tooltip.directive';
import { RichTooltipHeaderComponent } from '@app/shared/components/tooltip/rich-tootlip/components/rich-tooltip-header/rich-tooltip-header.component';
import { RichTooltipBodyComponent } from '@app/shared/components/tooltip/rich-tootlip/components/rich-tooltip-body/rich-tooltip-body.component';
import { RichTooltipFooterComponent } from '@app/shared/components/tooltip/rich-tootlip/components/rich-tooltip-footer/rich-tooltip-footer.component';

@NgModule({
  declarations: [],
  imports: [
    PlainTooltipDirective,
    RichTooltipDirective,
    RichTooltipHeaderComponent,
    RichTooltipBodyComponent,
    RichTooltipFooterComponent,
  ],
  exports: [
    PlainTooltipDirective,
    RichTooltipDirective,
    RichTooltipHeaderComponent,
    RichTooltipBodyComponent,
    RichTooltipFooterComponent,
  ],
})
export class TooltipModule {}
