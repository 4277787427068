import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { animationFrameScheduler, BehaviorSubject, combineLatest, endWith, interval, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';

const easeOutQuad = (x: number): number => x * (2 - x);

@Directive({
  selector: '[appAnimatedCounter]',
})
export class AnimatedCounterDirective implements OnInit, OnDestroy {
  @Input() startOnView = false;

  @Input()
  set value(count: number) {
    this.count$.next(count);
  }

  @Input()
  set duration(duration: number) {
    this.duration$.next(duration);
  }

  @Input()
  set template(template: string) {
    this.template$.next(template);
  }

  private unsubscribe$ = new Subject<void>();

  private readonly count$ = new BehaviorSubject(0);
  private readonly duration$ = new BehaviorSubject(5000);
  private readonly template$ = new BehaviorSubject<string>('{value}');
  private intersectionObserver?: IntersectionObserver;

  private readonly currentCount$ = combineLatest([this.count$, this.duration$, this.template$]).pipe(
    switchMap(([count, duration, template]) => {
      const startTime = animationFrameScheduler.now();

      return interval(0, animationFrameScheduler).pipe(
        map(() => animationFrameScheduler.now() - startTime),
        map((elapsedTime) => elapsedTime / duration),
        takeWhile((progress) => progress <= 1),
        map(easeOutQuad),
        map((progress) => Math.round(progress * count)),
        endWith(count),
        distinctUntilChanged(),
        map((currentCount) => {
          const style = `
            width: ${count.toString().length * 0.9}ch;
            text-align: right;
            display: inline-block`;
          return template.replace(
            '{value}',
            `<span class="font-mono" style="${style}">${currentCount.toString()}</span>`,
          );
        }),
      );
    }),
  );

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit() {
    if (this.startOnView) {
      this.observeViewport();
    } else {
      this.displayCurrentCount();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.intersectionObserver?.disconnect();
  }

  private displayCurrentCount() {
    this.currentCount$.pipe(takeUntil(this.unsubscribe$)).subscribe((currentCount) => {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', currentCount);
    });
  }

  private observeViewport() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.displayCurrentCount();
            this.intersectionObserver!.unobserve(this.elementRef.nativeElement);
          }
        });
      },
      { threshold: 0.1 },
    );

    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }
}
