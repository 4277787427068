import { Inject, Injectable, NgModule, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { AuthenticationGuard } from '@app/shared/guards/authentication.guard';
import { TranslateService } from '@ngx-translate/core';
import { LayoutsEnum } from '@app/layout/layouts.enum';
import { ReferralGuard } from '@app/shared/guards/referral.guard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ComponentsOverviewComponent } from '@app/shared/components/components-overview/components-overview.component';
import { AppGtmService } from '@app/shared/services/gtm.service';
import { CompareComponent } from '@app/unauthenticated/compare/compare.component';
import { OtcDeskComponent } from '@app/unauthenticated/otc-desk/otc-desk.component';
import { AffiliateProgramComponent } from '@app/unauthenticated/affiliate-program/affiliate-program.component';

// every second route redirects /XYZ to the correct language version /(cs|en)/XYZ
export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@app/unauthenticated/homepage/homepage.module').then((m) => m.HomepageModule),
    pathMatch: 'full',
  },
  {
    path: 'developers',
    loadChildren: () => import('./unauthenticated/developers/developers.module').then((m) => m.DevelopersModule),
  },
  {
    path: 'developers',
    redirectTo: '/developers',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'fees',
    loadChildren: () => import('./unauthenticated/fees/fees.module').then((m) => m.FeesModule),
  },
  {
    path: 'fees',
    redirectTo: '/fees',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'legal',
    loadChildren: () => import('./unauthenticated/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'legal',
    redirectTo: '/legal',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'whistleblowing',
    loadChildren: () =>
      import('./unauthenticated/whistleblowing/whistleblowing.module').then((m) => m.WhistleblowingModule),
  },
  {
    path: 'whistleblowing',
    redirectTo: '/whistleblowing',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'bankid-error',
    loadChildren: () => import('./authentication/bankid-failed/bankid-failed.module').then((m) => m.BankidFailedModule),
  },
  {
    path: 'bankid-error',
    redirectTo: '/bankid-error',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.basic },
  },
  {
    path: 'prices',
    loadChildren: () => import('./unauthenticated/currencies/currencies.module').then((m) => m.CurrenciesModule),
  },
  {
    path: 'prices',
    redirectTo: '/prices',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./authentication/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'reset-password',
    redirectTo: 'reset-password',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.basic },
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'login',
    redirectTo: 'login',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.basic },
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./authentication/sign-up/sign-up.module').then((m) => m.SignUpModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'sign-up',
    redirectTo: 'sign-up',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.basic },
  },
  {
    path: 'sign-up/bankid-success',
    redirectTo: '/sign-up/bankid-success',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.basic },
  },
  {
    path: 'disable-2fa',
    loadChildren: () => import('./authentication/disable-2fa/disable-2fa.module').then((m) => m.DisableTwoFactorModule),
  },
  {
    path: 'disable-2fa',
    redirectTo: '/disable-2fa',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.basic },
  },
  {
    path: 'support',
    loadChildren: () => import('./unauthenticated/contact-form/contact-form.module').then((m) => m.ContactFormModule),
  },
  {
    path: 'support',
    redirectTo: 'support',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'faq',
    loadChildren: () => import('./unauthenticated/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'faq',
    redirectTo: '/faq',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'our-story',
    loadChildren: () => import('./unauthenticated/our-story/our-story.module').then((m) => m.OurStoryModule),
  },
  {
    path: 'our-story',
    redirectTo: 'our-story',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'block-account',
    loadChildren: () =>
      import('./unauthenticated/block-account/block-account.module').then((m) => m.BlockAccountModule),
  },
  {
    path: 'block-account',
    redirectTo: 'block-account',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'close-account',
    loadChildren: () =>
      import('./unauthenticated/close-account/close-account.module').then((m) => m.CloseAccountModule),
  },
  {
    path: 'close-account',
    redirectTo: 'close-account',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unauthenticated/unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
  },
  {
    path: 'unsubscribe',
    redirectTo: 'unsubscribe',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: '404',
    loadChildren: () => import('./unauthenticated/404/404.module').then((m) => m.PageNotFoundModule),
  },
  {
    path: '404',
    redirectTo: '/404',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'error',
    loadChildren: () => import('./unauthenticated/error/error.module').then((m) => m.ErrorPageModule),
  },
  {
    path: 'error',
    redirectTo: '/error',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'affiliate',
    loadChildren: () => import('./authenticated/affiliate/affiliate.module').then((m) => m.AffiliateModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'affiliate',
    redirectTo: '/affiliate',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'customer-support',
    loadChildren: () => import('./authenticated/support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'customer-support',
    redirectTo: '/customer-support',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'referral',
    loadChildren: () => import('./authenticated/referrals/referrals.module').then((m) => m.ReferralsModule),
    canActivate: [ReferralGuard],
  },
  {
    path: 'referral',
    redirectTo: '/referral',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'quick-trade',
    loadChildren: () => import('./authenticated/quick-trade/quick-trade.module').then((m) => m.QuickTradeModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'quick-trade',
    redirectTo: '/quick-trade',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'quick-trade/bank-transfer',
    redirectTo: 'quick-trade/bank-transfer',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'quick-trade/checkout',
    redirectTo: 'quick-trade/checkout',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'recurring-buy',
    loadChildren: () => import('./authenticated/recurring-buy/recurring-buy.module').then((m) => m.RecurringBuyModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'recurring-buy',
    redirectTo: '/recurring-buy',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'verification',
    loadChildren: () => import('./authenticated/verification/verification.module').then((m) => m.VerificationModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'verification',
    redirectTo: '/verification',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'welcome',
    loadChildren: () => import('./authenticated/verification/welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'welcome',
    redirectTo: '/welcome',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'open-orders',
    loadChildren: () =>
      import('./authenticated/history/open-orders/open-orders.module').then((m) => m.OpenOrdersModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'open-orders',
    redirectTo: '/open-orders',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'order-history',
    loadChildren: () =>
      import('./authenticated/history/order-history/order-history.module').then((m) => m.OrderHistoryModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'order-history',
    redirectTo: '/order-history',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'transaction-history',
    loadChildren: () =>
      import('./authenticated/history/transaction-history/transaction-history.module').then(
        (m) => m.TransactionHistoryModule,
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'transaction-history',
    redirectTo: '/transaction-history',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'components-overview',
    component: ComponentsOverviewComponent,
  },
  {
    path: 'components-overview',
    redirectTo: '/components-overview',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'price-alerts',
    loadChildren: () => import('./authenticated/price-alerts/price-alerts.module').then((m) => m.PriceAlertsModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'price-alerts',
    redirectTo: '/price-alerts',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'account-api',
    loadChildren: () => import('./authenticated/api/api.module').then((m) => m.ApiModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'account-api',
    redirectTo: '/account-api',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'account-log',
    loadChildren: () => import('@app/authenticated/log/account-log/account-log.module').then((m) => m.AccountLogModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'account-log',
    redirectTo: '/account-log',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'user-log',
    loadChildren: () => import('@app/authenticated/log/user-log/user-log.module').then((m) => m.UserLogModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'user-log',
    redirectTo: '/user-log',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'transfers',
    loadChildren: () =>
      import('./authenticated/deposits-withdrawals/currencies/currencies.module').then((m) => m.CurrenciesModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'transfers',
    redirectTo: '/transfers',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'deposit/fiat/:fiat',
    loadChildren: () =>
      import('./authenticated/deposits-withdrawals/deposit/fiat/deposit-fiat.module').then((m) => m.DepositFiatModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'deposit/fiat/:fiat',
    redirectTo: '/deposit/fiat/:fiat',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'deposit/crypto/:crypto',
    loadChildren: () =>
      import('./authenticated/deposits-withdrawals/deposit/crypto/deposit-crypto.module').then(
        (m) => m.DepositCryptoModule,
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'deposit/crypto/:crypto',
    redirectTo: '/deposit/crypto/:crypto',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'withdraw/fiat/:fiat',
    loadChildren: () =>
      import('./authenticated/deposits-withdrawals/withdraw/fiat/withdraw-fiat.module').then(
        (m) => m.WithdrawFiatModule,
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'withdraw/fiat/:fiat',
    redirectTo: '/withdraw/fiat/:fiat',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'withdraw/crypto/:crypto',
    loadChildren: () =>
      import('./authenticated/deposits-withdrawals/withdraw/crypto/withdraw-crypto.module').then(
        (m) => m.WithdrawCryptoModule,
      ),
    canActivate: [AuthenticationGuard],
    data: { layout: LayoutsEnum.secured },
  },
  {
    path: 'withdraw/crypto/:crypto',
    redirectTo: '/withdraw/crypto/:crypto',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'deposit/fiat',
    loadChildren: () =>
      import('./authenticated/deposits-withdrawals/deposit/fiat/deposit-fiat.module').then((m) => m.DepositFiatModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'deposit/fiat',
    redirectTo: '/deposit/fiat',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'account-profile',
    loadChildren: () => import('./authenticated/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'account-profile',
    redirectTo: '/account-profile',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'security',
    loadChildren: () => import('./authenticated/security/security.module').then((m) => m.SecurityModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'security',
    redirectTo: '/security',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'trade',
    loadChildren: () =>
      import('./authenticated/trading-platform/trading-platform.module').then((m) => m.TradingPlatformModule),
  },
  {
    path: 'trade',
    redirectTo: '/trade',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.tradingPlatform },
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./authenticated/portfolio/portfolio.module').then((m) => m.PortfolioModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'portfolio',
    redirectTo: '/portfolio',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'portfolio/:account',
    redirectTo: '/portfolio/:account',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.secured },
  },
  {
    path: 'compare',
    component: CompareComponent,
    title: 'meta-tags.compare.title',
  },
  {
    path: 'compare',
    redirectTo: '/compare',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'affiliate-program',
    component: AffiliateProgramComponent,
    title: 'meta-tags.affiliate-program.title',
  },
  {
    path: 'affiliate-program',
    redirectTo: '/affiliate-program',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: 'otc-desk',
    component: OtcDeskComponent,
    title: 'meta-tags.otc-desk.title',
  },
  {
    path: 'otc-desk',
    redirectTo: '/otc-desk',
    data: { skipRouteLocalization: { localizeRedirectTo: true }, layout: LayoutsEnum.default },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@Injectable({ providedIn: 'root' })
export class AppTitleStrategy extends TitleStrategy implements OnDestroy {
  private defaultTitleKey = 'meta-tags.homepage.meta-title' as const;
  private unsubscribe$ = new Subject<void>();
  private renderer: Renderer2;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private gtmService: AppGtmService,
    private translate: TranslateService,
    @Inject(RendererFactory2) private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const titleKey = this.buildTitle(routerState);
    const titleSuffixKey = 'meta-tags.title-suffix';
    if (!titleKey) {
      this.translate
        .get([this.defaultTitleKey, titleSuffixKey])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((translations) => {
          this.titleService.setTitle(translations[this.defaultTitleKey] + translations[titleSuffixKey]);
        });
      return;
    }
    const metaTagsPrefix = titleKey.endsWith('.title') ? titleKey.slice(0, -'title'.length) : titleKey + '.';
    const metaTitleKey = metaTagsPrefix + 'meta-title';
    const metaDescriptionKey = metaTagsPrefix + 'meta-description';
    this.translate
      .get([titleKey, titleSuffixKey, metaTitleKey, metaDescriptionKey])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((translations) => {
        const title = translations[titleKey] + translations[titleSuffixKey];
        this.titleService.setTitle(title);
        // If meta title and description is not present, treat the page as not indexed.
        const isIndexed =
          translations[metaTitleKey] !== metaTitleKey && translations[metaDescriptionKey] !== metaDescriptionKey;
        this.removeMetaTags();
        if (isIndexed) {
          const metaTitle = translations[metaTitleKey] + translations[titleSuffixKey];
          const metaDescription = translations[metaDescriptionKey];
          const fullUrl = this.document.location.href;
          this.addMetaTitleAndDescription(metaTitle, metaDescription);
          this.addOpenGraphAndTwitterCards(metaTitle, metaDescription, fullUrl);
          this.addHrefLangTags(fullUrl);
          this.updateCanonicalTag();
        } else {
          this.addRobotPreventionTags();
        }
        const gtmTag = {
          event: 'ga_pageview',
          path: routerState.url,
          title,
        };
        this.gtmService.pushTag(gtmTag).then(() => {
          // TODO DTP-5035
        });
      });
  }

  private removeMetaTags() {
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('name="title"');
    this.metaService.removeTag('name="robots"');
    this.removeOpenGraphAndTwitterCards();
    this.removeHrefLangTags();
  }

  private addMetaTitleAndDescription(title: string, description: string) {
    this.metaService.updateTag(
      {
        name: 'description',
        content: description,
      },
      "name='description'",
    );
    this.metaService.updateTag(
      {
        name: 'title',
        content: title,
      },
      "name='title'",
    );
  }

  private addOpenGraphAndTwitterCards(title: string, description: string, url: string) {
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://coinmate.io/assets/img/coinmate.jpg',
    });
    this.metaService.updateTag({ property: 'og:image:width', content: '1200' });
    this.metaService.updateTag({ property: 'og:image:height', content: '630' });
    this.metaService.updateTag({ property: 'og:url', content: url });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });

    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    this.metaService.updateTag({
      name: 'twitter:image',
      content: 'https://coinmate.io/assets/img/coinmate.jpg',
    });
    this.metaService.updateTag({ name: 'twitter:image:width', content: '1200' });
    this.metaService.updateTag({ name: 'twitter:image:height', content: '630' });
    this.metaService.updateTag({ name: 'twitter:creator', content: '@CoinmateBT' });
    this.metaService.updateTag({ name: 'twitter:site', content: '@CoinmateBT' });
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  }

  private addHrefLangTag(lang: string, url: string) {
    const head = this.document.head;
    const linkElement = this.renderer.createElement('link');

    this.renderer.setAttribute(linkElement, 'rel', 'alternate');
    this.renderer.setAttribute(linkElement, 'hreflang', lang);
    this.renderer.setAttribute(linkElement, 'href', url);

    this.renderer.appendChild(head, linkElement);
  }

  private addHrefLangTags(fullUrl: string) {
    const langRegex = /\/(?<lang>cs|en)(\/|$)/;
    const match = fullUrl.match(langRegex);

    if (match && match.groups) {
      const { lang } = match.groups;
      const csUrl = fullUrl.replace(lang, 'cs');
      const enUrl = fullUrl.replace(lang, 'en');
      const defaultUrl = enUrl;

      this.addHrefLangTag('cs', csUrl);
      this.addHrefLangTag('en', enUrl);
      this.addHrefLangTag('x-default', defaultUrl);
    }
  }

  private updateCanonicalTag() {
    const location = this.document.location;
    let canonicalLink = this.document.querySelector('link[rel="canonical"]');
    if (!location.pathname.match('/login')) {
      canonicalLink?.remove();
      return;
    }
    const url = `${location.origin}${location.pathname}`;
    const head = this.document.head;

    if (!canonicalLink) {
      canonicalLink = this.renderer.createElement('link');
      this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
      this.renderer.appendChild(head, canonicalLink);
    }

    this.renderer.setAttribute(canonicalLink, 'href', url);
  }

  private removeOpenGraphAndTwitterCards() {
    const ogTags = ['og:title', 'og:description', 'og:image', 'og:image:width', 'og:image:height', 'og:url', 'og:type'];
    ogTags.forEach((tag) => {
      this.metaService.removeTag(`property='${tag}'`);
    });

    const twitterTags = [
      'twitter:title',
      'twitter:description',
      'twitter:image',
      'twitter:image:width',
      'twitter:image:height',
      'twitter:creator',
      'twitter:site',
      'twitter:card',
    ];
    twitterTags.forEach((tag) => {
      this.metaService.removeTag(`name='${tag}'`);
    });
  }

  private removeHrefLangTags() {
    const head = this.document.head;
    const linkElements = head.querySelectorAll('link[rel="alternate"][hreflang]');

    linkElements.forEach((linkElement) => {
      this.renderer.removeChild(head, linkElement);
    });
  }

  private addRobotPreventionTags() {
    this.metaService.updateTag(
      {
        name: 'robots',
        content: 'noindex, nofollow', // TODO: or both noindex, nofollow?
      },
      "name='robots",
    );
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: AppTitleStrategy,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
