<section *ngIf="showContent">
  <div class="container mb-12">
    <h1>Components Overview</h1>

    <h2>footer.component</h2>
    <h3>app-footer</h3>
    <app-footer></app-footer>
    <hr />

    <h2>input.component</h2>
    <h3>app-input</h3>
    <div class="flex flex-col gap-y-6">
      <div class="flex flex-col gap-y-4">
        <app-input type="text" [placeholder]="'email'" [disabled]="false"></app-input>
        <app-input
          type="text"
          [placeholder]="'email'"
          [disabled]="false"
          errorMessage="Error"
          [isValid]="false"
        ></app-input>
      </div>
      <div class="flex flex-col gap-y-4">
        <app-input type="text" [placeholder]="'email'">
          <svg-icon src="/assets/icons/16/checkmark-circle.svg" app-input-addons-left />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-right-inside />
          <svg-icon src="/assets/icons/16/warning.svg" app-input-addons-right />
        </app-input>
        <app-input type="text" [placeholder]="'email'">
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside />
        </app-input>
        <app-input type="text" [placeholder]="'email'">
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-right-inside />
        </app-input>
      </div>
      <div class="flex flex-col gap-y-4">
        <code>[size]="'small'"</code>
        <app-input type="text" [placeholder]="'email'" [size]="'small'">
          <svg-icon src="/assets/icons/16/checkmark-circle.svg" app-input-addons-left />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-right-inside />
          <svg-icon src="/assets/icons/16/warning.svg" app-input-addons-right />
        </app-input>
      </div>
      <div class="flex flex-col gap-y-4">
        <code>[hasError]="true"</code>
        <app-input type="text" [placeholder]="'email'" [hasError]="true">
          <svg-icon src="/assets/icons/16/checkmark-circle.svg" app-input-addons-left />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-right-inside />
          <svg-icon src="/assets/icons/16/warning.svg" app-input-addons-right />
        </app-input>
      </div>
      <div class="flex flex-col gap-y-4">
        <code>[hasSuccess]="true"</code>
        <app-input type="text" [placeholder]="'email'" [hasSuccess]="true">
          <svg-icon src="/assets/icons/16/checkmark-circle.svg" app-input-addons-left />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside />
          <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-right-inside />
          <svg-icon src="/assets/icons/16/warning.svg" app-input-addons-right />
        </app-input>
      </div>
    </div>
    <hr />

    <h2>paragraph.component</h2>
    <h3>app-paragraph</h3>
    <app-paragraph [paragraph]="apiForBanksP"></app-paragraph>
    <hr />

    <h2>paragraph-with-img.component</h2>
    <h3>app-paragraph-with-img</h3>
    <app-paragraph-with-img
      [imgSrc]="'/assets/img/articles/api1.png'"
      [nicerHeader]="'API Integration'"
      [paragraph]="apiIntegrationP"
    >
    </app-paragraph-with-img>
    <hr />

    <h2>multi-choice-button-bar.component</h2>
    <h3>app-multi-choice-button-bar</h3>
    <app-multi-choice-button-bar [options]="filterOptions" [description]="'currency-pair-filter' | translate">
    </app-multi-choice-button-bar>
    <hr />

    <h2>language-switch.component</h2>
    <h3>app-language-switch</h3>
    <app-language-switch [type]="languageSwitchTypeEnum.menu"></app-language-switch>
    <br />
    <app-language-switch [type]="languageSwitchTypeEnum.footer"></app-language-switch>
    <hr />

    <h2>h.component</h2>
    <h3>app-h</h3>
    <app-h type="h1" [subtitle]="'title h1'"></app-h>
    <app-h type="h2" [subtitle]="'title h2'"></app-h>
    <app-h type="h3" [subtitle]="'title h3'"></app-h>
    <app-h type="h4" [subtitle]="'title h4'"></app-h>
    <hr />

    <h2>logged-button.component</h2>
    <h3>app-logged-button</h3>
    <br />
    <app-logged-button
      appearance="cm-currency-buy"
      text="currencies.table.purchase"
      addressHref="/quick-trade"
      link="/sign-up"
    >
      <img src="/assets/img/arrow-right-blue.svg" alt="{{ 'currencies.table.purchase' | translate }}" />
    </app-logged-button>
    <br />
    <app-logged-button
      appearance="cm-button cm-button-primary w-full md:!w-fit mt-[20px] cursor-pointer"
      link="/sign-up"
      addressHref="/quick-trade"
      [text]="'Button text'"
    ></app-logged-button>
    <hr />

    <h2>search.component</h2>
    <h3>app-search</h3>
    <app-search [searchPlaceholder]="'quick-trade.main.search' | translate"></app-search>
    <hr />

    <h2>link-pair.component</h2>
    <h3>app-link-pair</h3>
    <app-logged-link-pair
      appearance="no-label"
      routerLink="/support"
      href="/customer-support"
      addressLabel="{{ 'bankid-error.support' | translate }}"
      addressIcon="/assets/img/arrow-right-blue.svg"
    ></app-logged-link-pair>
    <hr />

    <h2>currency-pair.component</h2>
    <h3>app-currency-pair</h3>
    <app-currency-pair type="remove" [currencyPair]="mockCurrencyPair" [isButtonDisplayed]="true"></app-currency-pair>
    <hr />

    <h2>currency-icon.component</h2>
    <h3>app-currency-icon</h3>
    <table class="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
      <div *ngFor="let currency of currencies$ | async" class="flex gap-x-2 items-center">
        <app-currency-icon [currencyName]="currency.name" />
        <span>{{ currency.name }}</span>
      </div>
    </table>
    <hr />

    <h2 id="app-currency-event-countdown">currency-event-countdown.component</h2>
    <h3>app-currency-event-countdown</h3>
    <div>
      <app-currency-event-countdown
        [date]="countdownDate"
        [currencyName]="'USDC'"
        [title]="'BTC/USDC'"
        [description]="'Obchodování spouštíme za'"
      >
        Lorem ipsum dolor sit amet
      </app-currency-event-countdown>
    </div>
    <hr />

    <h2>double-section-page-template.component</h2>
    <h3>app-double-section-page-template</h3>
    <!--<app-or></app-or>-->
    <div class="flex flex-col gap-6">
      <div>
        With middle page divider
        <div class="border border-grey-300">
          <app-double-section-page-template [showMiddlePageDivider]="true">
            <ng-container intro-content>
              <h2 [type]="'h1'" double-section-page-template-title>{{ 'login.welcome-back' | translate }}</h2>
              <h3 [type]="'h3'" double-section-page-template-title>{{ 'login.happy-to' | translate }}</h3>
            </ng-container>
            <ng-container payload-content>
              <form>
                <fieldset>
                  <p class="cm-paragraph cm-form-paragraph">
                    <app-input type="text" [label]="'Email'" [placeholder]="'Email'"></app-input>
                  </p>
                  <p class="cm-paragraph cm-form-paragraph">
                    <app-input type="text" [label]="'Heslo'" [placeholder]="'Heslo'"></app-input>
                  </p>
                </fieldset>
              </form>
              <p>{{ loremIpsumText }}</p>
            </ng-container>
          </app-double-section-page-template>
        </div>
      </div>

      <div>
        Narrower
        <div class="border border-grey-300">
          <app-double-section-page-template [narrower]="true" [contentAlignment]="'center'">
            <ng-container intro-content>
              <h2 double-section-page-template-title>
                {{ typographyTextDemo }}<span>Lorem ipsum dolor sit amet</span>
              </h2>
            </ng-container>
            <ng-container payload-content>
              <img src="/assets/img/shapes/shape-email.svg" alt="email" double-section-page-template-icon />
              <p class="display-h3 mb-6">{{ typographyTextDemo }}</p>
              <p>{{ loremIpsumText }}</p>
              <p>{{ loremIpsumText }}</p>
              <p>{{ loremIpsumText }}</p>
            </ng-container>
          </app-double-section-page-template>
        </div>
      </div>
    </div>
    <hr />

    <h2>paragraph-box.component</h2>
    <h3>app-text</h3>
    <app-text class="app-text" [data]="'text'"></app-text>
    <app-text class="app-text" [data]="'text large'" size="large"></app-text>
    <hr />

    <h2>colored-value-text.component</h2>
    <h3>app-colored-currency-value-text</h3>
    <div class="flex gap-6 mb-12">
      <div>
        Original value
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appColoredCurrencyValueNegative }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appColoredCurrencyValueNearlyZero }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appColoredCurrencyValuePositive }}</div>
      </div>
      <div>
        Currency: unknown
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNegative"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNearlyZero"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValuePositive"
        ></app-colored-currency-value-text>
      </div>
      <div>
        Currency: CZK
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNegative"
          [currency]="'CZK'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNearlyZero"
          [currency]="'CZK'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValuePositive"
          [currency]="'CZK'"
        ></app-colored-currency-value-text>
      </div>
      <div>
        Currency: BTC
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNegative"
          [currency]="'BTC'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNearlyZero"
          [currency]="'BTC'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValuePositive"
          [currency]="'BTC'"
        ></app-colored-currency-value-text>
      </div>
    </div>

    <h3>app-colored-percent-change-text</h3>
    <div class="flex gap-6">
      <div>
        Original value
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appPercentChangeValueNegative }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appPercentChangeValueNearlyZero }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appPercentChangeValuePositive }}</div>
      </div>
      <div>
        Decimals places: 2 <i>(default)</i>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
        ></app-colored-percent-change-text>
      </div>
      <div>
        Decimal places: 3
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
          [decimalPlaces]="3"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
          [decimalPlaces]="3"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
          [decimalPlaces]="3"
        ></app-colored-percent-change-text>
      </div>
      <div>
        Decimal places: 1
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
          [decimalPlaces]="1"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
          [decimalPlaces]="1"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
          [decimalPlaces]="1"
        ></app-colored-percent-change-text>
      </div>
      <div>
        Decimal places: 0
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
          [decimalPlaces]="0"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
          [decimalPlaces]="0"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
          [decimalPlaces]="0"
        ></app-colored-percent-change-text>
      </div>
    </div>
    <hr />

    <h2>phone-number-input.component</h2>
    <h3>app-phone-number-input</h3>
    <app-phone-number-input></app-phone-number-input>
    <hr />

    <h2>benefit.component</h2>
    <h3>app-benefit</h3>
    <app-benefit
      heading="{{ 'quick-trade.benefits.trusted.heading' }}"
      text="{{ 'quick-trade.benefits.trusted.description' }}"
      icon="/assets/icons/icon-cz-flag.svg"
    ></app-benefit>
    <hr />

    <h2 id="app-balances-table">balances-table.component</h2>
    <h3>app-balances-table</h3>
    <div class="flex flex-col gap-6">
      <div>
        <strong>Deposits & Withdrawals</strong>
        <app-balances-table
          [data$]="balancesTableData$"
          [loading]="(balancesTableStatus$ | async) === 'loading'"
          (addFavorite)="onAddFavorite($event)"
          (removeFavorite)="onRemoveFavorite($event)"
        >
          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellBalance>
            <p>
              <strong>{{ currency.available | appCurrencyFloor : currency.name | async }} {{ currency.name }}</strong>
              <span *ngIf="currency.virtual"
                >≈
                {{ currency.fiatEquivalents[selectedFiatCurrency] | appCurrencyFloor : selectedFiatCurrency | async }}
                {{ selectedFiatCurrency }}
              </span>
            </p>
          </ng-template>

          <ng-template let-currency #actionButtons>
            <ng-container *ngIf="currency.virtual">
              <app-action-button [facet]="'primary'" [size]="'small'">
                {{ 'balances-table.deposit' | translate }}
              </app-action-button>
              <app-action-button [size]="'small'" [facet]="'tertiary'" [disabled]="currency.available === 0">
                {{ 'balances-table.withdrawal' | translate }}
              </app-action-button>
            </ng-container>
          </ng-template>
        </app-balances-table>
        <button (click)="reloadBalancesTable()">Reload</button>
      </div>
      <div>
        <strong>Portfolio</strong>
        <app-balances-table
          [data$]="balancesTableData$"
          [loading]="(balancesTableStatus$ | async) === 'loading'"
          (addFavorite)="onAddFavorite($event)"
          (removeFavorite)="onRemoveFavorite($event)"
        >
          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellBalance>
            <p>
              <strong>{{ currency.available | appCurrencyFloor : currency.name | async }} {{ currency.name }}</strong>
              <span *ngIf="currency.virtual"
                >≈
                {{ currency.fiatEquivalents[selectedFiatCurrency] | appCurrencyFloor : selectedFiatCurrency | async }}
                {{ selectedFiatCurrency }}
              </span>
            </p>
          </ng-template>

          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellPrice>
            <p *ngIf="currency.virtual">
              <strong>
                {{ currency.lastPrices[selectedFiatCurrency] | appCurrencyFloor : selectedFiatCurrency | async }}
                {{ selectedFiatCurrency }}
              </strong>
            </p>
          </ng-template>

          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellChange>
            <p *ngIf="currency.virtual && currency.changeIn24Hours">
              <strong>
                <app-colored-percent-change-text
                  [change]="currency.changeIn24Hours[selectedFiatCurrency]"
                ></app-colored-percent-change-text>
              </strong>
            </p>
          </ng-template>

          <ng-template let-currency #actionButtons>
            <app-action-button [size]="'small'" [facet]="'primary'">
              {{ 'balances-table.buy' | translate }}
            </app-action-button>
            <app-action-button [size]="'small'" [facet]="'tertiary'" [disabled]="currency.available === 0">
              {{ 'balances-table.sell' | translate }}
            </app-action-button>
          </ng-template>

          <ng-template
            let-currency
            let-depositBlocked="depositBlocked"
            let-depositVerified="depositVerified"
            let-withdrawalBlocked="withdrawalBlocked"
            #actionDropdown
          >
            <app-dropdown>
              <button dropdown-trigger>
                <svg-icon [src]="'/assets/icons/icon-dots-vertical.svg'"></svg-icon>
              </button>
              <button *ngIf="currency.virtual" dropdown-item>
                {{ 'balances-table.trade' | translate }}
              </button>
              <button [disabled]="depositBlocked || (!depositVerified && currency.virtual)" dropdown-item>
                {{ 'balances-table.deposit' | translate }}
              </button>
              <button [disabled]="withdrawalBlocked || !depositVerified || currency.available === 0" dropdown-item>
                {{ 'balances-table.withdrawal' | translate }}
              </button>
            </app-dropdown>
          </ng-template>
        </app-balances-table>
        <button (click)="reloadBalancesTable()">Reload</button>
      </div>
    </div>
    <hr />

    <h2>currencies-table.component</h2>
    <h3>app-currencies-table</h3>
    <app-currencies-table [isMinimal]="true"></app-currencies-table>
    <hr />

    <h2>data-table.component</h2>
    <h3>app-data-table</h3>
    <app-data-table
      [loading]="(dataTableStatus$ | async) === 'loading'"
      [rows]="dataTableData$ | async"
      [columnWidths]="dataTableColumnWidths"
      [minRowHeight]="72"
      (scrolledToBottom)="dataTableAddData()"
    >
      <ng-template appDataTableHeader>Number of records: {{ (dataTableData$ | async)?.length }}</ng-template>
      <ng-template appDataTableHeader>Name</ng-template>
      <ng-template appDataTableHeader>Display name</ng-template>
      <ng-template appDataTableHeader>
        <div class="text-right flex-1">Available</div>
      </ng-template>
      <ng-template appDataTableHeader>
        <div class="text-right flex-1">Total balance</div>
      </ng-template>
      <ng-template appDataTableHeader>
        <div class="text-right flex-1">Fiat equivalent</div>
      </ng-template>
      <ng-template appDataTableHeader></ng-template>
      <ng-template appDataTableHeader></ng-template>

      <ng-template appDataTableColumn let-row>{{ row.id }}</ng-template>
      <ng-template appDataTableColumn let-row>{{ row.name }}</ng-template>
      <ng-template appDataTableColumn let-row>{{ row.displayName }}</ng-template>
      <ng-template appDataTableColumn let-row>
        <div class="text-right flex-1">{{ row.available | appCurrencyFloor : row.name | async }} {{ row.name }}</div>
      </ng-template>
      <ng-template appDataTableColumn let-row>
        <div class="text-right flex-1">{{ row.totalBalance | appCurrencyFloor : row.name | async }} {{ row.name }}</div>
      </ng-template>
      <ng-template appDataTableColumn let-row>
        <div class="text-right flex-1">
          {{ row.lastPrices.CZK | appCurrencyFloor : row.name | async }} {{ row.name }}
        </div>
      </ng-template>
      <ng-template appDataTableColumn let-row>
        <div class="text-center flex-1">
          <button
            class="cm-button cm-button-secondary-link cm-button-stretched"
            [appTooltip]="'Detail'"
            (click)="dataTableOpenDetail('Detail', row)"
          >
            Detail
          </button>
        </div>
      </ng-template>
      <ng-template appDataTableColumn [sticky]="true" let-row>
        <div class="text-center flex-1">
          <button
            class="cm-button button cm-button-secondary-link-danger"
            [appTooltip]="'Remove'"
            (click)="dataTableOpenDetail('Remove', row)"
          >
            Remove
          </button>
        </div>
      </ng-template>
    </app-data-table>
    <hr />

    <h2>action-button.component</h2>
    <h3>app-action-button</h3>
    <div class="flex flex-col gap-12">
      <div class="grid lg:grid-cols-2 xl:grid-cols-4 gap-12">
        <div class="flex flex-col gap-y-6">
          <div>size = small</div>
          <app-action-button [size]="'small'">Default button</app-action-button>
          <app-action-button [facet]="'primary'" [size]="'small'">Primary button</app-action-button>
          <app-action-button [facet]="'secondary'" [size]="'small'">Secondary button</app-action-button>
          <app-action-button [facet]="'tertiary'" [size]="'small'">Tertiary button</app-action-button>
          <app-action-button [facet]="'danger'" [size]="'small'">Danger button</app-action-button>
          <app-action-button [facet]="'secondary-danger'" [size]="'small'">Secondary danger button</app-action-button>
          <app-action-button [facet]="'link'" [size]="'small'">Link button</app-action-button>
          <app-action-button [facet]="'secondary-link'" [size]="'small'">Secondary link button</app-action-button>
          <app-action-button [facet]="'secondary-link-danger'" [size]="'small'"
            >Secondary link danger button</app-action-button
          >
          <app-action-button [facet]="'form'" [size]="'small'">Form button</app-action-button>
        </div>
        <div class="flex flex-col gap-y-6">
          <div>size = medium <i>(default)</i></div>
          <app-action-button>Default button</app-action-button>
          <app-action-button [facet]="'primary'">Primary button</app-action-button>
          <app-action-button [facet]="'secondary'">Secondary button</app-action-button>
          <app-action-button [facet]="'tertiary'">Tertiary button</app-action-button>
          <app-action-button [facet]="'danger'">Danger button</app-action-button>
          <app-action-button [facet]="'secondary-danger'">Secondary danger button</app-action-button>
          <app-action-button [facet]="'link'">Link button</app-action-button>
          <app-action-button [facet]="'secondary-link'">Secondary link button</app-action-button>
          <app-action-button [facet]="'secondary-link-danger'">Secondary link danger button</app-action-button>
          <app-action-button [facet]="'form'">Form button</app-action-button>
        </div>
        <div class="flex flex-col gap-y-6">
          <div>disabled = true</div>
          <app-action-button [disabled]="true">Default button</app-action-button>
          <app-action-button [facet]="'primary'" [disabled]="true">Primary button</app-action-button>
          <app-action-button [facet]="'secondary'" [disabled]="true">Secondary button</app-action-button>
          <app-action-button [facet]="'tertiary'" [disabled]="true">Tertiary button</app-action-button>
          <app-action-button [facet]="'danger'" [disabled]="true">Danger button</app-action-button>
          <app-action-button [facet]="'secondary-danger'" [disabled]="true">Secondary danger button</app-action-button>
          <app-action-button [facet]="'link'" [disabled]="true">Link button</app-action-button>
          <app-action-button [facet]="'secondary-link'" [disabled]="true">Secondary link button</app-action-button>
          <app-action-button [facet]="'secondary-link-danger'" [disabled]="true"
            >Secondary link danger button</app-action-button
          >
          <app-action-button [facet]="'form'" [disabled]="true">Form button</app-action-button>
        </div>
        <div class="flex flex-col gap-y-6">
          <div>inactive = true</div>
          <app-action-button [inactive]="true">Default button</app-action-button>
          <app-action-button [facet]="'primary'" [inactive]="true">Primary button</app-action-button>
          <app-action-button [facet]="'secondary'" [inactive]="true">Secondary button</app-action-button>
          <app-action-button [facet]="'tertiary'" [inactive]="true">Tertiary button</app-action-button>
          <app-action-button [facet]="'danger'" [inactive]="true">Danger button</app-action-button>
          <app-action-button [facet]="'secondary-danger'" [inactive]="true">Secondary danger button</app-action-button>
          <app-action-button [facet]="'link'" [inactive]="true">Link button</app-action-button>
          <app-action-button [facet]="'secondary-link'" [inactive]="true">Secondary link button</app-action-button>
          <app-action-button [facet]="'secondary-link-danger'" [inactive]="true"
            >Secondary link danger button</app-action-button
          >
          <app-action-button [facet]="'form'" [inactive]="true">Form button</app-action-button>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 gap-12">
        <div class="flex flex-col gap-y-6">
          <div>waiting = true</div>
          <app-action-button
            type="submit"
            label="shared.common.log-in"
            [size]="'small'"
            [facet]="'primary'"
            [waiting]="true"
          ></app-action-button>
          <div>waiting = true / custom label with progress</div>
          <app-action-button
            type="submit"
            label="shared.common.log-in"
            progressLabel="shared.common.loading"
            [size]="'small'"
            [facet]="'primary'"
            [waiting]="true"
          ></app-action-button>
          <div>With icon</div>
          <app-action-button type="submit" label="shared.common.log-in" [size]="'small'" [facet]="'primary'">
            <svg-icon src="/assets/icons/icon-trezor.svg button-icon-left"></svg-icon>
          </app-action-button>
          <app-action-button type="submit" label="shared.common.log-in" [size]="'small'" [facet]="'primary'">
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-right></svg-icon>
          </app-action-button>
          <app-action-button type="submit" label="shared.common.log-in" [size]="'small'" [facet]="'primary'">
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-left></svg-icon>
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-right></svg-icon>
          </app-action-button>
          <div>isFullWidth = true</div>
          <app-action-button
            type="submit"
            label="shared.common.log-in"
            [size]="'small'"
            [facet]="'primary'"
            [isFullWidth]="true"
          ></app-action-button>
        </div>
        <div class="flex flex-col gap-y-6">
          <div>waiting = true</div>
          <app-action-button
            type="submit"
            label="shared.common.log-in"
            [facet]="'primary'"
            [waiting]="true"
          ></app-action-button>
          <div>waiting = true / custom label with progress</div>
          <app-action-button
            type="submit"
            label="shared.common.log-in"
            progressLabel="shared.common.loading"
            [facet]="'primary'"
            [waiting]="true"
          ></app-action-button>
          <div>With icon</div>
          <app-action-button type="submit" label="shared.common.log-in" [facet]="'primary'">
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-left></svg-icon>
          </app-action-button>
          <app-action-button type="submit" label="shared.common.log-in" [facet]="'primary'">
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-right></svg-icon>
          </app-action-button>
          <app-action-button type="submit" label="shared.common.log-in" [facet]="'primary'">
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-left></svg-icon>
            <svg-icon src="/assets/icons/icon-trezor.svg" button-icon-right></svg-icon>
          </app-action-button>
          <div>isFullWidth = true</div>
          <app-action-button
            type="submit"
            label="shared.common.log-in"
            [facet]="'primary'"
            [isFullWidth]="true"
          ></app-action-button>
        </div>
      </div>
    </div>
    <hr />

    <h2>checkbox.component</h2>
    <h3>app-checkbox</h3>
    <app-checkbox
      id="newsletter"
      formControlName="newsletter"
      label="{{ 'signUp.subscribe' }}"
      (change)="toggleCheckbox($event)"
    ></app-checkbox>
    <hr />

    <h2>qt-currency-input.component</h2>
    <h3>app-qt-currency-input</h3>
    <app-qt-currency-input
      [readOnly]="false"
      [currencyName]="mockFromCurrency.name"
      class="md:mb-8"
      formControlName="from"
    ></app-qt-currency-input>
    <hr />

    <h2>textarea.component</h2>
    <h3>app-textarea</h3>
    <div>
      <app-textarea
        label="Label"
        placeholder="Provide us any other details relating to your trading bots."
        [isValid]="true"
      >
      </app-textarea>
    </div>
    <div>
      <app-textarea
        label="Label"
        placeholder="Provide us any other details relating to your trading bots."
        errorMessage="Error"
        [isValid]="false"
      >
      </app-textarea>
    </div>
    <hr />

    <h2>select.component</h2>
    <h3>app-select</h3>
    <div class="flex flex-col gap-6">
      <div class="flex items-center gap-6">
        <div>
          <div class="mb-4">Default</div>
          <app-select
            formControlName="occupation"
            class="verification-form__input"
            errorMessage="error.required-field"
            [isValid]="true"
            [autofocus]="true"
          >
            <option app-option>
              {{ 'SELF_EMPLOYED' | translate }}
            </option>
          </app-select>
        </div>
        <div>
          <div class="mb-4">waiting = true</div>
          <app-select
            formControlName="occupation"
            class="verification-form__input"
            errorMessage="error.required-field"
            [isValid]="true"
            [autofocus]="true"
            [waiting]="true"
          >
            <option app-option>
              {{ 'SELF_EMPLOYED' | translate }}
            </option>
          </app-select>
        </div>
      </div>
    </div>
    <hr />

    <h2>select.component</h2>
    <h3>app-select-new</h3>
    <app-select-new formControlName="countryCode" id="country" label="{{ 'Country' | translate }}"></app-select-new>
    <hr />

    <h2>step-number.component</h2>
    <h3>app-step-number</h3>
    <div class="flex gap-x-4">
      <div>
        Default
        <app-step-number [value]="1"></app-step-number>
      </div>
      <div>
        Active
        <app-step-number [value]="2" [active]="true"></app-step-number>
      </div>
      <div>
        Active & completed
        <app-step-number [value]="3" [active]="true" [completed]="true"></app-step-number>
      </div>
      <div>
        Completed
        <app-step-number [value]="4" [completed]="true"></app-step-number>
      </div>
      <div>
        Maximum value
        <app-step-number [value]="999"></app-step-number>
      </div>
    </div>
    <hr />

    <h2>textarea-new.component</h2>
    <h3>app-textarea-new</h3>
    <app-textarea-new formControlName="message" label="contact-form.message-label"></app-textarea-new>
    <hr />

    <h2>tooltip.component</h2>
    <h3>app-tooltip[text]</h3>
    <div class="flex flex-col gap-8">
      <h4 class="h4">Plain tooltip</h4>
      <div class="flex gap-3">
        <div>
          <span [appTooltip]="'Static content'">Static content</span>
        </div>
        <div>
          <span [appTooltip]="clock$ | async">Dynamic content</span>
        </div>
        <div>
          <span [appTooltip]="tooltipTemplate">Template content</span>
        </div>
      </div>
      <h5 class="font-bold">Default position: <code>right-bottom</code></h5>
      <div class="flex gap-3">
        <div>
          <span [appTooltip]="'Position left-bottom'" [tooltipPosition]="'leftBottom'">Position left-bottom</span>
        </div>
        <div>
          <span [appTooltip]="'Position left-top'" [tooltipPosition]="'leftTop'">Position left-top</span>
        </div>
        <div>
          <span [appTooltip]="'Position center'" [tooltipPosition]="'center'">Position center</span>
        </div>
        <div>
          <span [appTooltip]="'Position right-bottom'" [tooltipPosition]="'leftBottom'">Position right-bottom</span>
        </div>
        <div>
          <span [appTooltip]="'Position right-top'" [tooltipPosition]="'rightTop'">Position right-top</span>
        </div>
      </div>

      <ng-template #tooltipTemplate>
        Template content: <b>{{ clock$ | async }}</b>
      </ng-template>

      <h4 class="h4">Rich tooltip</h4>
      <div class="flex gap-3">
        <span [appRichTooltip]="richTooltipTemplate">Static content</span>
        <span [appRichTooltip]="richTooltipTemplate" [tooltipContext]="{ hideHeaderClose: true }"
          >Hide header close button</span
        >
        <span [appRichTooltip]="richTooltipTemplate" [tooltipContext]="{ hideFooterClose: true }"
          >Hide footer close button</span
        >
        <span [appRichTooltip]="richTooltipTemplate" [tooltipContext]="{ hideHeaderClose: true, hideFooterClose: true }"
          >Hide all close buttons</span
        >
        <span [appRichTooltip]="richTooltipTemplate" [tooltipContext]="{ hideFooter: true }">Hide footer</span>
      </div>
      <ng-template #richTooltipTemplate let-tooltipContext="tooltipContext">
        <app-rich-tooltip-header [hideClose]="tooltipContext?.hideHeaderClose"
          >Lorem ipsum dolor sit amet, consectetur adipiscing</app-rich-tooltip-header
        >

        <app-rich-tooltip-body>
          <div class="flex flex-col gap-6">
            <p>
              Template content: <b>{{ clock$ | async }}</b>
            </p>

            <a href="#">Link</a>

            <p [innerHTML]="'transfers.list.blockedWithdrawalTextWithoutDate' | translate"></p>

            <p *ngFor="let i of [1, 2, 3, 4, 5]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
          </div>
        </app-rich-tooltip-body>

        <app-rich-tooltip-footer *ngIf="!tooltipContext?.hideFooter" [hideClose]="tooltipContext?.hideFooterClose">
          <app-action-button [facet]="'primary'" [size]="'small'" (action)="tooltipContext?.close()">
            More information
          </app-action-button>
        </app-rich-tooltip-footer>
      </ng-template>

      <h4 class="h4">Tooltip (deprecated)</h4>
      <code>[appearance]="default"</code>
      <div class="flex flex-col gap-8 ml-4">
        <div>
          <code class="mb-6">[usePopover]="true"</code>
          <div class="flex gap-2">
            <div>
              Bottom position <i>(default)</i>:&nbsp;
              <app-tooltip [text]="'Tooltip'">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Top position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [tooltipPosition]="tooltipPositionEnum.top">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Left position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [tooltipPosition]="tooltipPositionEnum.left">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Right position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [tooltipPosition]="tooltipPositionEnum.right">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
          </div>
        </div>
        <div>
          <code class="mb-6">[usePopover]="false"</code>
          <div class="flex gap-2">
            <div>
              Bottom position <i>(default)</i>:&nbsp;
              <app-tooltip [text]="'Tooltip'">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Top position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [tooltipPosition]="tooltipPositionEnum.top">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Left position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [tooltipPosition]="tooltipPositionEnum.left">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Right position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [tooltipPosition]="tooltipPositionEnum.right">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <h2>otp-input.component</h2>
    <h3>app-otp-input</h3>
    <app-otp-input></app-otp-input>
    <hr />

    <h2>copy-to-clipboard.component</h2>
    <h3>app-copy-to-clipboard</h3>
    <app-copy-to-clipboard class="refLinkCopy copy-input" [toCopy]="'referralCode'"></app-copy-to-clipboard>
    <hr />

    <h2>confirmation-dialog.component</h2>
    <h3>app-confirmation-dialog</h3>
    <br />
    <button (click)="toggleConfirmationDialog()">Confirmation - click me!</button>
    <hr />

    <h2 id="app-dropdown">dropdown.component</h2>
    <h3>app-dropdown</h3>
    <div class="flex flex-col gap-6">
      <div class="flex items-center gap-6">
        <div>
          <app-dropdown>
            <button dropdown-trigger [withChevron]="true">Bottom-end position&nbsp;<i>(default)</i></button>
            <button dropdown-item>Item 1</button>
            <button dropdown-item>
              Item 2
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            </button>
            <button dropdown-item>
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
              Item 3
            </button>
            <button dropdown-item [facet]="'danger'">
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 4
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item disabled>
              Item 5
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            </button>
            <button dropdown-item>Lorem ipsum dolor sit amet</button>
          </app-dropdown>
        </div>
        <div>
          <app-dropdown [popoverPosition]="popoverPositionEnum.bottomStart">
            <button dropdown-trigger [withChevron]="true">Bottom-start position</button>
            <button dropdown-item>Item 1</button>
            <button dropdown-item>
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 2
            </button>
            <button dropdown-item>
              Item 3
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item [facet]="'danger'">
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 4
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item disabled>
              Item 5
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            </button>
            <button dropdown-item>Lorem ipsum dolor sit amet</button>
          </app-dropdown>
        </div>
        <div>
          <app-dropdown [popoverPosition]="popoverPositionEnum.topEnd">
            <button dropdown-trigger [withChevron]="true">Top-end position</button>
            <button dropdown-item>Item 1</button>
            <button dropdown-item>
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 2
            </button>
            <button dropdown-item>
              Item 3
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item [facet]="'danger'">
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 4
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item disabled>
              Item 5
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            </button>
            <button dropdown-item>Lorem ipsum dolor sit amet</button>
          </app-dropdown>
        </div>
        <div>
          <app-dropdown [popoverPosition]="popoverPositionEnum.topStart">
            <button dropdown-trigger [withChevron]="true">Top-start position</button>
            <button dropdown-item>Item 1</button>
            <button dropdown-item>
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 2
            </button>
            <button dropdown-item>
              Item 3
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item [facet]="'danger'">
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 4
              <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button dropdown-item disabled>
              Item 5
              <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            </button>
            <button dropdown-item>Lorem ipsum dolor sit amet</button>
          </app-dropdown>
        </div>
      </div>
      <div class="flex items-center gap-6">
        <div>
          <app-dropdown [popoverClass]="'md:min-w-[328px]'" [popoverPosition]="popoverPositionEnum.bottomStart">
            <button dropdown-trigger>Custom content</button>
            <div>
              <p class="text-large mb-6"><strong>Přepínání účtů</strong></p>
              <p class="mb-4">
                V rámci této stránky nelze přepínat mezi účty. Použijte svislou navigaci po levé straně.
              </p>
              <app-action-button [size]="'small'">Rozumím</app-action-button>
            </div>
          </app-dropdown>
        </div>
        <div>
          <app-dropdown [popoverClass]="'md:min-w-[382px]'" [popoverPosition]="popoverPositionEnum.bottomStart">
            <app-action-button
              [facet]="'secondary'"
              [size]="'small'"
              [label]="'Custom trigger'"
              [inactive]="true"
              dropdown-trigger
            ></app-action-button>
            <div>Lorem ipsum dolor sit amet</div>
          </app-dropdown>
        </div>
      </div>
    </div>
    <hr />

    <h2>menu-dropdown.component</h2>
    <h3>app-dropdown-menu</h3>
    <div class="flex flex-col gap-6">
      <div class="flex items-center gap-4">
        <div>
          <code>Button trigger:</code><br />
          <app-menu-dropdown [popoverPosition]="popoverPositionEnum.bottomStart">
            <button [withChevron]="true" menu-dropdown-trigger>Menu dropdown</button>
            <div menu-dropdown-section>
              <button menu-dropdown-item>Item 1</button>
              <button menu-dropdown-item>
                <span menu-dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
                Item 2
              </button>
            </div>
            <div menu-dropdown-section>
              <button menu-dropdown-item>
                Item 3
                <span menu-dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
              </button>
              <button menu-dropdown-item>
                <span menu-dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
                Item 4
                <span menu-dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
              </button>
            </div>
          </app-menu-dropdown>
        </div>
        <div>
          <code>Button trigger inactive:</code><br />
          <app-menu-dropdown [popoverPosition]="popoverPositionEnum.bottomStart">
            <button [withChevron]="true" [inactive]="true" menu-dropdown-trigger>Menu dropdown</button>
            <div>Inactive</div>
          </app-menu-dropdown>
        </div>
      </div>
      <div>
        <code>Menu icon link trigger:</code><br />
        <app-menu-dropdown [popoverPosition]="popoverPositionEnum.bottomStart">
          <app-menu-icon-link [tooltip]="'User account'" menu-dropdown-trigger>
            <svg-icon svgClass="icon" src="/assets/icons/icon-user.svg"></svg-icon>
            <div content>FirstName LastName</div>
          </app-menu-icon-link>
          <div menu-dropdown-section>
            <button menu-dropdown-item>Item 1</button>
            <button menu-dropdown-item>
              <span menu-dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 2
            </button>
          </div>
          <div menu-dropdown-section>
            <button menu-dropdown-item>
              Item 3
              <span menu-dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
            <button menu-dropdown-item>
              <span menu-dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
              Item 4
              <span menu-dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            </button>
          </div>
        </app-menu-dropdown>
      </div>
    </div>
    <hr />

    <h2>message.component</h2>
    <h3>app-message</h3>
    <div class="mb-2">
      <app-message
        type="info"
        [showMessage]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="info"
        [showMessage]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="info"
        [showMessage]="true"
        [showCloseButton]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="info"
        [showMessage]="true"
        [showCloseButton]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="error"
        [showMessage]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="error"
        [showMessage]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="error"
        [showMessage]="true"
        [showCloseButton]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="error"
        [showMessage]="true"
        [showCloseButton]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="success"
        [showMessage]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="success"
        [showMessage]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="success"
        [showMessage]="true"
        [showCloseButton]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="success"
        [showMessage]="true"
        [showCloseButton]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="warning"
        [showMessage]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="warning"
        [showMessage]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="warning"
        [showMessage]="true"
        [showCloseButton]="true"
        [title]="'quick-trade.main.error.no-verified' | translate"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <div class="mb-2">
      <app-message
        type="warning"
        [showMessage]="true"
        [showCloseButton]="true"
        [message]="'quick-trade.main.error.no-verified' | translate"
      ></app-message>
    </div>
    <hr />

    <h2>menu.component</h2>
    <h3>app-menu</h3>
    <app-menu></app-menu>
    <br />
    <app-menu [isBasic]="true"></app-menu>
    <hr />

    <h2 id="app-menu-vertical">menu-vertical.component</h2>
    <h3>app-menu-vertical</h3>
    <div class="flex flex-col md:flex-row gap-6">
      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <a href="#" title="Components Overview button" menu-vertical-item>Anchor default</a>
            <a href="#" [facet]="'secondary'" menu-vertical-item>Anchor secondary</a>
            <a href="#" [facet]="'tertiary'" menu-vertical-item>Anchor tertiary</a>

            <a href="#" title="Components Overview button" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor default
            </a>
            <a href="#" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor secondary
            </a>
            <a href="#" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor tertiary
            </a>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button [facet]="'tertiary'" title="Button tertiary" menu-vertical-item>Button tertiary</button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <button title="Button default" menu-vertical-item>Button default</button>
            <button title="Button secondary" [facet]="'secondary'" menu-vertical-item>Button secondary</button>
            <button title="Button tertiary" [facet]="'tertiary'" menu-vertical-item>Button tertiary</button>

            <button title="Button default" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button default
            </button>
            <button title="Button secondary" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button secondary
            </button>
            <button title="Button tertiary" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button tertiary
            </button>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary" [facet]="'tertiary'" menu-vertical-item>Button tertiary</button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <a href="/components-overview" title="Anchor default active" menu-vertical-item>Anchor default active</a>
            <a href="/components-overview" title="Anchor secondary active" [facet]="'secondary'" menu-vertical-item
              >Anchor secondary active</a
            >
            <a href="/components-overview" title="Anchor tertiary active" [facet]="'tertiary'" menu-vertical-item
              >Anchor tertiary active</a
            >

            <a href="/components-overview" title="Anchor default active" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor default active
            </a>
            <a href="/components-overview" title="Anchor secondary active" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor secondary active
            </a>
            <a href="/components-overview" title="Anchor tertiary active" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor tertiary active
            </a>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary another section" [facet]="'tertiary'" menu-vertical-item>
              Button tertiary another section
            </button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <button title="Button default active" menu-vertical-item>Button default active</button>
            <button title="Button secondary active" [facet]="'secondary'" menu-vertical-item>
              Button secondary active
            </button>
            <button title="Button tertiary active" [facet]="'tertiary'" menu-vertical-item>
              Button tertiary active
            </button>

            <button title="Button default active" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button default active
            </button>
            <button title="Button secondary active" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button secondary active
            </button>
            <button title="Button tertiary active" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button tertiary active
            </button>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary another section" [facet]="'tertiary'" menu-vertical-item>
              Button tertiary another section
            </button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <button title="Button default active" disabled menu-vertical-item>Button default disabled</button>
            <button title="Button secondary disabled" [facet]="'secondary'" disabled menu-vertical-item>
              Button secondary disabled
            </button>
            <button title="Button tertiary disabled" [facet]="'tertiary'" disabled menu-vertical-item>
              Button tertiary disabled
            </button>

            <button title="Button default disabled" disabled menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button default disabled
            </button>
            <button title="Button secondary disabled" [facet]="'secondary'" disabled menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button secondary disabled
            </button>
            <button title="Button tertiary disabled" [facet]="'tertiary'" disabled menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button tertiary disabled
            </button>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary another section" [facet]="'tertiary'" disabled menu-vertical-item>
              Button tertiary another section
            </button>
          </div>
        </app-menu-vertical>
      </div>
    </div>
    <hr />

    <h2>base-modal.component</h2>
    <h3>app-base-modal</h3>
    <div class="flex flex-col items-center gap-6 lg:flex-row">
      <div>
        <div class="mb-4">Fixed height = true <i>(default)</i></div>
        <div class="flex flex-col gap-4 lg:flex-row">
          <button (click)="toggleModal('small')">Open modal: small</button>
          <button (click)="toggleModal('medium')">Open modal: medium</button>
          <button (click)="toggleModal('large')">Open modal: large</button>
          <button (click)="toggleModal('x-large')">Open modal: x-large</button>
        </div>
      </div>
      <div>
        <div class="mb-4">Fixed height = false</div>
        <div class="flex flex-col gap-4 lg:flex-row">
          <button (click)="toggleModal('small', false)">Open modal: small</button>
          <button (click)="toggleModal('medium', false)">Open modal: medium</button>
          <button (click)="toggleModal('large', false)">Open modal: large</button>
          <button (click)="toggleModal('x-large', false)">Open modal: x-large</button>
        </div>
      </div>
    </div>
    <hr />

    <h2>public-with-header.component</h2>
    <h3>app-public-with-header</h3>
    <app-public-with-header
      headerImgMobile="/assets/img/headers/dash.m.png"
      headerImgDesktop="/assets/img/headers/dash.png"
      [headingText]="'pageName'"
      [headingSubText]="'pageHeader'"
    ></app-public-with-header>
    <hr />

    <h2>ToastrService</h2>
    <h3>app-alert-message</h3>
    <br /><br />
    <button (click)="showInfo()">Info Toast</button>
    <br /><br />
    <button (click)="showSuccess()">Success Toast</button>
    <br /><br />
    <button (click)="showWarning()">Warning Toast</button>
    <br /><br />
    <button (click)="showError()">Error Toast</button>
    <hr />

    <h2>switcher.component</h2>
    <h3>app-switcher</h3>
    <div class="flex items-center gap-4">
      <div>
        <div class="mb-4">Label position = before <i>(default)</i></div>
        <app-switcher label="Label" (change)="toggleCheckbox($event)"></app-switcher>
      </div>
      <div>
        <div class="mb-4">Label position = after</div>
        <app-switcher label="Label" labelPosition="after" (change)="toggleCheckbox($event)"></app-switcher>
      </div>
    </div>
    <hr />

    <h2>switch.component</h2>
    <h3>app-switch</h3>
    <div class="flex items-center gap-4">
      <app-switch label="Label" falseLabel="Off" trueLabel="On" (change)="toggleCheckbox($event)"></app-switch>
      <app-switch
        label="Label"
        falseLabel="Konkrétní měna"
        trueLabel="Všechny prostředky"
        (change)="toggleCheckbox($event)"
      ></app-switch>
    </div>
    <hr />

    <h2>loader.component</h2>
    <h3>app-loader</h3>
    <app-loader></app-loader>
    <hr />

    <h2>progress-bar.component</h2>
    <h3>app-progress-bar</h3>
    <div class="flex items-center gap-1">
      <ng-container *ngIf="{ value: progressBarValueGenerator$ | async } as generatedValue">
        <app-progress-bar [value]="generatedValue.value"></app-progress-bar>
        <span>{{ generatedValue.value ?? 0 }}%</span>
      </ng-container>
    </div>
    <hr />

    <h2 id="app-accordion">accordion.component</h2>
    <h3>app-accordion</h3>
    <div class="flex gap-12">
      <div>
        <div class="mb-4">Multi = false <i>(default)</i></div>
        <app-accordion>
          <div [expanded]="true" app-accordion-item>
            <div app-accordion-header>
              {{ accordionHeaderText }}
            </div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
        </app-accordion>
      </div>
      <div>
        <div class="mb-4">Multi = true</div>
        <app-accordion [multi]="true">
          <div [expanded]="true" app-accordion-item>
            <div app-accordion-header>
              {{ accordionHeaderText }}
            </div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
        </app-accordion>
      </div>
    </div>
    <hr />

    <h2>Ribbon</h2>
    <h3>app-ribbon</h3>
    <div class="flex flex-col gap-y-6">
      <div>
        <div class="mb-4">Default</div>
        <div class="flex gap-4">
          <app-ribbon>{{ 'currencies.only-buy-sell' | translate }}</app-ribbon>
          <app-ribbon>
            <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
            {{ 'currencies.only-buy-sell' | translate }}
          </app-ribbon>
          <app-ribbon>
            {{ 'currencies.only-buy-sell' | translate }}
            <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-right></svg-icon>
          </app-ribbon>
          <app-ribbon>
            <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
            {{ 'currencies.only-buy-sell' | translate }}
            <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-right></svg-icon>
          </app-ribbon>
        </div>
      </div>
      <div>
        <div class="mb-4">Button</div>
        <div class="flex gap-4">
          <app-dropdown>
            <button ribbon dropdown-trigger>{{ 'currencies.only-buy-sell' | translate }}</button>
            <div>Dropdpown</div>
          </app-dropdown>
          <app-dropdown>
            <button ribbon dropdown-trigger>
              <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
              {{ 'currencies.only-buy-sell' | translate }}
            </button>
            <div>Dropdpown</div>
          </app-dropdown>
          <app-dropdown>
            <button ribbon dropdown-trigger>
              {{ 'currencies.only-buy-sell' | translate }}
              <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-right></svg-icon>
            </button>
            <div>Dropdpown</div>
          </app-dropdown>
          <app-dropdown>
            <button ribbon dropdown-trigger>
              <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
              {{ 'currencies.only-buy-sell' | translate }}
              <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-right></svg-icon>
            </button>
            <div>Dropdpown</div>
          </app-dropdown>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div class="container">
    <h1>Tailwind variants</h1>

    <h2>Typography</h2>
    <div class="grid grid-cols-2 gap-x-4 mt-8">
      <div class="mb-8">
        <strong>Used for secured pages</strong>
      </div>
      <div class="mb-8">
        <strong>Used for public pages</strong>
      </div>

      <div>
        <h3>.h1</h3>
        <div class="h1">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h1</h3>
        <div class="display-h1">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.h2</h3>
        <div class="h2">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h2</h3>
        <div class="display-h2">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.h3</h3>
        <div class="h3">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h3</h3>
        <div class="display-h3">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.h4</h3>
        <div class="h4">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h4</h3>
        <div class="display-h4">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div class="col-start-2">
        <h3>.display-paragraph-blog</h3>
        <p class="display-paragraph-blog mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph-blog font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.paragraph-large</h3>
        <p class="paragraph-large mb-2">{{ typographyTextDemo }}</p>
        <p class="paragraph-large font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <div>
        <h3>.display-paragraph-large</h3>
        <p class="display-paragraph-large mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph-large font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.paragraph</h3>
        <p class="paragraph mb-2">{{ typographyTextDemo }}</p>
        <p class="paragraph font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <div>
        <h3>.display-paragraph</h3>
        <p class="display-paragraph mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.paragraph-small</h3>
        <p class="paragraph-small mb-2">{{ typographyTextDemo }}</p>
        <p class="paragraph-small font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <div>
        <h3>.display-paragraph-small</h3>
        <p class="display-paragraph-small mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph-small font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div class="col-start-2">
        <h3>.display-caption</h3>
        <p class="display-caption mb-2">{{ typographyTextDemo }}</p>
        <p class="display-caption font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div class="col-start-2">
        <h3>.display-caption-small</h3>
        <p class="display-caption-small mb-2">{{ typographyTextDemo }}</p>
        <p class="display-caption-small font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />
    </div>

    <h2 id="buttons">Buttons</h2>
    <h3>.button</h3>
    <div class="grid grid-cols-2 gap-12">
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button">Default button</button>
        <button class="cm-button cm-button-primary">Primary button</button>
        <button class="cm-button cm-button-secondary">Secondary button</button>
        <button class="cm-button cm-button-tertiary">Tertiary button</button>
        <button class="cm-button cm-button-danger">Danger button</button>
        <button class="cm-button cm-button-secondary-danger">Secondary danger button</button>
        <button class="cm-button cm-button-link">Link button</button>
        <button class="cm-button cm-button-form">Form button</button>
      </div>
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-secondary-link">Secondary link button</button>
        <button class="cm-button cm-button-secondary-link-danger">Secondary link danger button</button>
      </div>
    </div>
    <hr />
    <h3>.button-small</h3>
    <div class="grid grid-cols-2 gap-12">
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-small">Default button</button>
        <button class="cm-button cm-button-small cm-button-primary">Primary button</button>
        <button class="cm-button cm-button-small cm-button-secondary">Secondary button</button>
        <button class="cm-button cm-button-small cm-button-tertiary">Tertiary button</button>
        <button class="cm-button cm-button-small cm-button-danger">Danger button</button>
        <button class="cm-button cm-button-small cm-button-secondary-danger">Secondary danger button</button>
        <button class="cm-button cm-button-small cm-button-link">Link button</button>
        <button class="cm-button cm-button-small cm-button-form">Form button</button>
      </div>
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-small cm-button-secondary-link">Secondary link button</button>
        <button class="cm-button cm-button-small cm-button-secondary-link-danger">Secondary link danger button</button>
      </div>
    </div>
    <hr />
    <h3>.button-large</h3>
    <div class="grid grid-cols-2 gap-12">
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-large">Default button</button>
        <button class="cm-button cm-button-large cm-button-primary">Primary button</button>
        <button class="cm-button cm-button-large cm-button-secondary">Secondary button</button>
        <button class="cm-button cm-button-large cm-button-tertiary">Tertiary button</button>
        <button class="cm-button cm-button-large cm-button-danger">Danger button</button>
        <button class="cm-button cm-button-large cm-button-secondary-danger">Secondary danger button</button>
        <button class="cm-button cm-button-large cm-button-link">Link button</button>
        <button class="cm-button cm-button-large cm-button-form">Form button</button>
      </div>
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-large cm-button-secondary-link">Secondary link button</button>
        <button class="cm-button cm-button-large cm-button-secondary-link-danger">Secondary link danger button</button>
      </div>
    </div>
    <hr />
    <h3>.button-menu</h3>
    <div class="grid grid-cols-2 gap-12">
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-menu">Menu button</button>
      </div>
      <div class="flex flex-col items-center gap-y-6">
        <button class="cm-button cm-button-menu cm-button-active">Menu button active</button>
      </div>
    </div>
    <hr />
  </div>
</section>
