<section class="pb-16">
  <div class="container w-full xl:max-w-screen-xl relative px-[20px]">
    <div class="md:flex">
      <div class="md:w-3/5">
        <h1 class="display-h1 mb-5">
          {{ 'affiliate-program.title' | translate }}
        </h1>
        <p class="text-lg mb-5" [innerHTML]="'affiliate-program.intro' | translate"></p>
        <p class="text-lg mb-5" [innerHTML]="'affiliate-program.intro-2' | translate"></p>
        <a [routerLink]="'/affiliate' | localize" class="cm-button cm-button-primary">
          {{ 'affiliate-program.intro-cta' | translate }}
        </a>
      </div>
      <div
        class="md:w-2/5 bg-[url('/assets/img/subpages/affiliate-program-intro.svg')] bg-center bg-no-repeat bg-contain ml-[40px]"
      ></div>
    </div>
  </div>
</section>

<section
  class="pt-[75px] lg:pt-[100px] text-[20px] mb-[100px] w-full xl:max-w-screen-xl relative px-[20px] container"
  id="start"
>
  <h2 class="display-h2 text-center mb-8">
    {{ 'affiliate-program.how-it-works.headline' | translate }}
  </h2>
  <p class="text-lg text-center mb-16">
    {{ 'affiliate-program.how-it-works.text' | translate }}
  </p>
  <div class="md:flex justify-between gap-6 text-left w-full">
    <div class="mb-3 lg:mb-0 px-[10px] flex-1">
      <div class="start-number">1</div>
      <h3 class="h3 mb-4">
        {{ 'affiliate-program.how-it-works.step-1-title' | translate }}
      </h3>
      <p class="display-paragraph" [innerHTML]="'affiliate-program.how-it-works.step-1-text' | translate"></p>
    </div>
    <div class="mb-3 lg:mb-0 px-[10px] flex-1">
      <div class="start-number">2</div>
      <h3 class="h3 mb-4">
        {{ 'affiliate-program.how-it-works.step-2-title' | translate }}
      </h3>
      <p class="display-paragraph" [innerHTML]="'affiliate-program.how-it-works.step-2-text' | translate"></p>
    </div>
    <div class="mb-3 lg:mb-0 px-[10px] flex-1">
      <div class="start-number">3</div>
      <h3 class="h3 mb-4">
        {{ 'affiliate-program.how-it-works.step-3-title' | translate }}
      </h3>
      <p class="display-paragraph" [innerHTML]="'affiliate-program.how-it-works.step-3-text' | translate"></p>
    </div>
  </div>
</section>

<section class="py-16">
  <div class="container w-full xl:max-w-screen-xl relative px-[20px]">
    <div class="md:flex">
      <div class="md:w-3/5">
        <h1 class="display-h1 mb-5">
          {{ 'affiliate-program.how-much.headline' | translate }}
        </h1>
        <p class="cm-paragraph" [innerHTML]="'affiliate-program.how-much.text' | translate"></p>
      </div>
      <div
        class="md:w-2/5 bg-[url('/assets/img/subpages/affiliate-program-earn.svg')] bg-center bg-no-repeat bg-contain ml-[40px]"
      ></div>
    </div>
  </div>
</section>

<homepage-numbers />

<section class="bg-black py-10 lg:py-20">
  <div class="container text-center">
    <h2 class="display-h2 my-10 text-white">
      {{ 'affiliate-program.generate-income.headline' | translate }}
    </h2>
    <p class="text-white display-paragraph-blog mb-10">
      {{ 'affiliate-program.generate-income.text' | translate }}
    </p>
    <a [routerLink]="'/affiliate' | localize" class="cm-button cm-button-primary mx-auto mt-5">
      {{ 'affiliate-program.generate-income.cta' | translate }}
    </a>
  </div>
</section>

<section class="pt-16">
  <div class="affiliate-program-faqs">
    <h2 class="h1">
      {{ 'affiliate-program.faqs.headline' | translate }}
    </h2>
    <app-accordion [multi]="true">
      <div [expanded]="true" app-accordion-item>
        <div app-accordion-header>
          {{ 'affiliate-program.faqs.question-1' | translate }}
        </div>
        <p>
          {{ 'affiliate-program.faqs.answer-1' | translate }}
        </p>
      </div>
      <div [expanded]="true" app-accordion-item>
        <div app-accordion-header>
          {{ 'affiliate-program.faqs.question-2' | translate }}
        </div>
        <p>
          {{ 'affiliate-program.faqs.answer-2' | translate }}
        </p>
      </div>
      <div [expanded]="true" app-accordion-item>
        <div app-accordion-header>
          {{ 'affiliate-program.faqs.question-3' | translate }}
        </div>
        <p>
          {{ 'affiliate-program.faqs.answer-3' | translate }}
        </p>
      </div>
    </app-accordion>
  </div>
</section>
