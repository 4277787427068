import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { YoutubeVideoModalComponent } from '@app/unauthenticated/homepage/modals/youtube-video/youtube-video-modal.component';

const VIDEO_MODAL_ID = 'videoModal';
const VIDEO_CS = {
  videoYoutubeId: 'i5CLLFVFaDQ',
  thumbnailSrc: '/assets/img/homepage/video-tour-thumbnail-cs.png',
};
const VIDEO_EN = {
  videoYoutubeId: 'skKpaHhVgpQ',
  thumbnailSrc: '/assets/img/homepage/video-tour-thumbnail-en.png',
};

@Component({
  selector: 'homepage-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
})
export class OtherComponent implements OnInit, OnChanges {
  @Input() lang = 'cs';
  @Input() colorScheme = 'light';

  others: {
    routerLink?: string;
    click?: () => void;
    heading: string;
    linkLabel: string;
    text: string;
    image: string;
    alt: string;
  }[] = [];

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.createOthers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorScheme) {
      this.createOthers();
    }
  }

  private getImageName(image: string): string {
    return image + (this.colorScheme === 'dark' ? '-dark' : '') + '.svg';
  }

  private createOthers() {
    this.others = [
      {
        routerLink: '/support',
        heading: 'home-page.other.other-1.heading',
        text: 'home-page.other.other-1.text',
        linkLabel: 'home-page.other.other-1.link-label',
        image: this.getImageName('shape-customer-support'),
        alt: 'customer-support',
      },
      {
        click: this.openVideo.bind(this),
        heading: 'home-page.other.other-2.heading',
        text: 'home-page.other.other-2.text',
        linkLabel: 'home-page.other.other-2.link-label',
        image: this.getImageName('shape-video-guide'),
        alt: 'video-guide',
      },
    ];
  }

  openVideo() {
    const video = this.lang === 'cs' ? VIDEO_CS : VIDEO_EN;

    this.modalService.openDynamicModal(VIDEO_MODAL_ID, YoutubeVideoModalComponent, {
      modalTitle: 'home-page.other.other-2.heading',
      videoYoutubeId: video.videoYoutubeId,
      thumbnailSrc: video.thumbnailSrc,
    });
  }
}
