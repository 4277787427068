import { NgModule } from '@angular/core';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { LineChartComponent } from '@app/shared/components/charts/line-chart/line-chart.component';
import { PieChartComponent } from '@app/shared/components/charts/pie-chart/pie-chart.component';

@NgModule({
  imports: [LineChartComponent, PieChartComponent],
  exports: [LineChartComponent, PieChartComponent],
  providers: [provideCharts(withDefaultRegisterables())],
})
export class ChartModule {}
