<!-- INTRO -->
<section class="pb-[70px]">
  <div class="container w-full xl:max-w-screen-xl mx-auto relative px-[20px]">
    <div class="md:flex">
      <!-- Left content -->
      <div class="md:w-3/5">
        <h1 class="display-h1 mb-5">
          {{ 'otc-desk.title' | translate }}
        </h1>
        <h2 class="display-paragraph-blog mb-5 leading-snug">
          {{ 'otc-desk.intro' | translate }}
        </h2>
        <p class="cm-paragraph">
          {{ 'otc-desk.text-1' | translate }}
        </p>
        <p class="cm-paragraph">
          {{ 'otc-desk.text-2' | translate }}
        </p>
        <!-- CTA button is hidden until calculator is implemented -->
        <!--<a [routerLink]="[]" fragment="start" class="cm-button cm-button-primary mt-8">
            {{ 'otc-desk.intro-cta' | translate }}
          </a>-->
      </div>
      <!-- Right image -->
      <div
        class="md:w-2/5 bg-[url('/assets/img/subpages/otc-intro.svg')] bg-center bg-no-repeat bg-contain ml-[40px]"
      ></div>
    </div>
  </div>
</section>

<!-- BENEFITS -->
<section
  class="bg-cm-blue px-[20px] bg-[url('/assets/img/subpages/otc-bg-blue.png')] bg-center bg-cover pt-[50px] pb-[70px] md:pt-[90px] md:pb-[120px]"
>
  <h2 class="display-h2 my-10 text-center text-white" [innerHTML]="'otc-desk.benefits.headline' | translate"></h2>
  <div class="pb-10 relative" #swiperContainer>
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let b of benefits; let i = index">
        <svg-icon svgClass="mb-2" src="assets/icons/icon-success-checkmark.svg"></svg-icon>
        <h3 class="font-semibold text-xl mb-2">{{ b.title | translate }}</h3>
        <p class="cm-paragraph mb-0">{{ b.text | translate }}</p>
      </div>
    </div>
    <div class="swiper-pagination absolute !bottom-0"></div>
  </div>
</section>

<!-- HOW IT WORKS -->
<section class="max-w-[700px] mx-auto px-[20px] mt-[20px]">
  <h2 class="display-h2 my-14 text-center">
    {{ 'otc-desk.how-it-works.title' | translate }}
  </h2>

  <div class="max-w-xl mx-0 py-6">
    <app-transaction-step
      *ngFor="let s of steps"
      [stepNumber]="s.number"
      [printNumber]="s.number"
      [currentStep$]="of(7)"
      [isLast]="s.number === steps.length"
      [title]="s.title | translate"
      [isCollapsable]="true"
      [isDefaultExpanded]="false"
    >
      <p class="text-lg">
        {{ s.text | translate }}
      </p>
    </app-transaction-step>
  </div>

  <app-message
    [showMessage]="true"
    type="warning"
    [title]="'otc-desk.how-it-works.important-info-box.title' | translate"
    [message]="'otc-desk.how-it-works.important-info-box.text' | translate"
  >
    <p class="mt-4">
      {{ 'otc-desk.how-it-works.important-info-box.text-2' | translate }}
    </p>
  </app-message>
</section>

<!-- START -->
<section
  class="pt-[75px] lg:pt-[100px] text-[20px] mb-[100px] w-full max-w-6xl mx-auto relative px-[20px] container"
  id="start"
>
  <h2 class="display-h2 text-center mb-[60px]">
    {{ 'otc-desk.start.title' | translate }}
  </h2>
  <div class="md:flex justify-between text-center w-full">
    <div class="mb-3 lg:mb-0 px-[10px] flex-1">
      <div class="start-number">1</div>
      <p class="display-paragraph" [innerHTML]="'otc-desk.start.step-1' | translate"></p>
      <a href="/sign-up?clickid=2207001" class="cm-button cm-button-primary w-auto table mx-auto mb-0 mt-5">
        {{ 'otc-desk.start.step-1-cta' | translate }}
      </a>
    </div>
    <div class="mb-3 lg:mb-0 px-[10px] flex-1">
      <div class="start-number">2</div>
      <p class="display-paragraph">
        <span [innerHTML]="'otc-desk.start.step-2' | translate"></span>
        <a href="mailto:otc@coinmate.io" class="font-bold hover:underline"> otc&#64;coinmate.io </a>
      </p>
    </div>
    <div class="mb-3 lg:mb-0 px-[10px] flex-1">
      <div class="start-number">3</div>
      <p class="display-paragraph" [innerHTML]="'otc-desk.start.step-3' | translate"></p>
    </div>
  </div>
</section>

<!-- PRIVATE SERVICES -->
<section
  *ngIf="false"
  class="bg-cm-blue px-[20px] bg-[url('/assets/img/subpages/otc-bg-blue.png')] bg-center bg-cover pt-[50px] pb-[70px] md:pt-[90px] md:pb-[120px]"
>
  <div class="container w-full max-w-6xl mx-auto py-6">
    <h2 class="display-h2 text-white mb-4">
      {{ 'otc-desk.private-services.title' | translate }}
    </h2>
    <p class="display-paragraph-blog text-white">
      {{ 'otc-desk.private-services.text' | translate }}
    </p>
    <a href="#" class="cm-button cm-button-black mt-8">
      {{ 'otc-desk.private-services.cta' | translate }}
    </a>
  </div>
</section>

<!-- Background images (replace the original .otc-bg-left/right elements) -->
<div
  class="absolute w-full h-[600px] bg-contain bg-no-repeat top-[35%] left-0 -z-10 bg-[url('/assets/img/subpages/bg-page-left.svg')]"
></div>
<div
  class="absolute w-full h-[600px] bg-contain bg-no-repeat top-[60%] right-0 -z-10 bg-[url('/assets/img/subpages/bg-page-right.svg')]"
></div>
<div
  class="absolute w-full h-[600px] bg-contain bg-no-repeat top-[70%] left-0 -z-10 bg-[url('/assets/img/subpages/bg-page-left.svg')]"
></div>
