import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-transaction-step',
  templateUrl: './transaction-step.component.html',
  styleUrls: ['./transaction-step.component.css'],
  animations: [
    trigger('simpleFadeIn', [transition(':enter', [style({ opacity: 0 }), animate('1s', style({ opacity: 1 }))])]),
  ],
})
export class TransactionStepComponent implements OnInit {
  @Input() printNumber = 0;
  @Input() stepNumber = 0;
  @Input() currentStep$ = of(0);
  @Input() title = '';
  @Input() help: string | null = '';
  @Input() isLast = false;
  @Input() isCollapsable = false;
  @Input() isDefaultExpanded = false;

  isExpanded = false;

  ngOnInit(): void {
    this.isExpanded = this.isDefaultExpanded;
  }

  toggleExpanded(): void {
    if (this.isCollapsable) {
      this.isExpanded = !this.isExpanded;
    }
  }
}
