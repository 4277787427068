import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectedPairControllerService } from '@app/generated/services/selected-pair-controller.service';
import { CurrencyPairs } from '@app/shared/store/currency-pairs/currency-pairs-facade.service';
import { CurrencyPairWithStatsDto } from '@app/generated/models/currency-pair-with-stats-dto';
import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { Subject, Subscription } from 'rxjs';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { take, takeUntil } from 'rxjs/operators';
import { CurrencyPairSelectorModalComponent } from '@app/shared/components/currency-pair/currency-pair-selector-modal/currency-pair-selector-modal.component';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { CurrencyPairSelectedDto } from '@app/generated/models/currency-pair-selected-dto';

const CURRENCY_SELECTOR_MODAL_ID = 'currencySelector';

@Component({
  selector: 'app-currency-pair-selector',
  templateUrl: './currency-pair-selector.component.html',
  styleUrls: ['./currency-pair-selector.component.scss'],
})
export class CurrencyPairSelectorComponent implements OnInit, OnDestroy {
  @Input() isLoggedIn = false;

  @Output() changePair = new EventEmitter<CurrencyPairSelectedDto>();

  selectedPair?: CurrencyPairSelectedDto;

  private unsubscribe$ = new Subject<void>();
  private eventSubscription = Subscription.EMPTY;

  constructor(
    private userInfo: UserInfo,
    public modalService: ModalService,
    private selectedPairService: SelectedPairControllerService,
    private currencyPairsService: CurrencyPairs,
  ) {}

  //region lifecycle
  ngOnInit(): void {
    // Load initial data
    if (this.isLoggedIn) {
      this.currencyPairsService.loadFavouriteCurrencyPairs();
    }

    this.getAndSetSelectedCurrencyPair();

    this.eventSubscription = this.userInfo.eventEmitter.subscribe(() => {
      this.getAndSetSelectedCurrencyPair();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.eventSubscription.unsubscribe();
  }
  //endregion

  //region inputs
  handlePairChange(pair: CurrencyPairWithStatsDto) {
    this.changeSelectedPair(pair);
  }

  openModal() {
    this.modalService.openDynamicModal(CURRENCY_SELECTOR_MODAL_ID, CurrencyPairSelectorModalComponent, undefined, {
      pairChanged: this.handlePairChange.bind(this),
    });
  }
  //endregion

  //region private
  private getAndSetSelectedCurrencyPair() {
    this.selectedPairService
      .getSelectedCurrencyPairUsingGet()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((selectedPair) => {
        this.selectedPair = selectedPair;
        this.changePair.emit(selectedPair);
      });
  }

  private changeSelectedPair = (currencyPair: CurrencyPairDto): void => {
    this.selectedPairService
      .selectCurrencyPairUsingPut({ body: currencyPair.name })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pair) => {
        this.selectedPair = pair;
        this.changePair.emit(pair);
        this.modalService.closeDynamicModal(CURRENCY_SELECTOR_MODAL_ID);
      });
  };
  //endregion
}
