import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { equals, mergeDeepRight } from 'ramda';
import { TranslatePipe } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { ActiveElement, ChartConfiguration, ChartData, ChartEvent, ChartOptions, ChartType, Plugin } from 'chart.js';
import { v4 as uuidv4 } from 'uuid';

import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import htmlLegendPlugin from '@app/shared/components/charts/plugins/html-legend';
import pieChartAdjustAnglesPlugin from '@app/shared/components/charts/plugins/pie-chart-adjust-angles';

export type PieChartData = ChartData<'pie'>;
export type PieChartOptions = ChartOptions<'pie'>;

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  imports: [CommonModule, BaseChartDirective, TranslatePipe, LoaderComponent],
  templateUrl: './pie-chart.component.html',
})
export class PieChartComponent implements AfterViewInit, OnDestroy {
  @Input() data$: Observable<PieChartData> = of({
    labels: [],
    datasets: [],
  });
  @Input() options$: Observable<ChartConfiguration['options']> = of({});
  @Input() plugins: Plugin[] = [];
  @Input() loading? = false;
  @Input() noDataMessage? = '';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  initialChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [],
  };
  chartOptions: ChartConfiguration['options'] = {};
  chartID = uuidv4();
  chartPlugins = [htmlLegendPlugin, pieChartAdjustAnglesPlugin()];
  chartType: ChartType = 'pie';
  chartHasData = false;

  private unsubscribe$ = new Subject<void>();

  defaultChartOptions = {
    maintainAspectRatio: false,
    cutout: '55%',
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
    spacing: 2,
    offset: 0,
    borderWidth: 0,
    hoverOffset: 20,
    hoverBorderWidth: 0,
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 16,
          font: {
            size: 12,
            weight: 'bold',
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      htmlLegend: {
        containerID: this.chartID,
        orientation: 'vertical',
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      this.handleHover(event, elements);
    },
  } as PieChartOptions;

  ngAfterViewInit() {
    if (this.plugins) {
      this.chartPlugins = [...this.chartPlugins, ...this.plugins];
    }

    this.data$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((a, b) => equals(a, b)),
      )
      .subscribe((updatedChartData) => {
        const updatedChartDatasets = updatedChartData?.datasets || {};
        const updatedChartLabels = updatedChartData?.labels || [];

        this.chartHasData = updatedChartDatasets.some((dataset) => dataset.data.filter(Boolean).length);

        if (!this.chart) {
          return;
        }

        if (this.chart.data) {
          /**
           * Updating data
           *
           * This update ensures smooth animations during data transitions.
           * By updating datasets individually and mutating the original data,
           * the chart can calculate the transition between the current and new data points.
           * Without this approach, the transition would not be smooth.
           *
           * https://www.chartjs.org/docs/latest/developers/updates.html#adding-or-removing-data
           */

          this.chart.data.labels = updatedChartLabels;

          const chartDatasets = this.chart.data.datasets;
          updatedChartDatasets.forEach((updatedChartDataset, index) => {
            if (chartDatasets[index]) {
              chartDatasets[index] = Object.assign(chartDatasets[index], updatedChartDataset);
            } else {
              chartDatasets.push(Object.assign({}, updatedChartDataset));
            }
          });

          if (chartDatasets.length > updatedChartDatasets.length) {
            this.chart.data.datasets = chartDatasets.slice(0, updatedChartDatasets.length);
          }
        }

        if (this.chart.chart?.canvas) {
          this.chart.chart.canvas.style.display = this.chartHasData ? 'block' : 'none';
        }

        this.chart.update();
      });

    this.options$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((a, b) => equals(a, b)),
        map((options) => {
          return mergeDeepRight(this.defaultChartOptions as object, options as object);
        }),
      )
      .subscribe((chartOptions) => {
        this.chartOptions = chartOptions;

        if (this.chart) {
          this.chart.update();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleHover(_event: ChartEvent, activeElements: any[]) {
    const legendContainer = document.getElementById(this.chartID) as HTMLUListElement;
    const labels = legendContainer.querySelectorAll('li');

    labels.forEach((label) => {
      label.classList.remove('bg-cm-blue-50');
      label.classList.remove('dark:bg-cm-blue-800');
    });

    if (activeElements.length) {
      const activeIndex = activeElements[0].index;
      const activeLegendItem = labels[activeIndex];

      if (activeLegendItem) {
        activeLegendItem.classList.add('bg-cm-blue-50');
        activeLegendItem.classList.add('dark:bg-cm-blue-800');
      }
    }
  }
}
