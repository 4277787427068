import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import { Locales, UserInfoProfileService } from '@app/shared/services/user-info-profile.service';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';
import { CurrencyDto } from '@app/generated/models/currency-dto';

@Pipe({
  name: 'appCurrencyFloor',
  standalone: true,
})
export class AppCurrencyFloorPipe implements PipeTransform, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  private currenciesRounding: { [key: string]: number } = {};

  constructor(
    private readonly userInfoProfileService: UserInfoProfileService,
    private readonly currenciesFacade: Currencies,
  ) {
    this.currenciesFacade.currenciesRounding$.pipe(takeUntil(this.unsubscribe$)).subscribe((currenciesRounding) => {
      this.currenciesRounding = currenciesRounding;
    });
  }

  transformNumber(
    value: number,
    numberLocale: string = Locales.cs,
    currencyName: CurrencyDto['name'],
    removeTrailingZeros: boolean = true,
    compact: boolean = false,
    compactThreshold: number = 10000,
  ): string {
    const valueBN = new BigNumber(value);
    const decimalPlaces = this.currenciesRounding[currencyName as keyof typeof this.currenciesRounding] ?? 2;
    const minimumFractionDigits = removeTrailingZeros ? 0 : decimalPlaces;

    if (compact && valueBN.abs().gte(compactThreshold)) {
      const formatter = Intl.NumberFormat(numberLocale, {
        minimumFractionDigits,
        maximumFractionDigits: decimalPlaces,
        notation: 'compact',
      });
      return formatter.format(valueBN.toNumber());
    }

    const digitsInfo = ['.', minimumFractionDigits, '-', decimalPlaces].join('');
    const flooredValueBN = valueBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);

    return formatNumber(flooredValueBN.toNumber(), numberLocale, digitsInfo);
  }

  transform(
    value$: Observable<number> | number,
    currencyName: CurrencyDto['name'],
    removeTrailingZeros: boolean = true,
    compact: boolean = false,
    compactThreshold: number = 10000,
  ): Observable<string> {
    if (typeof value$ === 'number') {
      value$ = of(value$);
    }

    return combineLatest([value$, this.userInfoProfileService.numberLocale$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([value, numberLocale]: [number, string]) => {
        return this.transformNumber(value, numberLocale, currencyName, removeTrailingZeros, compact, compactThreshold);
      }),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
