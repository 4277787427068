import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private tooltipCloseSubject = new Subject<null>();

  tooltipClose$ = this.tooltipCloseSubject.asObservable();

  closeTooltip() {
    this.tooltipCloseSubject.next(null);
  }
}
