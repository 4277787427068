<app-base-modal
  [id]="modalId"
  [title]="'trading-platform.modal.balances.title' | translate"
  [size]="'large'"
  [isBodyZeroSpacing]="true"
>
  <ng-container modal-header>
    <app-search
      [searchPlaceholder]="'trading-platform.modal.balances.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    ></app-search>
  </ng-container>
  <app-base-modal-body>
    <ng-container *ngIf="balancesLimited$ | async as currencies">
      <ng-container *ngIf="currencies.length; else noData">
        <table class="table-auto w-full">
          <thead>
            <tr class="currencies-table-row">
              <ng-container *ngIf="isMobileViewport; else headerDesktop">
                <th class="currencies-table-cell">
                  {{ headers[0] | translate }}
                </th>
                <ng-container *ngIf="headers.slice(1, headers.length) as restHeaders">
                  <th class="currencies-table-cell" [colSpan]="restHeaders.length">
                    <ng-container *ngFor="let header of restHeaders.reverse(); let isLast = last">
                      <span class="inline-flex items-center gap-x-6px">
                        {{ header | translate }}
                      </span>
                      <br *ngIf="!isLast" />
                    </ng-container>
                  </th>
                </ng-container>
              </ng-container>
              <ng-template #headerDesktop>
                <th *ngFor="let header of headers" class="currencies-table-cell">
                  {{ header | translate }}
                </th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let currency of currencies; trackBy: trackByItemId"
              class="currencies-table-row"
              [ngClass]="{
                clickable: modalActions.onCurrencySelect
              }"
              (click)="modalActions.onCurrencySelect && modalActions.onCurrencySelect(currency)"
            >
              <td class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="flex items-center gap-x-8px md:gap-x-20px">
                    <app-currency-icon class="h-8 w-8" [currencyName]="currency.name" [alt]="currency.displayName" />
                    <div class="flex flex-col gap-y-4px">
                      <div class="flex flex-col gap-y-2px">
                        <div class="font-bold">
                          {{ getCurrencyDisplayName(currency.name) }}
                        </div>
                        <div class="text-dimmed text-sm">{{ currency.name }}</div>
                      </div>
                      <div class="hidden md:flex md:flex-wrap md:gap-[8px]">
                        <app-currency-event-ribbon [currency]="currency" />
                        <ng-container
                          *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"
                        ></ng-container>
                        <ng-container
                          *ngTemplateOutlet="tradingSuspension; context: { $implicit: currency }"
                        ></ng-container>
                        <ng-container *ngTemplateOutlet="tokenUpdate; context: { $implicit: currency }"></ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap gap-8px md:hidden">
                    <app-currency-event-ribbon [currency]="currency" />
                    <ng-container *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"></ng-container>
                    <ng-container
                      *ngTemplateOutlet="tradingSuspension; context: { $implicit: currency }"
                    ></ng-container>
                    <ng-container *ngTemplateOutlet="tokenUpdate; context: { $implicit: currency }"></ng-container>
                  </div>
                </div>
              </td>
              <td *ngIf="isMobileViewport" class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="font-bold">
                    {{ currency.available | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
                  </div>
                  <div class="text-dimmed text-sm md:hidden">
                    {{ currency.inOpenOrders | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
                  </div>
                  <div class="text-dimmed text-sm md:hidden">
                    {{ currency.totalBalance | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
                  </div>
                </div>
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell text-sm">
                {{ currency.totalBalance | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell text-sm">
                {{ currency.inOpenOrders | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell font-bold">
                {{ currency.available | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>

    <ng-template #noData>
      <div class="no-data">
        {{ (searchTermSubject.value ? 'currencies.not-found' : 'shared.common.no-data') | translate }}
      </div>
    </ng-template>

    <ng-template #onlyBuySellInfo let-currency>
      <ng-container *ngIf="!currency.depositActive || !currency.withdrawalActive">
        <app-ribbon [appRichTooltip]="blockedDepositWithdrawalTooltipTemplate" [stopEventPropagation]="true">
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.only-buy-sell' | translate }}
        </app-ribbon>

        <ng-template #blockedDepositWithdrawalTooltipTemplate>
          <app-rich-tooltip-header>{{
            'currencies.blocked-deposit-withdrawal.title' | translate
          }}</app-rich-tooltip-header>
          <app-rich-tooltip-body>{{
            'currencies.blocked-deposit-withdrawal.message' | translate
          }}</app-rich-tooltip-body>
          <app-rich-tooltip-footer></app-rich-tooltip-footer>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template #tradingSuspension let-currency>
      <ng-container *ngIf="tradingSuspensionSonic && currency.name === 'S'">
        <app-ribbon [appRichTooltip]="tradingSuspensionTooltipTemplate" [stopEventPropagation]="true">
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.trading-suspension.label' | translate }}
        </app-ribbon>

        <ng-template #tradingSuspensionTooltipTemplate>
          <app-rich-tooltip-header>{{ 'currencies.trading-suspension.title' | translate }}</app-rich-tooltip-header>
          <app-rich-tooltip-body>
            {{ 'currencies.trading-suspension.message' | translate }}
          </app-rich-tooltip-body>
          <app-rich-tooltip-footer></app-rich-tooltip-footer>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template #tokenUpdate let-currency>
      <ng-container *ngIf="currency.name === 'S'">
        <app-ribbon [appRichTooltip]="tokenUpdateTooltipTemplate" [stopEventPropagation]="true">
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.token-update.label' | translate }}
        </app-ribbon>

        <ng-template #tokenUpdateTooltipTemplate>
          <app-rich-tooltip-header>{{ 'currencies.token-update.title' | translate }}</app-rich-tooltip-header>
          <app-rich-tooltip-body>
            <div
              [innerHTML]="
                'currencies.token-update.message'
                  | translate : { token: 'Fantom (FTM)', tokenNew: 'Sonic (S)', url: 'sonic-fantom-kurz' }
              "
            ></div>
          </app-rich-tooltip-body>
          <app-rich-tooltip-footer></app-rich-tooltip-footer>
        </ng-template>
      </ng-container>
    </ng-template>

    <app-currencies-show-more-button
      *ngIf="!(searchTermSubject | async)"
      class="mt-7"
      [currencies$]="balances$"
      [currenciesLimited$]="balancesLimited$"
      (click)="showAllBalances()"
    ></app-currencies-show-more-button>
  </app-base-modal-body>
</app-base-modal>
