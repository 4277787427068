import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'homepage-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit, OnChanges {
  @Input() lang = 'cs';
  @Input() colorScheme = 'light';

  benefits: {
    href?: string;
    routerLink?: string;
    heading: string;
    text: string;
    image: string;
    alt: string;
  }[] = [];

  ngOnInit() {
    this.createBenefits();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang || changes.colorScheme) {
      this.createBenefits();
    }
  }

  private getImageName(image: string): string {
    return image + (this.colorScheme === 'dark' ? '-dark' : '') + '.svg';
  }

  private createBenefits() {
    const benefitsCs = [
      {
        routerLink: '/our-story',
        heading: 'home-page.benefits.benefit-1.heading',
        text: 'home-page.benefits.benefit-1.text',
        image: this.getImageName('shape-cz-flag'),
        alt: 'cz-flag',
      },
      {
        routerLink: '/compare',
        heading: 'home-page.benefits.benefit-2.heading',
        text: 'home-page.benefits.benefit-2.text',
        image: this.getImageName('shape-czk-exchange'),
        alt: 'czk-exchange',
      },
      {
        href: 'https://coinmate.io/cz/srovnani-rychlosti-vkladu/',
        heading: 'home-page.benefits.benefit-3.heading',
        text: 'home-page.benefits.benefit-3.text',
        image: this.getImageName('shape-calendar-check'),
        alt: 'calendar-check',
      },
    ];

    const benefitsEn = [
      {
        routerLink: '/our-story',
        heading: 'home-page.benefits.benefit-1.heading',
        text: 'home-page.benefits.benefit-1.text',
        image: this.getImageName('shape-cz-flag'),
        alt: 'cz-flag',
      },
      {
        routerLink: '/compare',
        heading: 'home-page.benefits.benefit-2.heading',
        text: 'home-page.benefits.benefit-2.text',
        image: this.getImageName('shape-czk-euro-exchange'),
        alt: 'czk-exchange',
      },
      {
        heading: 'home-page.benefits.benefit-3.heading',
        text: 'home-page.benefits.benefit-3.text',
        image: this.getImageName('shape-piggy'),
        alt: 'calendar-check',
      },
    ];

    this.benefits = this.lang === 'cs' ? benefitsCs : benefitsEn;
  }
}
