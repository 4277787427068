import { Chart, Plugin } from 'chart.js';
import { PieChartData } from '@app/shared/components/charts/pie-chart/pie-chart.component';

interface Options {
  minAngle: number;
}

const DEFAULT_OPTIONS = {
  minAngle: 6,
};

/**
 * Adjusts the pie chart angles based on minAngle without modifying the original dataset values,
 * and ensures the total sum of angles equals 360°.
 * @param data number[]
 * @param minAngle number
 */
const calculatePieAngles = (data: number[], minAngle: number): number[] => {
  const fullCircle = 360;

  // Ensure that the data contains only positive values
  if (data.some((value) => value < 0)) {
    return data;
  }

  // Calculate the total sum of all values
  const total = data.reduce((sum, value) => sum + value, 0);

  // If the total is 0, return unchanged data
  if (total === 0) {
    return data;
  }

  // Ensure the minimum angle for each segment
  let angles = data.map((value) => {
    const angle = (value / total) * fullCircle;
    if (angle < minAngle) {
      return minAngle;
    }

    return angle;
  });

  // If there is an excess (angles exceed 360°), adjust other segments
  const totalAdjustedAngles = angles.reduce((sum, angle) => sum + angle, 0);
  const surplus = totalAdjustedAngles - fullCircle;

  if (surplus > 0) {
    // Find segments that can be reduced (angles greater than minAngle)
    const adjustableAngles = angles.filter((angle) => angle > minAngle);
    const adjustableTotal = adjustableAngles.reduce((sum, angle) => sum + angle, 0);

    // Proportionally reduce adjustable segments
    angles = angles.map((angle) => {
      if (angle > minAngle) {
        // Proportional reduction
        return angle - surplus * (angle / adjustableTotal);
      }
      // Leave small segments unchanged
      return angle;
    });
  }

  // Normalize angles so that they sum to exactly 360°
  const totalFinal = angles.reduce((sum, angle) => sum + angle, 0);
  const normalizationFactor = fullCircle / totalFinal;

  // Scale angles to sum to 360°
  return angles.map((angle) => angle * normalizationFactor);
};

/**
 * This plugin ensures that all slices in a Pie chart meet a minimum angle requirement
 * by calling the `adjustAnglesForPieChart` function. The chart's data is temporarily
 * modified during rendering to enforce the minimum angle, but the original data is
 * restored afterward to ensure the legend and tooltips display the unmodified values.
 */
const pieChartAdjustAnglesPlugin = (): Plugin => {
  const storage = new WeakMap();

  return {
    id: 'pieChartAdjustAngles',
    beforeElementsUpdate: (chart: Chart, args: any, options: Options) => {
      if (!chart.data?.datasets) return;

      const datasetsDataOriginal: number[][] = [];
      const chartOptions = { ...DEFAULT_OPTIONS, ...options };
      const chartDatasets = chart.data.datasets as PieChartData['datasets'];

      chartDatasets.forEach((dataset, index) => {
        datasetsDataOriginal[index] = dataset.data;

        dataset = Object.assign(dataset, {
          ...dataset,
          data: calculatePieAngles(dataset.data, chartOptions.minAngle),
        });
      });

      storage.set(chart, {
        datasets: datasetsDataOriginal,
      });
    },
    afterDatasetsUpdate(chart: Chart) {
      if (!chart.data?.datasets) return;

      const chartDataOriginal = storage.get(chart);

      const chartDatasets = chart.data.datasets as PieChartData['datasets'];
      chartDatasets.forEach((dataset, index) => {
        dataset = Object.assign(dataset, {
          ...dataset,
          data: chartDataOriginal.datasets[index],
        });
      });
    },
    afterDestroy(chart: Chart) {
      storage.delete(chart);
    },
  };
};

export default pieChartAdjustAnglesPlugin;
