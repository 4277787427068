import { createSelector, MemoizedSelector } from '@ngrx/store';
import { SharedState } from '../shared.reducer';
import { getSharedState } from '@app-shared/store/shared.selectors';
import BigNumber from 'bignumber.js';
import {
  queryCurrencyPairs,
  queryCurrencyPairsWithStats,
} from '@app/shared/store/currency-pairs/currency-pairs.selectors';
import { CurrencyBalanceWithEquivalentsDto } from '@app/generated/models/currency-balance-with-equivalents-dto';
import { queryCurrencies } from '@app/shared/store/currencies/currencies.selectors';
import { CurrencyDto } from '@app/generated/models/currency-dto';
import { CurrencyPairWithStatsDto } from '@app/generated/models/currency-pair-with-stats-dto';

export interface BalanceWithCurrencyEquivalents extends CurrencyDto {
  tradeActive: boolean;
  totalBalance: number;
  available: number;
  inOpenOrders: number;
  lastPrices: { EUR: number; CZK: number };
  changeIn24Hours: { EUR: number; CZK: number };
  fiatEquivalentsTotal: {
    [key: string]: number;
  };
  fiatEquivalentsAvailable: {
    [key: string]: number;
  };
}

const getCurrencyPair = (
  currencyPairsWithStats: CurrencyPairWithStatsDto[],
  currency: CurrencyDto,
  secondCurrency: string,
) => {
  return currencyPairsWithStats.find(
    (currencyPair) => currencyPair.firstCurrency === currency.name && currencyPair.secondCurrency === secondCurrency,
  );
};

const calculateFiatEquivalents = (
  currency: CurrencyDto,
  currencyPairInCzkLastPriceBN: BigNumber,
  currencyPairInEurLastPriceBN: BigNumber,
  fiatEquivalentsInCzkBN: BigNumber,
  fiatEquivalentsInEurBN: BigNumber,
  amountBN: BigNumber,
) =>
  currency.virtual
    ? {
        CZK: amountBN.times(currencyPairInCzkLastPriceBN).toNumber(),
        EUR: amountBN.times(currencyPairInEurLastPriceBN).toNumber(),
      }
    : {
        CZK: fiatEquivalentsInCzkBN.toNumber(),
        EUR: fiatEquivalentsInEurBN.toNumber(),
      };

export const queryCurrencyBalances = () => {
  return createSelector(getSharedState, (state: SharedState) => state.balances);
};

export const createBalancesWithCurrencyEquivalentsSelector = (
  currencyBalancesSelector: MemoizedSelector<object, Record<string, CurrencyBalanceWithEquivalentsDto>>,
) =>
  createSelector(
    currencyBalancesSelector,
    queryCurrencyPairs(),
    queryCurrencyPairsWithStats(),
    queryCurrencies(),
    (balances, currencyPairs, currencyPairsWithStats, allAvailableCurrencies): BalanceWithCurrencyEquivalents[] => {
      if (!(balances && currencyPairs && currencyPairsWithStats && allAvailableCurrencies)) return [];

      const currencyPairNames: Set<string> = new Set(currencyPairs.map((currencyPair) => currencyPair.firstCurrency));

      return allAvailableCurrencies.reduce((acc: BalanceWithCurrencyEquivalents[], currency) => {
        const balance = balances[currency.name];

        const balanceTotalBN = BigNumber(balance?.totalBalance ?? 0);
        const balanceAvailableBN = BigNumber(balance?.available ?? 0);
        const balanceInOpenOrdersBN = BigNumber(balance?.inOpenOrders ?? 0);
        const fiatEquivalentsInCzkBN = BigNumber(balance?.fiatEquivalents.CZK ?? 0);
        const fiatEquivalentsInEurBN = BigNumber(balance?.fiatEquivalents.EUR ?? 0);
        const currencyPairInCzk = getCurrencyPair(currencyPairsWithStats, currency, 'CZK');
        const currencyPairInEur = getCurrencyPair(currencyPairsWithStats, currency, 'EUR');
        const currencyPairInCzkLastPriceBN = BigNumber(currencyPairInCzk?.lastPrice ?? 0);
        const currencyPairInEurLastPriceBN = BigNumber(currencyPairInEur?.lastPrice ?? 0);
        const currencyPairInCzkChangePriceBN = BigNumber(currencyPairInCzk?.changeIn24Hours ?? 0);
        const currencyPairInEurChangePriceBN = BigNumber(currencyPairInEur?.changeIn24Hours ?? 0);

        const tradeActive = !!(currency.virtual && currencyPairNames.has(currency.name));

        acc.push({
          ...currency,
          tradeActive,
          totalBalance: balanceTotalBN.toNumber(),
          inOpenOrders: balanceInOpenOrdersBN.toNumber(),
          available: balanceAvailableBN.toNumber(),
          fiatEquivalentsTotal: calculateFiatEquivalents(
            currency,
            currencyPairInCzkLastPriceBN,
            currencyPairInEurLastPriceBN,
            fiatEquivalentsInCzkBN,
            fiatEquivalentsInEurBN,
            balanceTotalBN,
          ),
          fiatEquivalentsAvailable: calculateFiatEquivalents(
            currency,
            currencyPairInCzkLastPriceBN,
            currencyPairInEurLastPriceBN,
            fiatEquivalentsInCzkBN,
            fiatEquivalentsInEurBN,
            balanceAvailableBN,
          ),
          lastPrices: {
            CZK: currencyPairInCzkLastPriceBN.toNumber(),
            EUR: currencyPairInEurLastPriceBN.toNumber(),
          },
          changeIn24Hours: {
            CZK: currencyPairInCzkChangePriceBN.toNumber(),
            EUR: currencyPairInEurChangePriceBN.toNumber(),
          },
        });

        return acc;
      }, []);
    },
  );

export const queryCurrencyBalancesWithEquivalents = () =>
  createBalancesWithCurrencyEquivalentsSelector(queryCurrencyBalances());
