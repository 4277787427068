import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

interface item {
  heading: string;
  text: string;
  image: string;
  alt: string;
}

@Component({
  selector: 'homepage-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.scss'],
})
export class SafetyComponent implements OnInit, OnChanges {
  @Input() colorScheme = 'light';

  items: item[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorScheme) {
      this.createItems();
    }
  }

  ngOnInit() {
    this.createItems();
  }

  private getImageName(image: string): string {
    return image + (this.colorScheme === 'dark' ? '-dark' : '') + '.svg';
  }

  private createItems() {
    this.items = [
      {
        heading: 'home-page.safety.safety-1.heading',
        text: 'home-page.safety.safety-1.text',
        image: this.getImageName('shape-trezor'),
        alt: 'trezor',
      },
      {
        heading: 'home-page.safety.safety-2.heading',
        text: 'home-page.safety.safety-2.text',
        image: this.getImageName('shape-money-safety'),
        alt: 'money-safety',
      },
      {
        heading: 'home-page.safety.safety-3.heading',
        text: 'home-page.safety.safety-3.text',
        image: this.getImageName('shape-bankid'),
        alt: 'bankid',
      },
      {
        heading: 'home-page.safety.safety-4.heading',
        text: 'home-page.safety.safety-4.text',
        image: this.getImageName('shape-money-protection'),
        alt: 'money-protection',
      },
    ];
  }
}
