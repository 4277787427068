<section class="cm-section">
  <div class="cm-container">
    <div class="px-[15px]">
      <h2 class="cm-section-heading">{{ 'our-story.media-articles.heading' | translate }}</h2>
    </div>

    <div class="cm-media-articles">
      <app-media-article
        link="https://cc.cz/kazdy-by-mel-drzet-krypto-propady-ukazuji-silu-bitcoinu-ceka-ho-rust-rika-sef-ceske-burzy-coinmate/"
        imgSrc="/assets/img/media1.jpg"
        imgAlt="our-story.media-articles.crypto.img-alt"
        source="Czechcrunch"
        heading="our-story.media-articles.crypto.heading"
        text="our-story.media-articles.crypto.text"
      ></app-media-article>

      <app-media-article
        link="https://www.e15.cz/kryptomeny/nft-neni-pro-masy-a-nevime-jak-se-bitcoin-zachova-v-krizi-rika-zakladatel-coinmate-1389399"
        imgSrc="/assets/img/media2.jpg"
        imgAlt="our-story.media-articles.nft.img-alt"
        source="E15"
        heading="our-story.media-articles.nft.heading"
        text="our-story.media-articles.nft.text"
      ></app-media-article>
    </div>

    <!-- Not used in MVP release, might be required for the next version -->
    <!-- <div class="text-center px-[5px]">-->
    <!--   <a href="#" class="cm-button cm-button-primary">Další články</a>-->
    <!-- </div>-->
  </div>
</section>
