import { TooltipPosition, TooltipPositionX, TooltipPositionY } from '@app/shared/components/tooltip/tooltip.enum';
import { ConnectedPosition } from '@angular/cdk/overlay';

export const DESKTOP_BREAKPOINT = 640;
export const VIEWPORT_MARGIN = 16;

export const FLEXIBLE_POSITIONS = {
  // Right bottom – Tooltip aligned right, appears below the element.
  [TooltipPosition.rightBottom]: {
    originX: TooltipPositionX.start,
    originY: TooltipPositionY.bottom,
    overlayX: TooltipPositionX.start,
    overlayY: TooltipPositionY.top,
  },
  // Right top – Tooltip aligned right, appears above the element.
  [TooltipPosition.rightTop]: {
    originX: TooltipPositionX.start,
    originY: TooltipPositionY.top,
    overlayX: TooltipPositionX.start,
    overlayY: TooltipPositionY.bottom,
  },
  // Left bottom – Tooltip aligned left, appears below the element.
  [TooltipPosition.leftBottom]: {
    originX: TooltipPositionX.end,
    originY: TooltipPositionY.bottom,
    overlayX: TooltipPositionX.end,
    overlayY: TooltipPositionY.top,
  },
  // Left top – Tooltip aligned left, appears above the element.
  [TooltipPosition.leftTop]: {
    originX: TooltipPositionX.end,
    originY: TooltipPositionY.top,
    overlayX: TooltipPositionX.end,
    overlayY: TooltipPositionY.bottom,
  },
  // Centered below – Tooltip centered, appears below the element.
  [TooltipPosition.center]: {
    originX: TooltipPositionX.center,
    originY: TooltipPositionY.bottom,
    overlayX: TooltipPositionX.center,
    overlayY: TooltipPositionY.top,
  },
} as Record<TooltipPosition, ConnectedPosition>;
