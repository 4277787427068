import { Injectable, OnDestroy } from '@angular/core';
import { ApiConfiguration } from '@app/generated/api-configuration';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError, switchMap, first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import type { WP_REST_API_Categories, WP_REST_API_Category, WP_REST_API_Posts, WP_REST_API_Post } from 'wp-types';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { AppGtmService } from '@app/shared/services/gtm.service';

interface WpPageContent {
  custom_fields: {
    slug: string;
    language: string;
  };
  post_content: string;
  post_title: string;
  ID: number;
}

export interface WpPost {
  link: string;
  title: string;
  image?: {
    url: string;
    alt: string;
  };
}

interface YoastHeadJson {
  og_image: {
    url: string;
  }[];
  og_title: string;
}

export enum CategorySlug {
  CryptoBlog = 'krypto-clanky',
  CryptoNews = 'krypto-novinky',
}

@Injectable({
  providedIn: 'root',
})
export class WordpressContentApiService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private apiBaseUrl = 'https://coinmate.io/cz/wp-json';

  constructor(
    protected config: ApiConfiguration,
    protected http: HttpClient,
    private guiParams: GuiParams,
    private gtmService: AppGtmService,
    protected translateService: TranslateService,
  ) {
    this.guiParams.guiParams$
      .pipe(
        first((params) => params.environment !== ''),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (params) => {
          if (params.environment === 'STAGE') {
            this.apiBaseUrl = 'https://stage.coinmate.io/cz/wp-json';
          }
        },
        error: () => {},
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected fetchWordpressContent(): Observable<WpPageContent[]> {
    return this.http.get<WpPageContent[]>(`${this.apiBaseUrl}/content/v1/exchange`);
  }

  protected fetchCategoryIdBySlug(categorySlug: CategorySlug): Observable<WP_REST_API_Category> {
    return this.http
      .get<WP_REST_API_Categories>(`${this.apiBaseUrl}/wp/v2/categories?slug=${categorySlug}`)
      .pipe(map((categories) => categories[0]));
  }

  protected fetchWordpressLatestPostByCategoryId(
    categoryID: WP_REST_API_Category['id'],
    limit: number = 10,
  ): Observable<WP_REST_API_Post | undefined> {
    return this.http
      .get<WP_REST_API_Posts>(
        `${this.apiBaseUrl}/wp/v2/posts?categories=${categoryID}&per_page=${limit}&order=desc&orderby=date`,
      )
      .pipe(map((posts) => posts.find((post) => post.status === 'publish' && !post.content.protected)));
  }

  subscribeToNewsletter(email: string, origin: 'homepage' | 'footer' = 'homepage'): Observable<any> {
    this.gtmService.pushTag({
      event: 'newsletter_subscribed',
      origin,
    });

    return this.http.post(`${this.apiBaseUrl}/content/v1/brevo-subscribe`, { email, origin });
  }

  public async getPageContent(slug: string): Promise<WpPageContent | null> {
    return await firstValueFrom(
      this.fetchWordpressContent().pipe(
        map(
          (data: WpPageContent[]) =>
            data.find(
              (item) =>
                item.custom_fields.slug?.includes(slug) &&
                item.custom_fields.language?.includes(this.translateService.currentLang),
            ) || null,
        ),
        catchError((error) => {
          return throwError(() => new Error('Error processing page content. Please try again later.'));
        }),
      ),
    );
  }

  public async getArticleContent(categorySlug: CategorySlug) {
    return await firstValueFrom(
      this.fetchCategoryIdBySlug(categorySlug).pipe(
        switchMap((category) => {
          if (!category) {
            return of(undefined);
          }

          return this.fetchWordpressLatestPostByCategoryId(category.id, 1).pipe(
            map((post) => {
              if (!post) {
                return;
              }

              const yoastHeadJson = post.yoast_head_json as YoastHeadJson;

              return {
                link: post.link,
                title: post.title.rendered,
                ...(yoastHeadJson?.og_image[0]?.url && {
                  image: {
                    url: yoastHeadJson.og_image[0].url,
                    alt: yoastHeadJson.og_title,
                  },
                }),
              };
            }),
          );
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            return throwError(() => error);
          }
          return throwError(() => new Error('Error processing post content. Please try again later.'));
        }),
      ),
    );
  }
}
