import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  InjectionToken,
  OnInit,
  Optional,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ConnectedOverlayPositionChange,
  FlexibleConnectedPositionStrategy,
  GlobalPositionStrategy,
  OverlayRef,
} from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type TooltipContent = null | undefined | string | TemplateRef<any>;

export const TOOLTIP_CONTENT = new InjectionToken<TooltipContent>('Data to display in tooltip');
export const TOOLTIP_ID = new InjectionToken<string>('Unique Tooltip ID');
export const TOOLTIP_TRIGGER = new InjectionToken<ElementRef>('Tooltip trigger');
export const TOOLTIP = new InjectionToken<OverlayRef>('Tooltip element');

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plain-tooltip.component.html',
  styleUrls: ['./plain-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainTooltipComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private renderer = inject(Renderer2);
  private cdr = inject(ChangeDetectorRef);

  // Try to remove @Optional()
  constructor(
    @Inject(TOOLTIP_CONTENT) @Optional() public tooltipContent: TooltipContent,
    @Inject(TOOLTIP_ID) @Optional() public tooltipId: string,
    @Inject(TOOLTIP_TRIGGER) @Optional() private tooltipTriggerRef: ElementRef,
    @Inject(TOOLTIP) @Optional() private tooltipRef: OverlayRef,
  ) {}

  private setTooltipPositionAttributes(position: ConnectedOverlayPositionChange) {
    if (!this.tooltipTriggerRef) {
      return;
    }

    const tooltipElement = this.tooltipRef.overlayElement;
    const connectionPairPosition = position.connectionPair;
    this.renderer.setAttribute(tooltipElement, 'data-tooltip-x', connectionPairPosition.overlayX);
    this.renderer.setAttribute(tooltipElement, 'data-tooltip-y', connectionPairPosition.overlayY);
  }

  ngOnInit() {
    if (this.tooltipRef) {
      const positionStrategy = this.tooltipRef.getConfig().positionStrategy as
        | FlexibleConnectedPositionStrategy
        | GlobalPositionStrategy;

      if (positionStrategy instanceof FlexibleConnectedPositionStrategy) {
        positionStrategy.positionChanges
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(this.setTooltipPositionAttributes.bind(this));
      }
    }
  }

  isTooltipContentTemplate(tooltipContent: TooltipContent): tooltipContent is TemplateRef<unknown> {
    return tooltipContent instanceof TemplateRef;
  }

  updateTooltipContent(tooltipContent: TooltipContent) {
    this.tooltipContent = tooltipContent;
    this.cdr.detectChanges();
  }
}
