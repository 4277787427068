import { Component, Input } from '@angular/core';
import { TeamMemberInfo } from '@app/unauthenticated/our-story/team-member-info';

@Component({
  selector: 'app-team-member-card[teamMember]',
  templateUrl: './team-member-card.component.html',
  styleUrls: ['./team-member-card.component.scss'],
})
export class TeamMemberCardComponent {
  @Input() teamMember!: TeamMemberInfo;
}
