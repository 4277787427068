import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FooterColumnWithIcons, FooterLink } from '@app/shared/api/footer.api';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer-icon-link',
  standalone: true,
  templateUrl: './footer-icon-link.component.html',
  styleUrls: ['../footer.component.scss', './footer-icon-link.component.scss'],
  imports: [CommonModule, TranslatePipe, SvgIconComponent],
})
export class FooterIconLinkComponent implements OnDestroy {
  @Input() columnData!: FooterColumnWithIcons;

  lang = '';

  private unsubscribe$ = new Subject<void>();

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => (this.lang = val.lang));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getHref(footerLink: FooterLink): string | null {
    if (footerLink?.lang?.length && !footerLink?.lang?.includes(this.lang)) {
      return null;
    }
    return footerLink.link;
  }
}
