import { DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import { DateTime, Duration, DurationUnit, DurationLikeObject, ToHumanDurationOptions } from 'luxon';
import { Language, LanguageService } from '@app/shared/services/language.service';
import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, takeWhile } from 'rxjs/operators';

const FORMAT = {
  listStyle: 'long',
  unitDisplay: 'short',
  maximumFractionDigits: 0,
} as ToHumanDurationOptions;
const DURATION_UNITS = ['day'] as DurationUnit[];

@Pipe({
  name: 'appCountdown',
  standalone: true,
})
export class AppCountdownPipe implements PipeTransform {
  private languageService = inject(LanguageService);
  private dateLuxon$ = new BehaviorSubject<DateTime>(DateTime.now());
  private destroyRef = inject(DestroyRef);

  private timeLeft$: Observable<{ duration: Duration; locale: Language }> = combineLatest([
    this.dateLuxon$,
    this.languageService.currentLanguage$,
  ]).pipe(
    switchMap(([date, currentLanguage]: [DateTime, Language]) => {
      if (!date.isValid || date.diffNow().valueOf() <= 0) {
        const duration = date.diffNow();
        return of({ duration, locale: currentLanguage });
      }

      return interval(1000, animationFrameScheduler).pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(0),
        map(() => date.diffNow()),
        takeWhile((duration: Duration) => duration.valueOf() >= 0),
        map((duration) => ({ duration, locale: currentLanguage })),
      );
    }),
  );

  transform(
    date: string | undefined | null,
    units: DurationUnit[] = DURATION_UNITS,
    format: ToHumanDurationOptions = FORMAT,
  ): Observable<string> {
    if (date) {
      this.dateLuxon$.next(DateTime.fromISO(date));
    }
    return this.timeLeft$.pipe(
      map(({ duration, locale }) => {
        let shiftToUnits = units.concat();
        if (duration.as('days') < 1) {
          shiftToUnits = ['hours'];
        }
        if (duration.as('hours') < 1) {
          shiftToUnits = ['minutes'];
        }
        if (duration.as('minutes') < 1) {
          shiftToUnits = ['seconds'];
        }

        if (duration.valueOf() < 1000) {
          return '';
        }

        return this.formatDuration(duration.shiftTo(...shiftToUnits))
          .reconfigure({ locale })
          .toHuman(format);
      }),
    );
  }

  private formatDuration(duration: Duration): Duration {
    const nonZeroValues = Object.entries(duration.toObject())
      .filter(([_, value]) => value > 0)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as DurationLikeObject;

    return Duration.fromObject(nonZeroValues);
  }
}
