import { DestroyRef, Directive, inject, Injector, Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseTooltipDirective } from '@app/shared/components/tooltip/base-tooltip.directive';
import {
  RichTooltipComponent,
  TOOLTIP,
  TOOLTIP_CONTENT,
  TOOLTIP_ID,
  TOOLTIP_CONTEXT,
  TOOLTIP_TRIGGER,
  TooltipContent,
} from '@app/shared/components/tooltip/rich-tootlip/rich-tooltip.component';
import { FLEXIBLE_POSITIONS, VIEWPORT_MARGIN } from '@app/shared/components/tooltip/tooltip.const';
import { TooltipTriggerMode } from '@app/shared/components/tooltip/tooltip.enum';

@Directive({
  selector: '[appRichTooltip]',
  standalone: true,
})
export class RichTooltipDirective extends BaseTooltipDirective<RichTooltipComponent> {
  @Input() appRichTooltip!: TooltipContent;
  @Input() tooltipContext: {
    [key: string]: any;
  } = {};

  private destroyRef = inject(DestroyRef);

  tooltipTriggerMode = TooltipTriggerMode.click;

  createTooltip() {
    if (this.isTooltipAttached() || !this.appRichTooltip) {
      return;
    }

    if (this.overlayRef) {
      this.destroyTooltip();
    }

    let flexiblePositions = Object.values(FLEXIBLE_POSITIONS);
    if (this.tooltipPosition && this.tooltipPosition in FLEXIBLE_POSITIONS) {
      const { [this.tooltipPosition]: preferredFlexiblePosition, ...preferredFlexiblePositions } = FLEXIBLE_POSITIONS;
      flexiblePositions = [
        preferredFlexiblePosition,
        ...Object.values(preferredFlexiblePositions),
      ] as ConnectedPosition[];
    }

    const positionStrategy = this.mobileViewportSubject.value
      ? this.overlay.position().global().centerHorizontally().bottom()
      : this.overlay
          .position()
          .flexibleConnectedTo(this.elementRef)
          .withPositions(flexiblePositions)
          .withViewportMargin(VIEWPORT_MARGIN)
          .withPush(true);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      backdropClass: 'tooltip-backdrop',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    this.overlayRef
      .outsidePointerEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.destroyTooltip.bind(this));

    this.overlayRef
      .keydownEvents()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event.key === 'Escape'),
      )
      .subscribe(this.destroyTooltip.bind(this));

    const tooltipInjector = Injector.create({
      providers: [
        { provide: TOOLTIP_CONTENT, useValue: this.appRichTooltip },
        { provide: TOOLTIP_ID, useValue: this.tooltipId },
        {
          provide: TOOLTIP_CONTEXT,
          useValue: {
            ...this.tooltipContext,
            close: this.destroyTooltip.bind(this),
          },
        },
        { provide: TOOLTIP_TRIGGER, useValue: this.elementRef },
        { provide: TOOLTIP, useValue: this.overlayRef },
      ],
      parent: this.injector,
    });

    this.tooltipComponentPortalRef = new ComponentPortal(RichTooltipComponent, this.viewContainerRef, tooltipInjector);
    this.tooltipComponentRef = this.overlayRef.attach(this.tooltipComponentPortalRef);
  }
}
