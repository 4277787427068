import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

interface Benefit {
  title: string;
  text: string;
}

interface Step {
  number: number;
  title: string;
  text: string;
}

@Component({
  selector: 'app-otc-desk',
  standalone: true,
  templateUrl: './otc-desk.component.html',
  styleUrls: ['./otc-desk.component.scss'],
  imports: [CommonModule, SharedModule],
})
export class OtcDeskComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('swiperContainer') private readonly swiperContainer!: ElementRef;

  private readonly unsubscribe$ = new Subject<void>();
  private swiper?: Swiper;

  protected lang = 'cs';
  protected readonly of = of;

  private readonly swiperConfig: SwiperOptions = {
    modules: [Pagination],
    direction: 'horizontal',
    spaceBetween: 30,
    grabCursor: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    initialSlide: 0,
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      1048: {
        initialSlide: 2,
      },
    },
  };

  protected readonly benefits: Benefit[] = [
    { title: 'otc-desk.benefits.benefit-1-title', text: 'otc-desk.benefits.benefit-1-text' },
    { title: 'otc-desk.benefits.benefit-2-title', text: 'otc-desk.benefits.benefit-2-text' },
    { title: 'otc-desk.benefits.benefit-3-title', text: 'otc-desk.benefits.benefit-3-text' },
    { title: 'otc-desk.benefits.benefit-4-title', text: 'otc-desk.benefits.benefit-4-text' },
    { title: 'otc-desk.benefits.benefit-5-title', text: 'otc-desk.benefits.benefit-5-text' },
    { title: 'otc-desk.benefits.benefit-6-title', text: 'otc-desk.benefits.benefit-6-text' },
    { title: 'otc-desk.benefits.benefit-7-title', text: 'otc-desk.benefits.benefit-7-text' },
    { title: 'otc-desk.benefits.benefit-8-title', text: 'otc-desk.benefits.benefit-8-text' },
  ];

  protected readonly steps: Step[] = [
    { number: 1, title: 'otc-desk.how-it-works.step-1-title', text: 'otc-desk.how-it-works.step-1-text' },
    { number: 2, title: 'otc-desk.how-it-works.step-2-title', text: 'otc-desk.how-it-works.step-2-text' },
    { number: 3, title: 'otc-desk.how-it-works.step-3-title', text: 'otc-desk.how-it-works.step-3-text' },
    { number: 4, title: 'otc-desk.how-it-works.step-4-title', text: 'otc-desk.how-it-works.step-4-text' },
    { number: 5, title: 'otc-desk.how-it-works.step-5-title', text: 'otc-desk.how-it-works.step-5-text' },
    { number: 6, title: 'otc-desk.how-it-works.step-6-title', text: 'otc-desk.how-it-works.step-6-text' },
    { number: 7, title: 'otc-desk.how-it-works.step-7-title', text: 'otc-desk.how-it-works.step-7-text' },
  ];

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.initializeLanguage();
  }

  ngAfterViewInit(): void {
    this.initializeSwiper();
  }

  ngOnDestroy(): void {
    this.cleanup();
  }

  private initializeLanguage(): void {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(({ lang }) => (this.lang = lang));
  }

  private initializeSwiper(): void {
    if (!this.swiperContainer) {
      console.warn('Swiper container not found');
      return;
    }

    this.swiper = new Swiper(this.swiperContainer.nativeElement, this.swiperConfig);
  }

  private cleanup(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.swiper?.destroy();
  }
}
