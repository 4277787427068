<div class="h-full relative flex">
  <div class="flex flex-col flex-1 gap-y-[20px] overflow-hidden">
    <div class="flex-1 overflow-hidden">
      <canvas
        baseChart
        [type]="chartType"
        [data]="initialChartData"
        [options]="chartOptions"
        [plugins]="chartPlugins"
      ></canvas>
    </div>
    <div class="flex items-center" [id]="chartID"></div>
  </div>

  <ng-container *ngIf="!loading; else loader">
    <ng-container *ngIf="chartHasData; else noData"> </ng-container>
  </ng-container>

  <ng-template #loader>
    <app-loader [absolute]="true"></app-loader>
  </ng-template>

  <ng-template #noData>
    <div class="w-full h-full absolute flex items-center justify-center text-cm-grey-300 text-sm">
      <ng-container *ngIf="noDataMessage; else noDataTemplate">
        {{ noDataMessage }}
      </ng-container>

      <ng-template #noDataTemplate>
        {{ 'shared.common.no-data' | translate }}
      </ng-template>
    </div>
  </ng-template>
</div>
