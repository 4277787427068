<div *ngIf="displayCurrencies.length > 0" class="context-currency-bar-balances">
  {{ 'balances' | translate }}
  <app-action-button appearance="text-small md:text-base" [size]="'xs'" (click)="openModal()">
    <ng-container *ngFor="let currency of displayCurrencies">
      <span>
        <b>{{ currency.name }}:&nbsp;</b>
        <span class="font-normal">{{ currency.available | appCurrencyFloor : currency.name | async }}</span>
      </span>
    </ng-container>

    <span class="ml-8px" *ngIf="moreCount > 0">+{{ moreCount }} {{ 'more' | translate }}</span>
  </app-action-button>
</div>
