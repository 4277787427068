<article class="cm-timeline-item">
  <img class="cm-timeline-img" src="{{ imgSrc }}" alt="{{ imgAlt | translate }}" />
  <div class="cm-timeline-text">
    <span class="cm-blue-tag">{{ time }}</span>
    <h3 class="cm-timeline-heading">{{ heading | translate }}</h3>
    <p class="cm-paragraph">
      {{ text | translate }}
    </p>
  </div>
  <p class="cm-paragraph mobile-only">
    {{ text | translate }}
  </p>
</article>
