<homepage-intro [lang]="lang" [isLogged]="isLogged"></homepage-intro>

<homepage-media [colorScheme]="colorScheme" [lang]="lang"></homepage-media>

<homepage-benefits [colorScheme]="colorScheme" [lang]="lang"></homepage-benefits>

<homepage-newsletter *ngIf="lang === 'cs'"></homepage-newsletter>

<homepage-solutions [colorScheme]="colorScheme" [lang]="lang"></homepage-solutions>

<homepage-numbers></homepage-numbers>

<homepage-tutorial [lang]="lang"></homepage-tutorial>

<homepage-safety [colorScheme]="colorScheme"></homepage-safety>

<homepage-partners></homepage-partners>

<homepage-education *ngIf="lang === 'cs'"></homepage-education>

<homepage-other [colorScheme]="colorScheme" [lang]="lang"></homepage-other>

<homepage-register *ngIf="!isLogged" [lang]="lang"></homepage-register>
