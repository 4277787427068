import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { equals, mergeDeepRight } from 'ramda';
import { TranslatePipe } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartData, ChartOptions, ChartType, Plugin } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { v4 as uuidv4 } from 'uuid';
import 'chartjs-adapter-luxon';

import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import htmlLegendPlugin from '@app/shared/components/charts/plugins/html-legend';

Chart.register(annotationPlugin);

type LineChartData = ChartData<'line'>;
type LineChartOptions = ChartOptions<'line'>;

@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [CommonModule, BaseChartDirective, TranslatePipe, LoaderComponent],
  templateUrl: './line-chart.component.html',
})
export class LineChartComponent implements AfterViewInit, OnDestroy {
  @Input() data$: Observable<LineChartData> = of({
    labels: [],
    datasets: [],
  });
  @Input() options$: Observable<ChartConfiguration['options']> = of({});
  @Input() plugins: Plugin[] = [];
  @Input() loading? = false;
  @Input() noDataMessage? = '';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  chartID = uuidv4();
  initialChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [],
  };
  chartOptions: ChartConfiguration['options'] = {};
  chartPlugins = [htmlLegendPlugin];
  chartType: ChartType = 'line';
  chartHasData = false;

  private unsubscribe$ = new Subject<void>();

  private defaultChartOptions = {
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
        fill: false,
        borderWidth: 1,
      },
      point: {
        radius: 4,
        hoverRadius: 7,
      },
    },
    plugins: {
      legend: {
        display: false,
        align: 'start',
        position: 'bottom',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 30,
          font: {
            size: 12,
            weight: 'bold',
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      htmlLegend: {
        containerID: this.chartID,
        orientation: 'horizontal',
      },
    },
  } as LineChartOptions;

  ngAfterViewInit() {
    if (this.plugins) {
      this.chartPlugins = [...this.chartPlugins, ...this.plugins];
    }

    this.data$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((a, b) => equals(a, b)),
      )
      .subscribe((updatedChartData) => {
        const updatedChartDatasets = updatedChartData?.datasets || {};
        const updatedChartLabels = updatedChartData?.labels || [];

        const chartHasDataPrevious = this.chartHasData;
        this.chartHasData = updatedChartDatasets.some((dataset) => dataset.data.filter(Boolean).length);

        if (!this.chart) {
          return;
        }

        if (this.chart.data) {
          /**
           * Updating data
           *
           * This update ensures smooth animations during data transitions.
           * By updating datasets individually and mutating the original data,
           * the chart can calculate the transition between the current and new data points.
           * Without this approach, the transition would not be smooth.
           *
           * https://www.chartjs.org/docs/latest/developers/updates.html#adding-or-removing-data
           */
          this.chart.data.labels = updatedChartLabels;

          const chartDatasets = this.chart.data.datasets;
          updatedChartDatasets.forEach((updatedChartDataset, index) => {
            if (chartDatasets[index]) {
              chartDatasets[index] = Object.assign(chartDatasets[index], updatedChartDataset);
            } else {
              chartDatasets.push(Object.assign({}, updatedChartDataset));
            }
          });

          if (chartDatasets.length > updatedChartDatasets.length) {
            this.chart.data.datasets = chartDatasets.slice(0, updatedChartDatasets.length);
          }
        }

        if (this.chart.chart?.canvas) {
          this.chart.chart.canvas.style.display = this.chartHasData ? 'block' : 'none';
        }

        /**
         * Disable animations for rendering newly loaded data after showing a loader.
         * This ensures the chart instantly displays the updated data without transitioning
         * from the initial empty or loading state.
         */
        this.chart.update(this.chartHasData && chartHasDataPrevious ? undefined : 'none');
      });

    this.options$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !!this.chart),
        distinctUntilChanged((a, b) => equals(a, b)),
        map((options) => {
          return mergeDeepRight(this.defaultChartOptions as object, options as object);
        }),
      )
      .subscribe((chartOptions) => {
        this.chartOptions = chartOptions;

        if (this.chart) {
          this.chart.update();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
