<h2 *ngIf="columnData.title" class="cm-footer-heading">{{ columnData.title | translate }}</h2>
<p *ngIf="columnData.caption" class="cm-footer-caption">{{ columnData.caption | translate }}</p>
<ul class="cm-footer-icon-links">
  <ng-container *ngFor="let item of columnData.links">
    <li *ngIf="!item.lang?.length || item.lang?.includes(lang)" class="flex">
      <a
        *ngIf="getHref(item); let href"
        [target]="item.openInNewTab ? '_blank' : '_self'"
        class="cm-button cm-button-secondary-link"
        [href]="[href]"
      >
        <span class="sr-only">{{ item.text | translate }}</span>
        <svg-icon svgClass="cm-footer-icon-link-icon" [src]="'/assets/icons/' + item.icon" aria-hidden="true" />
      </a>
    </li>
  </ng-container>
</ul>
