export enum LoadState {
  idle,
  loading,
}

export enum OrderDirection {
  buy = 'BUY',
  sell = 'SELL',
  // Used to highlight the type of open orders
  buyStop = 'BUY STOP',
  sellStop = 'SELL STOP',
}

export enum OrderType {
  limit,
  market,
  limitStop,
}

export enum CreateOrderControlName {
  orderType = 'orderType',
  amount = 'amount',
  price = 'price',
  total = 'total',
  stopPrice = 'stopPrice',
  hidden = 'hidden',
  trailing = 'trailing',
  postOnly = 'postOnly',
}

export enum ValueChangeTrend {
  down = 'DOWN',
  up = 'UP',
}

export enum ValueChangeSign {
  negative = 'NEGATIVE',
  positive = 'POSITIVE',
}

export enum OrderResultState {
  saved = 'SAVED',
  settled = 'SETTLED',
  cancelled = 'CANCELLED',
}

export enum OrderErrorType {
  ridiculousPriceException = 'RidiculousPriceException',
  insufficientMarketDepthException = 'InsufficientMarketDepthException',
  invalidPriceInputPrecisionException = 'InvalidPriceInputPrecisionException',
}

export enum MobileTab {
  dashboard = 'dashboard',
  wallet = 'wallet',
  history = 'history',
}

export enum TradingPlatformUserSettingsKeys {
  collapsedAreas = 'collapsedAreas',
}

export enum TradingPlatformCollapsableArea {
  historyAndOpenOrders = 'history-and-open-orders',
}

export enum AlertType {
  Liquidity = 'liquidity',
  EmptyHistory = 'emptyHistory',
}
