<section class="cm-section">
  <div class="cm-container">
    <h2 class="cm-section-heading">{{ 'our-story.timeline.heading' | translate }}</h2>

    <app-timeline-item
      imgSrc="/assets/img/timeline1.png"
      time="2013"
      heading="our-story.timeline.beer-for-bitcoins.heading"
      text="our-story.timeline.beer-for-bitcoins.text"
      imgAlt="our-story.timeline.beer-for-bitcoins.img-alt"
    ></app-timeline-item>

    <app-timeline-item
      imgSrc="/assets/img/timeline2.png"
      time="2014"
      heading="our-story.timeline.exchange-created.heading"
      text="our-story.timeline.exchange-created.text"
      imgAlt="our-story.timeline.exchange-created.img-alt"
    ></app-timeline-item>

    <app-timeline-item
      imgSrc="/assets/img/timeline3.png"
      time="2015—2019"
      heading="our-story.timeline.growth.heading"
      text="our-story.timeline.growth.text"
      imgAlt="our-story.timeline.growth.img-alt"
    ></app-timeline-item>

    <app-timeline-item
      imgSrc="/assets/img/timeline4.png"
      time="2020"
      heading="our-story.timeline.billion.heading"
      text="our-story.timeline.billion.text"
      imgAlt="our-story.timeline.billion.img-alt"
    ></app-timeline-item>

    <app-timeline-item
      imgSrc="/assets/img/timeline5.png"
      time="2021"
      heading="our-story.timeline.miton-investment.heading"
      text="our-story.timeline.miton-investment.text"
      imgAlt="our-story.timeline.miton-investment.img-alt"
    ></app-timeline-item>

    <app-timeline-item
      imgSrc="/assets/img/timeline6.png"
      time="2024"
      heading="our-story.timeline.tipsport-cooperation.heading"
      text="our-story.timeline.tipsport-cooperation.text"
      imgAlt="our-story.timeline.tipsport-cooperation.img-alt"
    ></app-timeline-item>
  </div>
</section>
