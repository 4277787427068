<section class="cm-section with-bg">
  <div class="container max-w-screen-xl">
    <div class="w-full flex flex-wrap md:flex-nowrap items-center">
      <div class="md:pr-6 xl:pr-10 w-full md:w-auto flex-none md:flex-1 mb-8 md:mb-0">
        <h1 class="display-h1 mb-36px">{{ 'compare.title' | translate }}</h1>
        <p class="text-[24px] leading-[1.5] mb-[25px]">
          {{ 'compare.intro' | translate }}
        </p>
        <a
          [routerLink]="'/sign-up' | localize"
          class="cm-button cm-button-primary cm-button-large"
          [ngClass]="lang === 'cs' ? 'mb-4' : 'mb-[30px]'"
        >
          {{ 'home-page.create-account' | translate }}
        </a>
        <div class="flex items-end display-caption-small" *ngIf="lang === 'cs'">
          <span class="leading-none">{{ 'home-page.intro.bank-id' | translate }}</span
          >&nbsp;<svg-icon src="/assets/icons/bank-id.svg"></svg-icon>
        </div>
      </div>
      <div class="flex-none w-full md:w-auto">
        <img
          src="/assets/img/subpages/compare.png"
          class="block mx-auto w-[250px] md:w-[300px] max-w-full"
          alt="Comparation"
        />
      </div>
    </div>
  </div>

  <div class="container max-w-5xl">
    <div class="w-full text-center pt-12 md:pt-28 max-w-5xl mx-auto">
      <h2 class="display-h2 mb-20px">{{ 'compare.domestic-headline' | translate }}</h2>
      <p class="display-paragraph-blog">
        {{ 'compare.domestic-text-1' | translate }}<br /><br />
        {{ 'compare.domestic-text-2' | translate }}<br /><br />
        {{ 'compare.domestic-text-3' | translate }}
      </p>
    </div>
    <div class="w-full pt-24px">
      <h3 class="display-h3 mb-16px">{{ 'compare.table-headline' | translate }}</h3>
      <ng-container *ngIf="lang">
        <div class="comparison-desktop">
          <table>
            <tr>
              <th>{{ 'compare.platform' | translate }}</th>
              <th>{{ 'compare.fees' | translate }}</th>
              <th>{{ 'compare.total-fees' | translate }}</th>
            </tr>
            <tr *ngFor="let platform of domesticPlatforms">
              <td>
                <app-theme-image
                  [height]="platform.logoHeightPx"
                  [srcLight]="'/assets/img/subpages/' + platform.logo"
                  [srcDark]="'/assets/img/subpages/' + platform.logoDark"
                  [alt]="platform.name"
                />
              </td>
              <td [innerHTML]="platform.fees[lang]"></td>
              <td [innerHTML]="platform.costExample[lang]"></td>
            </tr>
          </table>
        </div>
        <div class="comparison-mobile">
          <table *ngFor="let platform of domesticPlatforms">
            <tr>
              <th colspan="2">
                <app-theme-image
                  [height]="platform.logoHeightPx"
                  [srcLight]="'/assets/img/subpages/' + platform.logo"
                  [srcDark]="'/assets/img/subpages/' + platform.logoDark"
                  [alt]="platform.name"
                />
              </th>
            </tr>
            <tr>
              <td>
                <strong>{{ 'compare.fees' | translate }}</strong>
              </td>
              <td [innerHTML]="platform.fees[lang]"></td>
            </tr>
            <tr>
              <td>
                <strong>{{ 'compare.total-fees' | translate }}</strong>
              </td>
              <td [innerHTML]="platform.costExample[lang]"></td>
            </tr>
          </table>
        </div>
      </ng-container>
      <p class="text-12px md:text-13px text-center mb-0 text-cm-grey-300 pt-16px">
        {{ 'compare.domestic-updated-at' | translate }}
      </p>
    </div>
  </div>
</section>

<section class="cm-section bg-cm-grey-50 dark:bg-cm-blue-800">
  <div class="container max-w-5xl">
    <div class="w-full text-center max-w-5xl mx-auto">
      <h2 class="display-h2 mb-20px">{{ 'compare.foreign-headline' | translate }}</h2>
      <p class="display-paragraph-blog">
        {{ 'compare.foreign-text-1' | translate }}<br /><br />
        {{ 'compare.foreign-text-2' | translate }}
      </p>
    </div>
    <div class="w-full pt-24px">
      <h3 class="display-h3 mb-16px">{{ 'compare.table-headline-foreign' | translate }}</h3>
      <div class="comparison-desktop">
        <table>
          <tr>
            <th>{{ 'compare.platform' | translate }}</th>
            <th class="min-w-44">{{ 'compare.exchange-fees' | translate }}</th>
            <th>{{ 'compare.banking-fees' | translate }}</th>
            <th class="min-w-44">{{ 'compare.total-fees' | translate }}</th>
            <th>{{ 'compare.fees-example' | translate }}</th>
          </tr>
          <tr *ngFor="let platform of foreignPlatforms">
            <td>
              <app-theme-image
                [height]="platform.logoHeightPx"
                [srcLight]="'/assets/img/subpages/' + platform.logo"
                [srcDark]="'/assets/img/subpages/' + platform.logoDark"
                [alt]="platform.name"
              />
            </td>
            <td>{{ platform.exchangeFees[lang] }}</td>
            <td [innerHTML]="platform.bankFees[lang]"></td>
            <td>{{ platform.totalCost[lang] }}</td>
            <td [innerHTML]="platform.costExample[lang]"></td>
          </tr>
        </table>
      </div>

      <div class="comparison-mobile">
        <table *ngFor="let platform of foreignPlatforms">
          <tr>
            <th colspan="2">
              <app-theme-image
                [height]="platform.logoHeightPx"
                [srcLight]="'/assets/img/subpages/' + platform.logo"
                [srcDark]="'/assets/img/subpages/' + platform.logoDark"
                [alt]="platform.name"
              />
            </th>
          </tr>
          <tr>
            <td>
              <strong>{{ 'compare.exchange-fees' | translate }}</strong>
            </td>
            <td>{{ platform.exchangeFees[lang] }}</td>
          </tr>
          <tr>
            <td>
              <strong>{{ 'compare.banking-fees' | translate }}</strong>
            </td>
            <td [innerHTML]="platform.bankFees[lang]"></td>
          </tr>
          <tr>
            <td>
              <strong>{{ 'compare.total-fees' | translate }}</strong>
            </td>
            <td>{{ platform.totalCost[lang] }}</td>
          </tr>
          <tr>
            <td>
              <strong>{{ 'compare.fees-example' | translate }}</strong>
            </td>
            <td [innerHTML]="platform.costExample[lang]"></td>
          </tr>
        </table>
      </div>
      <p class="text-12px md:text-13px text-center mb-0 text-cm-grey-300 pt-16px">
        {{ 'compare.foreign-updated-at' | translate }}
      </p>
    </div>
  </div>
</section>

<homepage-numbers />

<section class="cm-section">
  <div class="container max-w-5xl">
    <div class="w-full text-center max-w-5xl mx-auto">
      <h2 class="display-h2 mb-20px">{{ 'compare.invest-today-headline' | translate }}</h2>
      <p class="display-paragraph-blog mb-24px">
        {{ 'compare.invest-today-text' | translate }}
      </p>
      <a routerLink="/quick-trade" class="cm-button cm-button-primary cm-button-large">{{
        'compare.invest-today-cta' | translate
      }}</a>
    </div>
  </div>
</section>
