import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ribbon, button[ribbon]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss'],
})
export class RibbonComponent {
  @Input() type: 'info' | 'warning' = 'info';
}
