export const VALID_NUMBER_FORMAT_EN_REGEX = /^(\d+[.,]?)*$/;
export const VALID_NUMBER_FORMAT_CS_REGEX = /^(\d+[,]?)*$/;

export const DECIMAL_SEPARATOR_EN = '.';
export const DECIMAL_SEPARATOR_EN_REGEX = new RegExp(`\\${DECIMAL_SEPARATOR_EN}`, 'g');
export const DECIMAL_SEPARATOR_CS = ',';
export const DECIMAL_SEPARATOR_CS_REGEX = new RegExp(`\\${DECIMAL_SEPARATOR_CS}`, 'g');

export const DECIMAL_SEPARATOR_REGEX = /^[.,]$/;

export const NON_VISIBLE_SPACES_REGEX = /[^\S]/g;

export const NUMERIC_REGEX = /^(\d+)([.,])?(\d+)?$/;
