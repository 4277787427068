import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { HomepageModule } from '@app/unauthenticated/homepage/homepage.module';

@Component({
  selector: 'app-affiliate-program',
  standalone: true,
  templateUrl: './affiliate-program.component.html',
  styleUrls: ['./affiliate-program.component.scss'],
  imports: [CommonModule, SharedModule, HomepageModule],
})
export class AffiliateProgramComponent implements OnInit, OnDestroy {
  lang = 'cs';
  private unsubscribe$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.lang = data.lang));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
