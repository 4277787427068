import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  template: '',
})
export class TrackingParametersComponent {
  private readonly signUpStoragePrefix = 'signUp.';
  private readonly analyticsStoragePrefix = 'analytics.';
  protected readonly isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      const urlParams = new URLSearchParams(window.location.search);
      this.processUrlParameters(urlParams);
    }
  }

  private processUrlParameters(urlParams: URLSearchParams): void {
    // Process referral and click IDs
    this.saveReferralOrClickId(
      urlParams.get('referral'),
      urlParams.get('clickid'),
      urlParams.get('affiliate'),
      urlParams.get('invite'),
      urlParams.get('consultant'),
    );

    // Process UTM parameters
    this.saveUtmParameters(
      urlParams.get('utm_source'),
      urlParams.get('utm_medium'),
      urlParams.get('utm_campaign'),
      urlParams.get('utm_content'),
      urlParams.get('utm_term'),
    );
  }

  private saveReferralOrClickId(
    referralCode: string | null,
    clickId: string | null,
    affiliateCode: string | null,
    inviteCode: string | null,
    consultant: string | null,
  ): void {
    if (referralCode) {
      this.safelySetItem(`${this.signUpStoragePrefix}affiliateCode`, referralCode);
    }
    if (clickId) {
      this.safelySetItem(`${this.signUpStoragePrefix}clickId`, clickId);
    }
    if (affiliateCode) {
      this.safelySetItem(`${this.signUpStoragePrefix}affiliateCode`, affiliateCode);
    }
    if (inviteCode) {
      this.safelySetItem(`${this.signUpStoragePrefix}referralCode`, inviteCode);
    }
    if (consultant) {
      this.safelySetItem(`${this.signUpStoragePrefix}consultant`, consultant);
    }
  }

  private saveUtmParameters(
    source: string | null,
    medium: string | null,
    campaign: string | null,
    content: string | null,
    term: string | null,
  ): void {
    if (source) {
      this.safelySetItem(`${this.analyticsStoragePrefix}utmSource`, source);
    }
    if (medium) {
      this.safelySetItem(`${this.analyticsStoragePrefix}utmMedium`, medium);
    }
    if (campaign) {
      this.safelySetItem(`${this.analyticsStoragePrefix}utmCampaign`, campaign);
    }
    if (content) {
      this.safelySetItem(`${this.analyticsStoragePrefix}utmContent`, content);
    }
    if (term) {
      this.safelySetItem(`${this.analyticsStoragePrefix}utmTerm`, term);
    }
  }

  private safelySetItem(key: string, value: string): void {
    if (!this.isBrowser) {
      return;
    }

    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      console.error('Unable to access localStorage:', error);
    }
  }
}
