import { Chart, ChartConfiguration, Plugin } from 'chart.js';
import { INVISIBLE_SEPARATOR } from '@app/shared/const/string';

interface Options {
  containerID: string | undefined;
  orientation: 'horizontal' | 'vertical';
}

function getLegendList(chart: Chart, options: Options) {
  const { containerID, orientation } = options;
  const legendContainer = containerID && document.getElementById(containerID);

  if (!legendContainer) {
    return;
  }

  return legendContainer.querySelector('ul');
}

function createLegendList(chart: Chart, options: Options) {
  const { containerID, orientation } = options;

  let listContainer = getLegendList(chart, options);
  if (listContainer) {
    return listContainer;
  }

  const legendContainer = containerID && document.getElementById(containerID);
  if (!legendContainer) {
    return;
  }

  listContainer = document.createElement('ul');
  listContainer.style.display = 'flex';
  listContainer.style.flex = '1';
  listContainer.style.flexWrap = 'wrap';
  listContainer.style.flexDirection = orientation === 'vertical' ? 'column' : 'row';
  listContainer.style.margin = '0';
  listContainer.style.padding = '0';

  legendContainer.appendChild(listContainer);

  return listContainer;
}

function removeLegendList(chart: Chart, options: Options) {
  const legendList = getLegendList(chart, options);

  if (legendList) {
    legendList.remove();
  }
}

const htmlLegendPlugin: Plugin = {
  id: 'htmlLegend',
  beforeDestroy(chart: Chart, args: any, options: Options) {
    removeLegendList(chart, options);
  },
  afterUpdate(chart: Chart, args: any, options: Options) {
    const generateLabels = chart.options.plugins?.legend?.labels?.generateLabels;
    if (typeof generateLabels !== 'function') {
      removeLegendList(chart, options);
      return;
    }

    const chartHasData = chart.data?.datasets.some((dataset) => dataset.data.filter(Boolean).length);
    if (!chartHasData) {
      removeLegendList(chart, options);
      return;
    }

    let ul = createLegendList(chart, options);
    if (!ul) {
      return;
    }

    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = generateLabels(chart) || [];

    items
      .filter((item) => item.text)
      .forEach((item) => {
        const type = (chart.config as ChartConfiguration).type;

        const li = document.createElement('li');
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.marginLeft = '10px';
        li.style.padding = '5px';
        li.style.columnGap = '4px';
        li.style.fontSize = '12px';
        li.style.borderRadius = '4px';

        li.onclick = () => {
          if (typeof item.index === 'number' && (type === 'pie' || type === 'doughnut')) {
            chart.toggleDataVisibility(item.index);
          } else if (typeof item.datasetIndex === 'number') {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle as string;
        boxSpan.style.borderColor = item.strokeStyle as string;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = '0';
        boxSpan.style.width = '12px';
        boxSpan.style.height = '12px';
        boxSpan.style.borderRadius = '50%';

        const textContainer = document.createElement('span');
        textContainer.style.display = 'flex';
        textContainer.style.flex = '1';
        textContainer.style.alignItems = 'center';
        textContainer.style.justifyContent = 'space-between';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const texts = item.text.split(INVISIBLE_SEPARATOR);
        for (const [index, text] of texts.entries()) {
          const textWrap = document.createElement('span');
          const textEl = document.createTextNode(text);

          if (index === 0) {
            textWrap.style.fontWeight = 'bold';
          } else {
            textWrap.classList.add('text-cm-grey-300');
            textWrap.classList.add('dark:text-cm-grey-200');
          }

          textWrap.appendChild(textEl);
          textContainer.appendChild(textWrap);
        }

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
  },
};

export default htmlLegendPlugin;
