<section class="homepage-section-container">
  <h2 class="display-h2 mb-8 lg:mb-16" [innerHTML]="'home-page.solutions.heading' | translate"></h2>
  <div class="items">
    <ng-container *ngFor="let solution of solutions">
      <div class="item">
        <div class="item-column text-center md:text-left">
          <h3 class="display-h3 mb-4 md:max-w-[22ch]" [innerHTML]="solution.heading | translate"></h3>
          <div class="flex flex-1 flex-col">
            <div class="flex-1 mb-6 display-paragraph-blog font-normal">
              <p class="max-w-[29ch]" [innerHTML]="solution.text | translate"></p>
            </div>
            <app-link-pair
              *ngIf="solution.routerLink"
              [routerLink]="solution.routerLink"
              [addressLabel]="solution.linkLabel || '' | translate"
              addressIcon="/assets/img/arrow-right-blue.svg"
            ></app-link-pair>
            <app-link-pair
              *ngIf="solution.link"
              [addressHref]="solution.link"
              [addressLabel]="solution.linkLabel || '' | translate"
              addressIcon="/assets/img/arrow-right-blue.svg"
            ></app-link-pair>
          </div>
        </div>
        <div class="item-column relative">
          <div class="item-ímage-wrap">
            <div class="item-image-box">
              <img
                [src]="'/assets/img/homepage/solutions/' + solution.image + '.png'"
                [alt]="solution.alt"
                class="item-image"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
