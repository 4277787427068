import { Component } from '@angular/core';

@Component({
  selector: 'homepage-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent {
  cooperations = [
    {
      href: 'https://www.tipsport.cz/blogy/vsadte-na-krypto-s-tipsportem',
      image: 'tipsport.png',
      alt: 'Tipsport',
    },
    {
      href: 'https://web3hubzlin.cz/',
      image: 'web3.png',
      alt: 'Web3 HUB Zlín',
    },
  ];

  membersOf = [
    {
      href: 'https://czechfintech.cz/',
      image: 'cfasociace.png',
      alt: 'Česká fintechová asociace',
    },
  ];

  certificates = [
    {
      href: 'https://coinmate.io/cz/wp-content/uploads/2025/03/iso-iec-270012022.pdf',
      image: 'iso-iec-27001-2022.svg',
      alt: 'ISO/IEC 27001:2022',
    },
  ];
}
