<section class="cm-section">
  <div class="cm-container">
    <h2 class="cm-section-heading">
      {{ 'our-story.team-members.heading' | translate }}
    </h2>

    <div class="mb-[80px]">
      <div class="cm-team-members" #scrollContainer appDragToScroll>
        <app-team-member-card
          *ngFor="let teamMember of teamMembers"
          class="cm-team-member"
          [teamMember]="teamMember"
        ></app-team-member-card>
      </div>
      <div class="scroll-buttons">
        <button (click)="moveLeft()">
          <svg-icon src="assets/img/scroll-left.svg"></svg-icon>
        </button>
        <button (click)="moveRight()">
          <svg-icon src="assets/img/scroll-right.svg"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</section>
