<div class="cm-section">
  <app-hero-section
    imgPlacement="right"
    headingSize="heading"
    heading="our-story.intro.heading"
    text="our-story.intro.text"
    imgAddress="/assets/img/our-story1.svg"
    imgAlt="our-story.intro.img-alt"
  ></app-hero-section>

  <app-hero-section
    imgPlacement="left"
    headingSize="subheading"
    heading="our-story.history.heading"
    text="our-story.history.text"
    imgAddress="/assets/img/our-story2.png"
    imgAlt="our-story.history.img-alt"
  ></app-hero-section>

  <app-timeline></app-timeline>

  <app-media-article-list></app-media-article-list>

  <section *ngIf="lang === 'cs'" class="cm-section">
    <div class="cm-container">
      <app-video-player
        videoTitle="our-story.video.title"
        videoTag="our-story.video.tag"
        videoYoutubeId="yBxHtVftYSY"
        thumbnailSrc="/assets/img/Coinmate-intro-thumb.png"
      ></app-video-player>
    </div>
  </section>

  <app-team-member-list></app-team-member-list>
</div>
