import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SvgIconComponent } from 'angular-svg-icon';
import { emailRegexp } from '@app/shared/util';
import { WordpressContentApiService } from '@app/shared/services/wordpress-content-api.service';
import { ActionButtonComponent } from '@app/shared/components/form/action-button/action-button.component';
import { InputComponent } from '@app/shared/components/form/input/input.component';

enum ControlName {
  email = 'email',
}

enum ApiStateStatus {
  pending = 'pending',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

@Component({
  selector: 'app-footer-newsletter',
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
    ReactiveFormsModule,
    ActionButtonComponent,
    InputComponent,
    SvgIconComponent,
    LocalizedDatePipe,
  ],
  templateUrl: './footer-newsletter.component.html',
  styleUrls: ['../footer.component.scss', './footer-newsletter.component.scss'],
})
export class FooterNewsletterComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  protected readonly newsletterForm = new FormGroup({
    [ControlName.email]: new FormControl<string>('', [Validators.required, Validators.pattern(emailRegexp)]),
  });
  protected readonly controlNameEnum = ControlName;
  protected readonly apiStateStatusEnum = ApiStateStatus;

  formState: ApiStateStatus = ApiStateStatus.pending;
  firstAttempt = true;

  constructor(private wordpressService: WordpressContentApiService) {}

  ngOnInit() {
    this.newsletterForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.formState = ApiStateStatus.pending;
    });
  }

  submit() {
    if (this.newsletterForm.invalid || this.formState === ApiStateStatus.loading) {
      return;
    }

    const formControlEmail = this.newsletterForm.get(ControlName.email);
    if (!formControlEmail) {
      return;
    }

    const email = formControlEmail.value as string;

    this.formState = ApiStateStatus.loading;
    this.wordpressService
      .subscribeToNewsletter(email, 'footer')
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.formState = ApiStateStatus.success;
          this.firstAttempt = true;
        },
        error: () => {
          this.formState = ApiStateStatus.error;
          this.firstAttempt = false;
        },
      });
  }
}
