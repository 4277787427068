<section class="homepage-other">
  <div class="homepage-section-container">
    <h2 class="display-h2 mb-8 lg:mb-16" [innerHTML]="'home-page.other.heading' | translate"></h2>
    <div class="items">
      <div *ngFor="let other of others" class="item">
        <img [src]="'/assets/img/homepage/' + other.image" [alt]="other.alt" class="item-image" />
        <h3 class="display-h3 mb-2" [innerHTML]="other.heading | translate"></h3>
        <p class="flex flex-1 flex-col display-paragraph-large mb-4 lg:mb-8">
          {{ other.text | translate }}
        </p>
        <ng-container *ngIf="other.routerLink; else button">
          <a [routerLink]="other.routerLink | localize" class="cm-button cm-button-primary cm-button-large">
            {{ other.linkLabel | translate }}
          </a>
        </ng-container>
        <ng-template #button>
          <ng-container *ngIf="other.click">
            <button (click)="other.click()" class="cm-button cm-button-primary cm-button-large">
              {{ other.linkLabel | translate }}
            </button>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</section>
