import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import '@app/shared/components/tooltip/polyfill-wrapper';
import { TooltipPosition } from '@app/shared/components/tooltip/tooltip.enum';

const isPopoverApiSupported = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    typeof HTMLElement !== 'undefined' &&
    typeof HTMLElement.prototype === 'object' &&
    'popover' in HTMLElement.prototype
  );
};

@Component({
  selector: 'app-tooltip[text]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() text: string | null | undefined = '';
  @Input() tooltipPosition = TooltipPosition.bottom;
  @Input() usePopover = true;
  @Input() tooltipClass?: string;
  /**
   * stopEventPropagation - A custom attribute used to prevent the propagation of events
   * when the user interacts with a tooltip (e.g., mouseover or click).
   */
  @Input() disableEventPropagation? = false;

  @ViewChild('tooltipTarget') tooltipTarget!: ElementRef<HTMLElement>;
  @ViewChild('tooltipContent') tooltipContent!: ElementRef<HTMLElement>;

  private scrollSubscription!: Subscription;

  top?: number = undefined;
  left?: number = undefined;

  protected readonly tooltipPositionEnum = TooltipPosition;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.usePopover && this.hasElements() && isPopoverApiSupported()) {
      this.scrollSubscription = fromEvent(window, 'scroll')
        .pipe(filter(() => this.tooltipContent.nativeElement.style.visibility === 'visible'))
        .subscribe(() => {
          this.calculatePosition();
        });
    }
  }

  ngAfterViewInit() {
    if (this.usePopover && this.hasElements() && isPopoverApiSupported()) {
      this.tooltipTarget.nativeElement.addEventListener('mouseenter', this.showTooltip.bind(this));
      this.tooltipTarget.nativeElement.addEventListener('mouseleave', this.hideTooltip.bind(this));
      this.tooltipTarget.nativeElement.addEventListener('click', this.stopEventPropagation.bind(this));

      // @ts-ignore
      this.tooltipContent.nativeElement.popover = 'manual';

      this.tooltipContent.nativeElement.addEventListener('beforetoggle', this.onBeforeToggle.bind(this));

      this.tooltipContent.nativeElement.addEventListener('toggle', this.onToggle.bind(this));
    }
  }

  ngOnDestroy(): void {
    if (this.usePopover && this.hasElements() && isPopoverApiSupported()) {
      this.tooltipTarget.nativeElement.removeEventListener('mouseenter', this.showTooltip);
      this.tooltipTarget.nativeElement.removeEventListener('mouseleave', this.hideTooltip);
      this.tooltipContent.nativeElement.removeEventListener('beforetoggle', this.onBeforeToggle);
      this.tooltipContent.nativeElement.removeEventListener('toggle', this.onToggle);
      this.tooltipContent.nativeElement.removeEventListener('click', this.onToggle);
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  private showTooltip() {
    // @ts-ignore
    this.tooltipContent.nativeElement.showPopover();
  }

  private hideTooltip() {
    // @ts-ignore
    this.tooltipContent.nativeElement.hidePopover();
  }

  private onBeforeToggle(event: Event) {
    // @ts-ignore
    if (event.newState === 'open') {
      this.tooltipContent.nativeElement.style.visibility = 'hidden';
    }
  }

  private onToggle(event: Event) {
    // @ts-ignore
    if (event.newState === 'open') {
      this.calculatePosition();
      this.tooltipContent.nativeElement.style.visibility = 'visible';
    }
  }

  private stopEventPropagation(event: Event) {
    if (this.disableEventPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private hasElements() {
    return !!(this.tooltipTarget?.nativeElement && this.tooltipContent?.nativeElement);
  }

  private calculatePosition() {
    // In combination with Firefox and the `transform: translate()` property in CSS, it may return incorrect target coordination.
    const tooltipTargetRect = this.tooltipTarget.nativeElement.getBoundingClientRect();
    const tooltipContentRect = this.tooltipContent.nativeElement.getBoundingClientRect();

    if (this.tooltipPosition === TooltipPosition.top) {
      this.top = tooltipTargetRect.top - tooltipContentRect.height;
    }

    if (this.tooltipPosition === TooltipPosition.bottom) {
      this.top = tooltipTargetRect.bottom;
    }

    if (this.tooltipPosition === TooltipPosition.top || this.tooltipPosition === TooltipPosition.bottom) {
      this.left = tooltipTargetRect.left + (tooltipTargetRect.width - tooltipContentRect.width) / 2;
    }

    if (this.tooltipPosition === TooltipPosition.left) {
      this.left = tooltipTargetRect.left - tooltipContentRect.width;
    }

    if (this.tooltipPosition === TooltipPosition.right) {
      this.left = tooltipTargetRect.right;
    }

    if (this.tooltipPosition === TooltipPosition.left || this.tooltipPosition === TooltipPosition.right) {
      this.top = tooltipTargetRect.top + (tooltipTargetRect.height - tooltipContentRect.height) / 2;
    }
  }
}
