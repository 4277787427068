import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { ReferralClickIdModule } from '@app/unauthenticated/referral-click-id.module';
import { OurStoryRoutingModule } from '@app/unauthenticated/our-story/our-story-routing.module';
import { OurStoryComponent } from '@app/unauthenticated/our-story/our-story.component';
import { MediaArticleComponent } from '@app/unauthenticated/our-story/media-article/media-article.component';
import { MediaArticleListComponent } from '@app/unauthenticated/our-story/media-article-list/media-article-list.component';
import { TeamMemberCardComponent } from '@app/unauthenticated/our-story/team-member-card/team-member-card.component';
import { TeamMemberListComponent } from '@app/unauthenticated/our-story/team-member-list/team-member-list.component';
import { HeroSectionComponent } from '@app/unauthenticated/our-story/hero-section/hero-section.component';
import { TimelineComponent } from '@app/unauthenticated/our-story/timeline/timeline.component';
import { TimelineItemComponent } from '@app/unauthenticated/our-story/timeline-item/timeline-item.component';
import { VideoPlayerComponent } from '@app/shared/components/video-player/video-player.component';

@NgModule({
  declarations: [
    OurStoryComponent,
    MediaArticleComponent,
    MediaArticleListComponent,
    TeamMemberCardComponent,
    TeamMemberListComponent,
    HeroSectionComponent,
    TimelineComponent,
    TimelineItemComponent,
    VideoPlayerComponent,
  ],
  imports: [CommonModule, OurStoryRoutingModule, TranslateModule, SharedModule, ReferralClickIdModule],
  exports: [VideoPlayerComponent],
})
export class OurStoryModule {}
