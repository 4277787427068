<section class="homepage-section-container">
  <h2 class="display-h2 mb-8 lg:mb-16">{{ 'home-page.media.heading' | translate }}</h2>
  <div class="items">
    <ng-container *ngFor="let link of links">
      <div class="item">
        <ng-container *ngIf="lang === 'cs'; else image">
          <a [href]="link.href" target="_blank" rel="noopener noreferrer">
            <ng-container *ngTemplateOutlet="image"></ng-container>
          </a>
        </ng-container>

        <ng-template #image>
          <img
            [src]="'/assets/img/homepage/media/' + colorScheme + '/' + link.image"
            [alt]="link.alt"
            class="item-image"
          />
        </ng-template>
      </div>
    </ng-container>
  </div>
</section>
