<section class="home-page-container">
  <h2 class="display-h2 mb-8 lg:mb-16">{{ 'home-page.education.heading' | translate }}</h2>
  <div class="max-w-[56ch] mx-auto mb-8 display-paragraph-blog">{{ 'home-page.education.text' | translate }}</div>
  <a href="https://coinmate.io/cz/kryptomeny/" class="cm-button cm-button-primary cm-button-large">
    {{ 'home-page.education.explore' | translate }}
  </a>

  <div class="items">
    <div *ngFor="let article of articles; index as i" class="item">
      <ng-container *ngIf="article; else skeletonLoader">
        <h3 class="display-h4 font-semibold mb-4 lg:mb-2" [innerHTML]="categoryTitles[i] | translate"></h3>
        <a [href]="article.link" class="item-link" target="_blank" rel="noopener noreferrer">
          <div class="item-image">
            <img *ngIf="article.image; else imageFallback" [src]="article.image.url" [alt]="article.image.alt" />
          </div>
          <span class="display-paragraph font-semibold" [innerHTML]="article.title"></span>
        </a>
      </ng-container>
    </div>
  </div>

  <ng-template #imageFallback>
    <div class="item-image-fallback"></div>
  </ng-template>

  <ng-template #skeletonLoader>
    <h3 class="w-4/5 display-h4 font-semibold mb-4 lg:mb-2">
      <app-skeleton variant="text"></app-skeleton>
    </h3>
    <div class="w-full">
      <div class="item-image">
        <app-skeleton class="item-image-skeleton" height="100%" variant="rectangular"></app-skeleton>
      </div>
      <div class="w-2/3 display-paragraph font-semibold">
        <app-skeleton variant="text"></app-skeleton>
      </div>
    </div>
  </ng-template>
</section>
