import { Component, Input } from '@angular/core';

@Component({
  selector: 'homepage-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  @Input() lang = 'cs';
}
