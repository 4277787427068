<section class="homepage-register">
  <div class="homepage-section-container">
    <div
      class="flex flex-col md:flex-row md:gap-x-16 lg:gap-20 xl:gap-x-28 items-center md:items-start justify-center lg:justify-start"
    >
      <div>
        <h2 class="display-h2 mb-8 md:mb-0 md:max-w-[12ch]" [innerHTML]="'home-page.register.heading' | translate"></h2>
      </div>
      <div class="lg:pt-2">
        <div
          class="max-w-[36ch] mb-8 display-paragraph-large"
          [innerHTML]="'home-page.register.text' | translate"
        ></div>
        <div class="flex flex-col items-center md:items-start">
          <a [routerLink]="'/sign-up' | localize" class="cm-button cm-button-primary cm-button-large">
            {{ 'home-page.register.button' | translate }}
          </a>
          <div class="flex items-end display-caption mt-4" *ngIf="lang === 'cs'">
            <span class="leading-none">{{ 'home-page.intro.bank-id' | translate }}</span
            >&nbsp;<img src="/assets/icons/bank-id-white.svg" alt="Bank iD" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
