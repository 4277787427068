<ng-container *ngIf="selectedPair">
  <div class="currency-pair-selector">
    {{ 'currency-pair' | translate }}
    <app-action-button [size]="'xs'" (action)="openModal()">
      <app-currency-pair-icon
        class="text-large"
        [firstCurrencyName]="selectedPair.firstCurrency"
        [secondCurrencyName]="selectedPair.secondCurrency"
      ></app-currency-pair-icon>
      {{ selectedPair.name | appCurrencyPairDisplay | async }}
    </app-action-button>
  </div>
</ng-container>
