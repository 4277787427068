<span
  class="ribbon"
  [ngClass]="{
    'ribbon-warning': type === 'warning',
  }"
>
  <ng-content select="[ribbon-icon-left]"></ng-content>
  <ng-content></ng-content>
  <ng-content select="[ribbon-icon-right]"></ng-content>
</span>
