<section class="homepage-section-container">
  <div class="items">
    <div class="item">
      <div class="item-column">
        <h2
          class="display-h2 mb-8 lg:mb-16 md:max-w-[16ch]"
          [innerHTML]="'home-page.tutorial.heading' | translate"
        ></h2>
        <div class="w-full flex flex-1 flex-col gap-8 items-center lg:items-start">
          <div class="flex flex-1 flex-col gap-3 display-paragraph-large">
            <p class="max-w-[38ch]" [innerHTML]="'home-page.tutorial.text-1' | translate"></p>
            <p class="max-w-[38ch]" [innerHTML]="'home-page.tutorial.text-2' | translate"></p>
          </div>
          <button class="cm-button cm-button-primary cm-button-large" (click)="activateTutorial()">
            {{ 'home-page.tutorial.play-video' | translate }}
          </button>
        </div>
      </div>
      <div class="item-column">
        <app-accordion
          class="accordion-video"
          (opened)="onAccordionItemOpened($event)"
          (closed)="onAccordionItemClosed($event)"
        >
          <div [expanded]="activeAccordionItemIndex === 0" app-accordion-item>
            <div app-accordion-header>
              <span class="accordion-video-header">{{ 'home-page.tutorial.heading-video-1' | translate }}</span>
            </div>
            <app-video-player
              [videoYoutubeId]="videos[0].videoYoutubeId"
              [thumbnailSrc]="videos[0].thumbnailSrc"
              [size]="'small'"
              #firstVideo
            ></app-video-player>
          </div>
          <div app-accordion-item>
            <div app-accordion-header>
              <span class="accordion-video-header">{{ 'home-page.tutorial.heading-video-2' | translate }}</span>
            </div>
            <app-video-player
              [videoYoutubeId]="videos[1].videoYoutubeId"
              [thumbnailSrc]="videos[1].thumbnailSrc"
              [size]="'small'"
              #secondVideo
            ></app-video-player>
          </div>
        </app-accordion>
      </div>
    </div>
  </div>
</section>
