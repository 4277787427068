import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AccordionItemComponent } from '@app/shared/components/accordion/accordion-item/accordion-item.component';
import { VideoPlayerComponent } from '@app/shared/components/video-player/video-player.component';

const VIDEO_IDS_CS = ['J3F3kNs3NFs', 'JSAS05nlnTE'];

const VIDEO_IDS_EN = ['SpAYbcW9TtA', 'OYWE2Pb63oQ'];

@Component({
  selector: 'homepage-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit, OnChanges {
  @Input() lang = 'cs';

  @ViewChild('firstVideo') firstVideoRef!: VideoPlayerComponent;
  @ViewChild('secondVideo') secondVideoRef!: VideoPlayerComponent;

  videos: {
    videoYoutubeId: string;
    thumbnailSrc: string;
  }[] = [];
  activeAccordionItemIndex: number | undefined = 0;

  ngOnInit() {
    this.createVideos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang) {
      this.activeAccordionItemIndex = 0;
      this.createVideos();
    }
  }

  private createVideos() {
    const videoIDs = this.lang === 'cs' ? VIDEO_IDS_CS : VIDEO_IDS_EN;
    this.videos = videoIDs.map((videoId) => ({
      videoYoutubeId: videoId,
      thumbnailSrc: `//i.ytimg.com/vi/${videoId}/hqdefault.jpg`,
    }));
  }

  activateTutorial() {
    if (this.activeAccordionItemIndex !== 0) {
      const activeAccordionItemVideo = this.getActiveVideoRef(this.activeAccordionItemIndex);
      if (activeAccordionItemVideo) {
        activeAccordionItemVideo.stop();
      }
    }

    this.activeAccordionItemIndex = 0;

    if (this.firstVideoRef) {
      this.firstVideoRef.play();
    }
  }

  onAccordionItemOpened(item: AccordionItemComponent) {
    this.activeAccordionItemIndex = item.getIndex();
  }

  onAccordionItemClosed(item: AccordionItemComponent) {
    const accordionItemIndex = item.getIndex();
    const accordionItemVideo = this.getActiveVideoRef(accordionItemIndex);
    if (accordionItemVideo) {
      accordionItemVideo.stop();
    }
    if (this.activeAccordionItemIndex === accordionItemIndex) {
      this.activeAccordionItemIndex = undefined;
    }
  }

  private getActiveVideoRef(index: number | undefined) {
    switch (index) {
      case 0:
        return this.firstVideoRef;
      case 1:
        return this.secondVideoRef;
      default:
        return null;
    }
  }
}
