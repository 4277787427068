<h2 class="cm-footer-heading">{{ 'shared.footer.newsletter.title' | translate }}</h2>
<form class="footer-newsletter" [formGroup]="newsletterForm" (ngSubmit)="submit()">
  <app-input
    [size]="'small'"
    [placeholder]="'shared.common.email-placeholder' | translate"
    [formControlName]="controlNameEnum.email"
    [hasSuccess]="formState === apiStateStatusEnum.success"
    [hasError]="formState === apiStateStatusEnum.error"
    type="email"
  >
    <svg-icon src="/assets/icons/16/alternate-email.svg" app-input-addons-left-inside></svg-icon>
  </app-input>
  <div *ngIf="formState === apiStateStatusEnum.success" class="footer-newsletter-alert-success">
    <svg-icon src="/assets/icons/16/checkmark-circle.svg"></svg-icon>
    {{ 'shared.newsletter.success' | translate }}
  </div>
  <div *ngIf="formState === apiStateStatusEnum.error" class="footer-newsletter-alert-error">
    <svg-icon src="/assets/icons/16/warning.svg"></svg-icon>
    {{ 'shared.newsletter.error' | translate }}
  </div>
  <app-action-button
    *ngIf="formState !== apiStateStatusEnum.success"
    [facet]="'primary'"
    [size]="'small'"
    [isFullWidth]="true"
    [label]="(firstAttempt ? 'shared.newsletter.subscribe-long' : 'shared.newsletter.subscribe-again') | translate"
    [disabled]="newsletterForm.invalid"
    [waiting]="formState === apiStateStatusEnum.loading"
    type="submit"
  ></app-action-button>
</form>
