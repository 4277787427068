<button
  class="flex items-center"
  [appTooltip]="(copied ? 'shared.common.copy-to-clipboard-done' : 'shared.common.copy-to-clipboard') | translate"
>
  <svg-icon
    class="copyIcon"
    [src]="copied ? '/assets/icons/icon-copied.svg' : '/assets/icons/icon-copy.svg'"
    (click)="copyText()"
  >
  </svg-icon>
</button>
