import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Horizontal drag to scroll directive. Sets 'active' class while dragging.
 */
@Directive({
  selector: '[appDragToScroll]',
})
export class DragToScrollDirective implements AfterViewInit {
  @Input() scrollSpeed = 1;
  el: HTMLElement | undefined;

  startX = 0;
  scrollLeft = 0;
  isDown = false;

  constructor(private element: ElementRef, private changeDetector: ChangeDetectorRef) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    // only left mouse dragging counts
    if (event.button !== 0 || !this.el) return;

    this.changeDetector.detach();
    this.isDown = true;
    this.el.classList.add('active');

    this.startX = event.pageX - this.el.offsetLeft;
    this.scrollLeft = this.el.scrollLeft;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDown || !this.el) return;

    const x = event.pageX - this.el.offsetLeft;
    const movement = (x - this.startX) * this.scrollSpeed;
    this.el.scrollLeft = this.scrollLeft - movement;
    return false;
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  onMouseUp() {
    if (!this.el) return;
    if (this.isDown) {
      this.changeDetector.reattach();
    }

    this.isDown = false;
    this.el.classList.remove('active');
  }

  ngAfterViewInit() {
    this.el = this.element.nativeElement;
  }
}
