<div class="table-wrapper {{ isMinimal && 'minimal' }}">
  <ng-container *ngIf="currencyData$ | async as currencyData">
    <table appTable [dataSource]="currencyData" class="w-full currencies-table" [trackBy]="trackByItemId">
      <caption caption class="sr-only">
        {{
          'currencies.table.caption' | translate
        }}
      </caption>

      <ng-container appColumnDef="icon">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">&nbsp;</th>
        <td appCell *appCellDef="let data" class="desktop-only">
          <ng-container *ngIf="data; else skeletonLoaderColumnIcon">
            <a *ngIf="lang === 'cs'" class="currency-link" href="{{ data.url }}">
              <app-currency-icon
                class="block max-w-[25px]"
                [currencyName]="data.code"
                [alt]="data.name"
              ></app-currency-icon>
            </a>
            <span *ngIf="lang === 'en'">
              <app-currency-icon
                class="block max-w-[25px]"
                [currencyName]="data.code"
                [alt]="data.name"
              ></app-currency-icon>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="name">
        <th appHeaderCell *appHeaderCellDef>{{ 'currencies.table.header.currency' | translate }}</th>
        <td appCell *appCellDef="let data">
          <ng-container *ngIf="data; else skeletonLoaderColumnName">
            <div class="flex flex-col gap-y-4px">
              <a *ngIf="lang === 'cs'" class="currency-link" href="{{ data.url }}">
                <app-currency-icon
                  class="inline-block relative top-[-4px] max-w-[25px]"
                  [ngClass]="{
                    'md:hidden': !isMinimal
                  }"
                  [currencyName]="data.code"
                  [alt]="data.name"
                ></app-currency-icon>
                <span class="inline-block relative md:top-0 {{ !isMinimal && 'md:ml-0' }} ml-[12px] top-[3px]">
                  <strong class="hover:underline">{{ data.name }}</strong>
                  <span class="block w-full text-[14px] font-normal" [ngClass]="{ 'md:!hidden': !isMinimal }">{{
                    data.code
                  }}</span>
                </span>
              </a>
              <span *ngIf="lang === 'en'">
                <app-currency-icon
                  class="inline-block relative top-[-4px] max-w-[25px]"
                  [ngClass]="{
                    'md:hidden': !isMinimal
                  }"
                  [currencyName]="data.code"
                  [alt]="data.name"
                ></app-currency-icon>
                <span class="inline-block relative md:top-0 ml-[12px] top-[3px]" [ngClass]="{ 'md:ml-0': !isMinimal }">
                  <strong>{{ data.name }}</strong>
                  <span class="block w-full text-[14px] font-normal" [ngClass]="{ 'md:!hidden': !isMinimal }">{{
                    data.code
                  }}</span>
                </span>
              </span>
              <app-currency-event-ribbon *ngIf="!isMinimal" [currency]="data.currency" />
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="currency">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">&nbsp;</th>
        <td appCell *appCellDef="let data" class="desktop-only font-normal !text-[14px]">
          <ng-container *ngIf="data; else skeletonLoaderColumnCurrency">
            <a *ngIf="lang === 'cs'" class="currency-link" href="{{ data.url }}">
              {{ data.code }}
            </a>
            <span *ngIf="lang === 'en'">
              {{ data.code }}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="price">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">
          <span>{{ 'currencies.table.header.price' | translate }}</span>
          <form class="!inline-block ml-[10px]">
            <label for="currency" class="sr-only">{{ 'currencies.table.header.price' | translate }}</label>
            <select
              name="currency"
              id="currency"
              class="cm-currencies-select"
              (change)="onChangeCurrency($event)"
              [value]="selectedCurrency$ | async"
            >
              <option value="CZK">{{ 'currencies.units.CZK' | translate }}</option>
              <option value="EUR">{{ 'currencies.units.EUR' | translate }}</option>
            </select>
          </form>
        </th>
        <td appCell *appCellDef="let data" class="desktop-only">
          <ng-container *ngIf="data; else skeletonLoaderColumnPrice">
            <ng-container
              *ngIf="
                getCurrencyEventByType(data.currency, CurrencyEventType.Listing) as currencyEventListing;
                else priceTemplate
              "
            >
              <span *ngIf="!isMinimal" class="text-dimmed text-sm whitespace-nowrap">
                {{
                  'currencies.event.listing.countdown'
                    | translate : { date: (currencyEventListing.downtimeTo | appCountdown | async) }
                }}
              </span>
            </ng-container>
            <ng-template #priceTemplate>
              <app-currency-value
                [value]="data.price"
                [currency]="data.priceCurrency"
                [hideCurrency]="true"
              ></app-currency-value>
              {{ 'currencies.units.' + data.priceCurrency | translate }}
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="change">
        <th appHeaderCell *appHeaderCellDef class="mobile-last">
          <span>{{ 'currencies.table.header.change' | translate }}</span>
          <form class="!inline-block md:!hidden ml-[20px]">
            <label for="currency-mobile" class="sr-only">{{ 'currencies.table.header.price' | translate }}</label>
            <select
              name="currency"
              id="currency-mobile"
              class="cm-currencies-select no-bg"
              (change)="onChangeCurrency($event)"
              [value]="selectedCurrency$ | async"
            >
              <option value="CZK">{{ 'currencies.units.CZK' | translate }}</option>
              <option value="EUR">{{ 'currencies.units.EUR' | translate }}</option>
            </select>
          </form>
        </th>
        <td appCell *appCellDef="let data" class="mobile-last">
          <ng-container *ngIf="data; else skeletonLoaderColumnChange">
            <ng-container
              *ngIf="
                getCurrencyEventByType(data.currency, CurrencyEventType.Listing) as currencyEventListing;
                else priceTemplate
              "
            >
              <span *ngIf="!isMinimal" class="text-dimmed text-sm whitespace-nowrap" [ngClass]="'md:!hidden'">
                {{
                  'currencies.event.listing.countdown'
                    | translate : { date: (currencyEventListing.downtimeTo | appCountdown | async) }
                }}
              </span>
            </ng-container>
            <ng-template #priceTemplate>
              <strong class="block w-full !text-right" [ngClass]="{ 'md:!hidden': !isMinimal }">
                <app-currency-value
                  [value]="data.price"
                  [currency]="data.priceCurrency"
                  [hideCurrency]="true"
                ></app-currency-value>
                {{ 'currencies.units.' + data.priceCurrency | translate }}
              </strong>
              <app-colored-percent-change-text
                class="{{
                  isMinimal && '!text-right'
                }} block font-bold text-[14px] lg:text-[16px] text-right md:text-left"
                [change]="data.change"
              ></app-colored-percent-change-text>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <ng-container appColumnDef="buy">
        <th appHeaderCell *appHeaderCellDef class="desktop-only">&nbsp;</th>
        <td
          appCell
          *appCellDef="let data"
          class="text-right desktop-only minimal-show"
          [ngClass]="{
            'w-[55px]': isMinimal,
            'w-[80px]': !isMinimal
          }"
        >
          <ng-container *ngIf="data; else skeletonLoaderButtonBuy">
            <ng-container *ngIf="data.status !== CurrencyPairStatus.Break">
              <app-logged-button
                appearance="cm-currency-buy"
                text="currencies.table.purchase"
                addressHref="/quick-trade"
                link="/sign-up"
              >
                <img
                  src="/assets/img/arrow-right-blue.svg"
                  alt="{{ 'currencies.table.purchase' | translate }}"
                  *ngIf="!isMinimal"
                />
              </app-logged-button>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr appHeaderRow *appHeaderRowDef="displayedColumns"></tr>
      <tr appRow *appRowDef="let data; columns: displayedColumns"></tr>
    </table>

    <ng-container *ngIf="isMinimal">
      <div class="mt-6 text-center">
        <img
          ngSrc="/assets/img/new-coins.svg"
          class="mx-auto mb-3"
          [alt]="'currencies.new.text' | translate"
          height="27"
          width="89"
        />
        <p>
          <strong>{{ 'currencies.new.title' | translate }}</strong
          ><br />
          {{ 'currencies.new.text' | translate }}
        </p>
      </div>

      <div *ngIf="!isDataEmpty(currencyData); else skeletonLoaderButtonMore" class="flex justify-center">
        <app-link-pair
          routerLink="/prices"
          addressLabel="{{ 'currencies.more' | translate }}"
          addressIcon="/assets/img/arrow-right-blue.svg"
          appearance="cm-paragraph !mb-0 mt-[16px]"
        ></app-link-pair>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #skeletonLoaderColumnIcon>
    <div class="skeleton-loader-column-icon">
      <app-skeleton class="" width="25px" height="25px" variant="circular"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnPrice>
    <div class="skeleton-loader-column-price">
      <app-skeleton></app-skeleton>
      <app-skeleton *ngIf="isMinimal"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnChange>
    <div class="skeleton-loader-column-change">
      <app-skeleton></app-skeleton>
      <app-skeleton [ngClass]="{ 'md:!hidden': !isMinimal }"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnName>
    <div class="skeleton-loader-column-name">
      <div [ngClass]="{ 'md:!hidden': !isMinimal }">
        <app-skeleton width="25px" height="25px" variant="circular"></app-skeleton>
      </div>
      <div class="skeleton-loader-column-name-items">
        <app-skeleton></app-skeleton>
        <app-skeleton [ngClass]="{ 'md:!hidden': !isMinimal }"></app-skeleton>
      </div>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderColumnCurrency>
    <div class="skeleton-loader-column-currency">
      <app-skeleton></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderButtonBuy>
    <div class="skeleton-loader-button-buy">
      <app-skeleton></app-skeleton>
    </div>
  </ng-template>

  <ng-template #skeletonLoaderButtonMore>
    <div class="skeleton-loader-button-more">
      <app-skeleton></app-skeleton>
    </div>
  </ng-template>
</div>
