import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-media-article',
  templateUrl: './media-article.component.html',
  styleUrls: ['./media-article.component.scss'],
})
export class MediaArticleComponent {
  @Input() link = '#';
  @Input() imgSrc = '';
  @Input() imgAlt = '';
  @Input() source = '';
  @Input() heading = '';
  @Input() text = '';
}
