<div [formGroup]="parent">
  <app-input
    [forceAutofocus]="forceAutofocus || false"
    type="email"
    label="{{ label || 'shared.common.email-address-placeholder' }}"
    placeholder="{{ placeholder }}"
    autocapitalize="characters"
    formControlName="{{ formControlName || 'email' }}"
    errorMessage="{{ errorMessage || 'error.email-invalid' }}"
    [isValid]="email?.valid || email?.pristine"
    [size]="size"
  ></app-input>
</div>
