export interface TeamMemberInfo {
  name: string;
  photo: string;
  shortDescription: string;
  longDescription: string;
  socials: SocialNetworkLink[];
}

export interface SocialNetworkLink {
  name: SocialNetwork;
  link: string;
}

export type SocialNetwork = 'LinkedIn' | 'Twitter';

export const teamMembers: TeamMemberInfo[] = [
  {
    name: 'Roman Valihrach',
    photo: '/assets/img/team-members/roman-valihrach.png',
    shortDescription: 'our-story.team-members.roman-valihrach.short-description',
    longDescription: 'our-story.team-members.roman-valihrach.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/romanvalihrach/',
      },
      {
        name: 'Twitter',
        link: 'https://twitter.com/RValihrach',
      },
    ],
  },
  {
    name: 'Nikos Balamotis',
    photo: '/assets/img/team-members/nikos-balamotis.png',
    shortDescription: 'our-story.team-members.nikos-balamotis.short-description',
    longDescription: 'our-story.team-members.nikos-balamotis.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/nikos-balamotis-90a0596',
      },
    ],
  },
  {
    name: 'Aleš Krempa',
    photo: '/assets/img/team-members/ales-krempa.png',
    shortDescription: 'our-story.team-members.ales-krempa.short-description',
    longDescription: 'our-story.team-members.ales-krempa.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/ales-krempa-b7b5a584',
      },
    ],
  },
  {
    name: 'Petr Bezděka',
    photo: '/assets/img/team-members/petr-bezdeka.png',
    shortDescription: 'our-story.team-members.petr-bezdeka.short-description',
    longDescription: 'our-story.team-members.petr-bezdeka.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/petr-bezd%C4%9Bka-b5aa1b18a/',
      },
    ],
  },
  {
    name: 'Ondřej Steklý',
    photo: '/assets/img/team-members/ondrej-stekly.png',
    shortDescription: 'our-story.team-members.ondrej-stekly.short-description',
    longDescription: 'our-story.team-members.ondrej-stekly.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/ond%C5%99ej-stekl%C3%BD-79668523a/',
      },
    ],
  },
  {
    name: 'Markéta Dubová',
    photo: '/assets/img/team-members/marketa-dubova.png',
    shortDescription: 'our-story.team-members.marketa-dubova.short-description',
    longDescription: 'our-story.team-members.marketa-dubova.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/marketadubova/',
      },
    ],
  },
  {
    name: 'Jan Šobora',
    photo: '/assets/img/team-members/jan-sobora.png',
    shortDescription: 'our-story.team-members.jan-sobora.short-description',
    longDescription: 'our-story.team-members.jan-sobora.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/jan-%C5%A1obora-894b0382/',
      },
    ],
  },
  {
    name: 'Linda Rakušanová',
    photo: '/assets/img/team-members/linda-rakusanova.png',
    shortDescription: 'our-story.team-members.linda-rakusanova.short-description',
    longDescription: 'our-story.team-members.linda-rakusanova.long-description',
    socials: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/linda-raku%C5%A1anov%C3%A1-098108144/',
      },
    ],
  },
];
