<ng-container *ngFor="let currencyEvent of currencyEvents">
  <ng-container [ngSwitch]="currencyEvent.type">
    <ng-container *ngSwitchCase="currencyEventTypeEnum.Listing">
      <ng-container [ngSwitch]="currency.name">
        <ng-container *ngSwitchCase="'USDC'">
          <ng-container *ngTemplateOutlet="listingUSDC"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="currencyEvent.downtimeTo | appCountdown | async as countdown">
            <app-ribbon>
              <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
              {{ 'currencies.event.listing.label' | translate : { date: countdown } }}
            </app-ribbon>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="currencyEventTypeEnum.Delisting">
      <app-ribbon [appRichTooltip]="delistingTooltipTemplate" [stopEventPropagation]="true">
        <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
        {{ 'currencies.event.delisting.label' | translate }}
      </app-ribbon>
    </ng-container>
    <ng-container *ngSwitchCase="currencyEventTypeEnum.DelistingCurrency">
      <ng-container [ngSwitch]="currency.name">
        <ng-container *ngSwitchCase="'USDT'">
          <ng-container *ngTemplateOutlet="delistingUSDT"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="currencyEvent.downtimeFrom | appCountdown | async as countdown">
            <app-ribbon [type]="'warning'">
              <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
              {{ 'currencies.event.delisting-currency.label' | translate : { date: countdown } }}
            </app-ribbon>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #delistingTooltipTemplate>
    <ng-container *ngIf="currencyEvent.currencyPair as currencyEventPair">
      <app-rich-tooltip-header>
        {{ 'currencies.event.delisting.title' | translate }}
      </app-rich-tooltip-header>
      <app-rich-tooltip-body>
        <div
          [innerHTML]="
            'currencies.event.delisting.message'
              | translate
                : {
                    currencyPair: currencyEventPair | appCurrencyPairDisplay | async,
                    currency: currencyEvent.currencyName,
                    date: currencyEvent.downtimeFrom | localizedDate,
                    url: 'konec-usdt-v-evrope'
                  }
          "
        ></div>
      </app-rich-tooltip-body>
      <app-rich-tooltip-footer></app-rich-tooltip-footer>
    </ng-container>
  </ng-template>

  <ng-template #listingUSDC>
    <ng-container *ngIf="currencyEvent.downtimeTo | appCountdown | async as countdown">
      <app-ribbon [appRichTooltip]="listingTooltipTemplate" [stopEventPropagation]="true">
        <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
        {{ 'currencies.event.listing.label' | translate : { date: countdown } }}
      </app-ribbon>
      <ng-template #listingTooltipTemplate>
        <app-rich-tooltip-header>
          {{ 'currencies.event.listing-usdc.title' | translate }}
        </app-rich-tooltip-header>
        <app-rich-tooltip-body>
          <p>{{ 'currencies.event.listing-usdc.description-1' | translate }}</p>
          <p>{{ 'currencies.event.listing-usdc.description-2' | translate }}</p>
          <p>{{ 'currencies.event.listing-usdc.list-title' | translate }}</p>
          <ul>
            <li [innerHTML]="'currencies.event.listing-usdc.list-item-1' | translate"></li>
            <li [innerHTML]="'currencies.event.listing-usdc.list-item-2' | translate"></li>
            <li [innerHTML]="'currencies.event.listing-usdc.list-item-3' | translate"></li>
            <li [innerHTML]="'currencies.event.listing-usdc.list-item-4' | translate"></li>
            <li [innerHTML]="'currencies.event.listing-usdc.list-item-5' | translate"></li>
          </ul>
        </app-rich-tooltip-body>
        <app-rich-tooltip-footer></app-rich-tooltip-footer>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #delistingUSDT>
    <ng-container *ngIf="currencyEvent.downtimeFrom | appCountdown | async as countdown">
      <app-ribbon [appRichTooltip]="delistingUSDTTooltipTemplate" [stopEventPropagation]="true" [type]="'warning'">
        <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
        {{ 'currencies.event.delisting-currency.label' | translate : { date: countdown } }}
      </app-ribbon>
      <ng-template #delistingUSDTTooltipTemplate>
        <app-rich-tooltip-header>
          {{ 'currencies.event.delisting-usdt.title' | translate }}
        </app-rich-tooltip-header>
        <app-rich-tooltip-body>
          <p [innerHTML]="'currencies.event.delisting-usdt.description' | translate"></p>
          <p>{{ 'currencies.event.delisting-usdt.list-title' | translate }}</p>
          <ul>
            <li [innerHTML]="'currencies.event.delisting-usdt.list-item-1' | translate"></li>
            <li [innerHTML]="'currencies.event.delisting-usdt.list-item-2' | translate"></li>
            <li [innerHTML]="'currencies.event.delisting-usdt.list-item-3' | translate"></li>
            <li [innerHTML]="'currencies.event.delisting-usdt.list-item-4' | translate"></li>
            <li [innerHTML]="'currencies.event.delisting-usdt.list-item-5' | translate"></li>
          </ul>
        </app-rich-tooltip-body>
        <app-rich-tooltip-footer></app-rich-tooltip-footer>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>
