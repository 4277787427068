import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  of,
  pairwise,
  switchMap,
} from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { DateTime, Duration, DurationUnit } from 'luxon';
import { style, transition, trigger, animate } from '@angular/animations';
import { TranslatePipe } from '@ngx-translate/core';
import { Language, LanguageService } from '@app/shared/services/language.service';
import { CurrencyIconComponent } from '@app/shared/components/currency-icon/currency-icon.component';
import { AppCurrencyPairDisplayPipe } from '@app/shared/pipes/app-currency-pair-display.pipe';
import { CurrencyDto } from '@app/generated/models/currency-dto';

type CountdownParts = { days: string; hours: string; minutes: string; seconds: string };

const COUNTDOWN_ANIMATION_DURATION = 250;
const COUNTDOWN_ANIMATION = [
  style({ transform: 'translateY(0)' }),
  animate(
    `${COUNTDOWN_ANIMATION_DURATION}ms cubic-bezier(0.32, 0, 0.67, 0)`,
    style({ transform: 'translateY(-100%)' }),
  ),
];

@Component({
  selector: 'app-currency-event-countdown',
  standalone: true,
  imports: [CommonModule, TranslatePipe, CurrencyIconComponent, AppCurrencyPairDisplayPipe],
  templateUrl: './currency-event-countdown.component.html',
  styleUrl: './currency-event-countdown.component.scss',
  animations: [
    trigger('slideUp', [transition(':increment', COUNTDOWN_ANIMATION), transition(':decrement', COUNTDOWN_ANIMATION)]),
  ],
})
export class CurrencyEventCountdownComponent {
  @Input('date') set date(date: string | undefined) {
    const dateLuxon = date ? DateTime.fromISO(date) : DateTime.now();
    this.$dateLuxon.next(dateLuxon);
  }
  @Input() currencyName!: CurrencyDto['name'];
  @Input() title!: string;
  @Input() description!: string;
  @Output() countdownCompleted = new EventEmitter();

  private $dateLuxon = new BehaviorSubject<DateTime>(DateTime.now());
  private languageService = inject(LanguageService);
  private destroyRef = inject(DestroyRef);

  timeLeft$: Observable<{
    previous: CountdownParts;
    current: CountdownParts;
    units: CountdownParts;
  }> = combineLatest([this.$dateLuxon, this.languageService.currentLanguage$]).pipe(
    switchMap(([date, currentLanguage]: [DateTime, Language]) => {
      if (!date.isValid || date.diffNow().valueOf() <= 0) {
        const diff = DateTime.now().diffNow(['day', 'hour', 'minute', 'second']);
        return of({
          previous: this.getCountdownParts(diff),
          current: this.getCountdownParts(diff),
          units: this.getCountdownUnits(diff, currentLanguage),
        });
      }

      return interval(COUNTDOWN_ANIMATION_DURATION, animationFrameScheduler).pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => date.diffNow(['day', 'hour', 'minute', 'second'])),
        tap((diff: Duration) => {
          if (diff.valueOf() <= 0 && this.countdownCompleted) {
            this.countdownCompleted.emit();
          }
        }),
        takeWhile((diff: Duration) => diff.valueOf() > 0),
        pairwise(),
        map(([previousDiff, currentDiff]) => ({
          previous: this.getCountdownParts(previousDiff),
          current: this.getCountdownParts(currentDiff),
          units: this.getCountdownUnits(currentDiff, currentLanguage),
        })),
      );
    }),
  );

  private formatValue(value: number) {
    return String(Math.floor(value)).padStart(2, '0');
  }

  private getCountdownParts(value: Duration) {
    return {
      days: this.formatValue(value.days),
      hours: this.formatValue(value.hours),
      minutes: this.formatValue(value.minutes),
      seconds: this.formatValue(value.seconds),
    };
  }

  private getDurationUnit(value: number, unit: DurationUnit, locale: Language) {
    return Duration.fromObject({
      [unit]: value,
    })
      .reconfigure({ locale })
      .toHuman()
      .split(' ')[1];
  }

  private getCountdownUnits(value: Duration, locale: Language) {
    return {
      days: this.getDurationUnit(value.get('days'), 'days', locale),
      hours: this.getDurationUnit(value.get('hours'), 'hours', locale),
      minutes: this.getDurationUnit(value.get('minutes'), 'minutes', locale),
      seconds: this.getDurationUnit(value.get('seconds'), 'seconds', locale),
    };
  }
}
