import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { LanguageService } from '@app/shared/services/language.service';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';

export enum Locales {
  en = 'en-US',
  cs = 'cs-CZ',
}

@Injectable({
  providedIn: 'root',
})
export class UserInfoProfileService implements OnDestroy {
  numberLocale$ = new BehaviorSubject<Locales>(Locales.en);

  private unsubscribe$ = new Subject<void>();

  constructor(private guiParams: GuiParams, private languageService: LanguageService, private userInfo: UserInfo) {
    this.guiParams.loggedIn$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((isLoggedIn): Observable<keyof typeof Locales> => {
          if (isLoggedIn) {
            return this.userInfo.userInfoObservable$.pipe(
              switchMap((userInfo) => {
                if (userInfo?.profile?.locale) {
                  return of(userInfo.profile.locale.numberFormat.toLowerCase() as keyof typeof Locales);
                }

                return this.languageService.currentLanguage$;
              }),
            );
          }

          return this.languageService.currentLanguage$;
        }),
        distinctUntilChanged(),
        map((language: keyof typeof Locales) => Locales[language]),
      )
      .subscribe((currentLanguage) => {
        this.numberLocale$.next(currentLanguage);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
