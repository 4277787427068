<app-base-modal [id]="modalId" [title]="'balances' | translate" [size]="'large'" [isBodyZeroSpacing]="true">
  <ng-container modal-header>
    <app-currencies-price-update-button
      *ngIf="lastUpdate"
      [date]="lastUpdate"
      (click)="refreshData()"
    ></app-currencies-price-update-button>
    <app-search
      [searchPlaceholder]="'quick-trade.main.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    ></app-search>
  </ng-container>
  <app-base-modal-body>
    <ng-container *ngIf="currenciesLimited$ | async as currencies">
      <ng-container *ngIf="currencies.length; else noData">
        <table class="table-auto w-full">
          <thead>
            <tr class="currencies-table-row">
              <th *ngFor="let header of headers; let isLast = last" class="currencies-table-cell">
                <div class="inline-flex items-center gap-x-6px">
                  {{ header | translate }}
                  <ng-container *ngIf="isLast">
                    <ng-container *ngTemplateOutlet="priceUpdateInfo"></ng-container>
                  </ng-container>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="currencies-table-row" *ngFor="let currency of currencies; trackBy: trackByItemId">
              <td class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="flex items-center gap-x-8px md:gap-x-20px">
                    <app-currency-icon class="h-8 w-8" [currencyName]="currency.name" [alt]="currency.displayName" />
                    <div class="flex flex-col gap-y-4px">
                      <div class="flex flex-col gap-y-2px">
                        <div class="font-bold">
                          {{ getCurrencyDisplayName(currency.name) }}
                        </div>
                        <div class="flex flex-col gap-8px md:flex-row">
                          <span class="text-dimmed text-sm">
                            {{ currency.name }}
                          </span>
                        </div>
                      </div>
                      <div class="hidden md:flex md:flex-wrap md:gap-[8px]">
                        <app-currency-event-ribbon [currency]="currency" />
                        <ng-container
                          *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"
                        ></ng-container>
                        <ng-container
                          *ngTemplateOutlet="tradingSuspension; context: { $implicit: currency }"
                        ></ng-container>
                        <ng-container *ngTemplateOutlet="tokenUpdate; context: { $implicit: currency }"></ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap gap-8px md:hidden">
                    <app-currency-event-ribbon [currency]="currency" />
                    <ng-container *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"></ng-container>
                    <ng-container
                      *ngTemplateOutlet="tradingSuspension; context: { $implicit: currency }"
                    ></ng-container>
                    <ng-container *ngTemplateOutlet="tokenUpdate; context: { $implicit: currency }"></ng-container>
                  </div>
                </div>
              </td>
              <td class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="font-bold">
                    {{ currency.available | appCurrencyFloor : currency.name | async }}&nbsp;{{ currency.name }}
                  </div>
                  <ng-container *ngIf="!getCurrencyEventByType(currency, CurrencyEventType.Listing)">
                    <div
                      *ngIf="currency.name !== 'CZK' && currency.name !== 'EUR'"
                      class="text-dimmed text-sm whitespace-normal"
                    >
                      <app-currency-value
                        [value]="currency.fiatEquivalentsAvailable.CZK"
                        [currency]="'CZK'"
                      ></app-currency-value>
                      ≈&nbsp;{{ currency.available | appCurrencyFloor : currency.name | async }}&nbsp;{{
                        currency.name
                      }}
                    </div>
                    <div
                      *ngIf="
                        currency.name !== 'CZK' && currency.name !== 'EUR' && currency.lastPrices.CZK as lastPriceCZK
                      "
                      class="text-sm font-bold"
                    >
                      <app-currency-value [value]="lastPriceCZK" [currency]="'CZK'"></app-currency-value>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>

    <ng-template #noData>
      <div class="no-data">
        {{ (searchTermSubject.value ? 'currencies.not-found' : 'shared.common.no-data') | translate }}
      </div>
    </ng-template>

    <ng-template #onlyBuySellInfo let-currency>
      <ng-container *ngIf="!currency.depositActive || !currency.withdrawalActive">
        <app-ribbon [appRichTooltip]="blockedDepositWithdrawalTooltipTemplate" [stopEventPropagation]="true">
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.only-buy-sell' | translate }}
        </app-ribbon>

        <ng-template #blockedDepositWithdrawalTooltipTemplate>
          <app-rich-tooltip-header>{{
            'currencies.blocked-deposit-withdrawal.title' | translate
          }}</app-rich-tooltip-header>
          <app-rich-tooltip-body>{{
            'currencies.blocked-deposit-withdrawal.message' | translate
          }}</app-rich-tooltip-body>
          <app-rich-tooltip-footer></app-rich-tooltip-footer>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template #priceUpdateInfo>
      <svg-icon
        class="inline-block text-cm-grey-300 dark:text-inherit"
        src="/assets/icons/12/info-circle.svg"
        [appRichTooltip]="priceUpdateInfoTooltipTemplate"
        [tooltipPosition]="'leftBottom'"
      ></svg-icon>

      <ng-template #priceUpdateInfoTooltipTemplate>
        <app-rich-tooltip-header>{{ 'currencies.price-info.title' | translate }}</app-rich-tooltip-header>
        <app-rich-tooltip-body>
          <div [innerHTML]="'currencies.price-info.message' | translate"></div>
        </app-rich-tooltip-body>
        <app-rich-tooltip-footer></app-rich-tooltip-footer>
      </ng-template>
    </ng-template>

    <ng-template #tradingSuspension let-currency>
      <ng-container *ngIf="tradingSuspensionSonic && currency.name === 'S'">
        <app-ribbon [appRichTooltip]="tradingSuspensionTooltipTemplate" [stopEventPropagation]="true">
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.trading-suspension.label' | translate }}
        </app-ribbon>

        <ng-template #tradingSuspensionTooltipTemplate>
          <app-rich-tooltip-header>{{ 'currencies.trading-suspension.title' | translate }}</app-rich-tooltip-header>
          <app-rich-tooltip-body>
            <div [innerHTML]="'currencies.trading-suspension.message' | translate"></div>
          </app-rich-tooltip-body>
          <app-rich-tooltip-footer></app-rich-tooltip-footer>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template #tokenUpdate let-currency>
      <ng-container *ngIf="currency.name === 'S'">
        <app-ribbon [appRichTooltip]="tokenUpdateTooltipTemplate" [stopEventPropagation]="true">
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.token-update.label' | translate }}
        </app-ribbon>

        <ng-template #tokenUpdateTooltipTemplate>
          <app-rich-tooltip-header>{{ 'currencies.token-update.title' | translate }}</app-rich-tooltip-header>
          <app-rich-tooltip-body>
            <div
              [innerHTML]="
                'currencies.token-update.message'
                  | translate : { token: 'Fantom (FTM)', tokenNew: 'Sonic (S)', url: 'sonic-fantom-kurz' }
              "
            ></div>
          </app-rich-tooltip-body>
          <app-rich-tooltip-footer></app-rich-tooltip-footer>
        </ng-template>
      </ng-container>
    </ng-template>

    <app-currencies-show-more-button
      *ngIf="!(searchTermSubject | async)"
      class="mt-7"
      [currencies$]="currencies$"
      [currenciesLimited$]="currenciesLimited$"
      (click)="showAllCurrencies()"
    ></app-currencies-show-more-button>
  </app-base-modal-body>
</app-base-modal>
