import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { UserInfo } from '@app/shared/store/user-info/user-info-facade.service';
import { LanguageService } from '@app/shared/services/language.service';

export enum DWErrorTypes {
  verificationLimitExceededException = 'VerificationLimitExceededException',
  thePayApiException = 'ThePayApiException',
  thePayWithdrawalException = 'ThePayWithdrawalException',
  confirmoApiException = 'ConfirmoApiException',
  lightsparkApiException = 'LightsparkSdkException',
  checkoutApiException = 'CheckoutApiException',
  amountIsUnderMinLimitException = 'AmountIsUnderMinLimitException',
  virtualCurrencyInvalidWalletAddressException = 'VirtualCurrencyInvalidWalletAddressException',
  virtualCurrencyInvalidWalletAddressExceptionExpired = 'VirtualCurrencyInvalidWalletAddressExceptionExpired',
  virtualCurrencyInvalidWalletAddressExceptionSelfPayment = 'VirtualCurrencyInvalidWalletAddressExceptionSelfPayment',
  virtualCurrencyInvalidWalletAddressExceptionAlreadyPaid = 'VirtualCurrencyInvalidWalletAddressExceptionAlreadyPaid',
  virtualCurrencyInvalidWalletAddressSameAsHotWalletException = 'VirtualCurrencyInvalidWalletAddressSameAsHotWalletException',
  virtualCurrencyInvalidDestinationTagException = 'VirtualCurrencyInvalidDestinationTagException',
  noAvailableVirtualCurrencyAddressException = 'NoAvailableVirtualCurrencyAddressException',
  permanentAddressExistsException = 'PermanentAddressExistsException',
  insufficientBalanceException = 'InsufficientBalanceException',
  insufficientBalanceMarginException = 'InsufficientBalanceMarginException',
  missingAmountInInvoiceException = 'MissingAmountInInvoiceException',
  blockedWithdrawException = 'BlockedWithdrawException',
  blockedDepositException = 'BlockedDepositException',
  tooManyDecimalPlacesException = 'TooManyDecimalPlacesException',
  coindCommunicationException = 'CoindCommunicationException',
  insufficientUserVerificationLevelException = 'InsufficientUserVerificationLevelException',
  monthLimitExceededException = 'MonthLimitExceededException',
  notSupportedCountryException = 'NotSupportedCountryException',
  invalidIbanException = 'InvalidIbanException',
  ibanCountryCodeNotMatchException = 'IbanCountryCodeNotMatchException',
  virtualCurrencyAddressIsCoinmateWalletException = 'VirtualCurrencyAddressIsCoinmateWalletException',
  cryptoWdrAfterLastFiatDepoBlockedException = 'CryptoWdrAfterLastFiatDepoBlockedException',
  paymentMethodBlockedException = 'PaymentMethodBlockedException',
  gopayApiException = 'GopayApiException',
  lastTimeNewVirtualCurrencyAddressLimitException = 'LastTimeNewVirtualCurrencyAddressLimitException',
  amountIsOverMax24hLimitException = 'AmountIsOverMax24hLimitException',
  czkWithdrawNotInTemplatesException = 'CzkWithdrawNotInTemplatesException',
}

export interface DWErrorAdditionalInfo {
  reason?: string;
  blockedUntil?: string;
}

export const globalHandledDWErrors: DWErrorTypes[] = [
  DWErrorTypes.verificationLimitExceededException,
  DWErrorTypes.thePayApiException,
  DWErrorTypes.thePayWithdrawalException,
  DWErrorTypes.confirmoApiException,
  DWErrorTypes.lightsparkApiException,
  DWErrorTypes.checkoutApiException,
  DWErrorTypes.amountIsUnderMinLimitException,
  DWErrorTypes.virtualCurrencyInvalidWalletAddressException,
  DWErrorTypes.virtualCurrencyInvalidWalletAddressSameAsHotWalletException,
  DWErrorTypes.virtualCurrencyInvalidDestinationTagException,
  DWErrorTypes.noAvailableVirtualCurrencyAddressException,
  DWErrorTypes.permanentAddressExistsException,
  DWErrorTypes.insufficientBalanceException,
  DWErrorTypes.insufficientBalanceMarginException,
  DWErrorTypes.missingAmountInInvoiceException,
  DWErrorTypes.blockedDepositException,
  DWErrorTypes.blockedWithdrawException,
  DWErrorTypes.tooManyDecimalPlacesException,
  DWErrorTypes.coindCommunicationException,
  DWErrorTypes.insufficientUserVerificationLevelException,
  DWErrorTypes.monthLimitExceededException,
  DWErrorTypes.notSupportedCountryException,
  DWErrorTypes.invalidIbanException,
  DWErrorTypes.ibanCountryCodeNotMatchException,
  DWErrorTypes.virtualCurrencyAddressIsCoinmateWalletException,
  DWErrorTypes.cryptoWdrAfterLastFiatDepoBlockedException,
  DWErrorTypes.paymentMethodBlockedException,
  DWErrorTypes.gopayApiException,
  DWErrorTypes.amountIsOverMax24hLimitException,
  DWErrorTypes.czkWithdrawNotInTemplatesException,
];

@Injectable({ providedIn: 'root' })
export class ErrorMappingService {
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private userInfo: UserInfo,
  ) {}

  getErrorMessage(errorType: DWErrorTypes, additionalInfo: DWErrorAdditionalInfo | null | undefined = null): string {
    // Sometimes BE returns generic exception and detail in additionalInfo, here we map it to FE errors
    switch (errorType) {
      case DWErrorTypes.virtualCurrencyInvalidWalletAddressException:
        if (additionalInfo && additionalInfo.reason === 'INVOICE_EXPIRED') {
          errorType = DWErrorTypes.virtualCurrencyInvalidWalletAddressExceptionExpired;
        } else if (additionalInfo && additionalInfo.reason === 'SELF_PAYMENT') {
          errorType = DWErrorTypes.virtualCurrencyInvalidWalletAddressExceptionSelfPayment;
        } else if (additionalInfo && additionalInfo.reason === 'INVOICE_ALREADY_PAID') {
          errorType = DWErrorTypes.virtualCurrencyInvalidWalletAddressExceptionAlreadyPaid;
        }
        break;
    }

    const datePipe = new LocalizedDatePipe(this.languageService, this.userInfo);
    const errorMessages: { [key in DWErrorTypes]?: string } = {
      [DWErrorTypes.lastTimeNewVirtualCurrencyAddressLimitException]: this.translate.instant(
        'error.deposit-withdrawal.lastTimeNewVirtualCurrencyAddressLimit',
      ),
      [DWErrorTypes.verificationLimitExceededException]: this.translate.instant(
        'error.deposit-withdrawal.verificationLimitExceededException',
      ),
      [DWErrorTypes.thePayApiException]: this.translate.instant('error.deposit-withdrawal.thePayApiException'),
      [DWErrorTypes.thePayWithdrawalException]: this.translate.instant(
        'error.deposit-withdrawal.thePayWithdrawalException',
      ),
      [DWErrorTypes.confirmoApiException]: this.translate.instant('error.deposit-withdrawal.confirmoApiException'),
      [DWErrorTypes.lightsparkApiException]: this.translate.instant('error.deposit-withdrawal.lightsparkApiException'),
      [DWErrorTypes.checkoutApiException]: this.translate.instant('error.deposit-withdrawal.checkoutApiException'),
      [DWErrorTypes.amountIsUnderMinLimitException]: this.translate.instant(
        'error.deposit-withdrawal.amountIsUnderMinLimitException',
      ),
      [DWErrorTypes.virtualCurrencyInvalidWalletAddressException]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyInvalidWalletAddressException',
      ),
      [DWErrorTypes.virtualCurrencyInvalidWalletAddressExceptionExpired]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyInvalidWalletAddressExceptionExpired',
      ),
      [DWErrorTypes.virtualCurrencyInvalidWalletAddressExceptionSelfPayment]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyInvalidWalletAddressExceptionSelfPayment',
      ),
      [DWErrorTypes.virtualCurrencyInvalidWalletAddressExceptionAlreadyPaid]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyInvalidWalletAddressExceptionAlreadyPaid',
      ),
      [DWErrorTypes.virtualCurrencyInvalidWalletAddressSameAsHotWalletException]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyInvalidWalletAddressSameAsHotWalletException',
      ),
      [DWErrorTypes.virtualCurrencyInvalidDestinationTagException]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyInvalidDestinationTagException',
      ),
      [DWErrorTypes.noAvailableVirtualCurrencyAddressException]: this.translate.instant(
        'error.deposit-withdrawal.noAvailableVirtualCurrencyAddressException',
      ),
      [DWErrorTypes.permanentAddressExistsException]: this.translate.instant(
        'error.deposit-withdrawal.permanentAddressExistsException',
      ),
      [DWErrorTypes.insufficientBalanceException]: this.translate.instant(
        'error.deposit-withdrawal.insufficientBalanceException',
      ),
      [DWErrorTypes.insufficientBalanceMarginException]: this.translate.instant(
        'error.deposit-withdrawal.insufficientBalanceMarginException',
      ),
      [DWErrorTypes.missingAmountInInvoiceException]: this.translate.instant(
        'error.deposit-withdrawal.missingAmountInInvoiceException',
      ),
      [DWErrorTypes.blockedWithdrawException]: this.translate.instant(
        'error.deposit-withdrawal.blockedWithdrawException',
      ),
      [DWErrorTypes.blockedDepositException]: this.translate.instant(
        'error.deposit-withdrawal.blockedDepositException',
      ),
      [DWErrorTypes.tooManyDecimalPlacesException]: this.translate.instant(
        'error.deposit-withdrawal.tooManyDecimalPlacesException',
      ),
      [DWErrorTypes.coindCommunicationException]: this.translate.instant(
        'error.deposit-withdrawal.coindCommunicationException',
      ),
      [DWErrorTypes.insufficientUserVerificationLevelException]: this.translate.instant(
        'error.deposit-withdrawal.insufficientUserVerificationLevelException',
      ),
      [DWErrorTypes.monthLimitExceededException]: this.translate.instant(
        'error.deposit-withdrawal.monthLimitExceededException',
      ),
      [DWErrorTypes.notSupportedCountryException]: this.translate.instant(
        'error.deposit-withdrawal.notSupportedCountryException',
      ),
      [DWErrorTypes.invalidIbanException]: this.translate.instant('error.deposit-withdrawal.invalidIbanException'),
      [DWErrorTypes.ibanCountryCodeNotMatchException]: this.translate.instant(
        'error.deposit-withdrawal.ibanCountryCodeNotMatchException',
      ),
      [DWErrorTypes.virtualCurrencyAddressIsCoinmateWalletException]: this.translate.instant(
        'error.deposit-withdrawal.virtualCurrencyAddressIsCoinmateWalletException',
      ),
      [DWErrorTypes.cryptoWdrAfterLastFiatDepoBlockedException]: this.translate.instant(
        'error.deposit-withdrawal.cryptoWdrAfterLastFiatDepoBlockedException',
        {
          // todo: correct types
          // @ts-ignore
          blockedUntil: datePipe.transform(additionalInfo?.blockedUntil ?? '', 'medium'),
        },
      ),
      [DWErrorTypes.paymentMethodBlockedException]: this.translate.instant(
        'error.deposit-withdrawal.paymentMethodBlockedException',
      ),
      [DWErrorTypes.gopayApiException]: this.translate.instant('error.deposit-withdrawal.gopayApiException'),
      [DWErrorTypes.amountIsOverMax24hLimitException]: this.translate.instant(
        'error.deposit-withdrawal.amountIsOverMax24hLimitException',
      ),
      [DWErrorTypes.czkWithdrawNotInTemplatesException]: this.translate.instant(
        'error.deposit-withdrawal.czkWithdrawNotInTemplatesException',
      ),
    };

    return errorMessages[errorType] || this.translate.instant('error.unknown');
  }
}
