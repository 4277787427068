import { createAction, props } from '@ngrx/store';

import { CurrencyPairDto } from '@app/generated/models/currency-pair-dto';
import { OrderBookDto } from '@app/generated/models/order-book-dto';
import { TradeItemDto } from '@app/generated/models/trade-item-dto';
import { AccountDto } from '@app/generated/models/account-dto';
import { TradeStatisticsDto } from '@app/generated/models/trade-statistics-dto';
import { OpenOrderDto } from '@app/generated/models/open-order-dto';
import { OpenOrder, TradeItem } from '@app/shared/services/pushpin.service';
import { OrderFormDto } from '@app/generated/models/order-form-dto';
import { CurrencyTradeLimitDto } from '@app/generated/models/currency-trade-limit-dto';
import { OrderFormValues } from '@app/authenticated/trading-platform/store/trading-platform.state';

//region Reset store
export const resetStore = createAction('[TradingPlatform] Reset store');
//endregion

//region Account ID
// TODO: Temporary solution. It should be stored in its own store, such as UserInfo
export const setAccount = createAction('[TradingPlatform] Set account', props<{ account: AccountDto }>());
//endregion

//region Currency Pair
export const setCurrencyPairName = createAction(
  '[TradingPlatform] Set currency pair name to trade',
  props<{ currencyPairName: CurrencyPairDto['name'] }>(),
);
//endregion

//region Currency Trade Limits
export const loadCurrencyTradeLimits = createAction('[TradingPlatform] Load currency trade limits');

export const loadCurrencyTradeLimitsSuccess = createAction(
  '[TradingPlatform] Load currency trade limits: Success',
  props<{ data: CurrencyTradeLimitDto[] }>(),
);

export const loadCurrencyTradeLimitsFailure = createAction(
  '[TradingPlatform] Load currency trade limits: Failure',
  props<{ error: string }>(),
);
//endregion

//region Last Trades
export const loadLastTradesForSelectedPairLoading = createAction(
  '[TradingPlatform] Load last trades for selected pair: Loading',
);

export const loadLastTradesForSelectedPairSuccess = createAction(
  '[TradingPlatform] Load last trades for selected pair: Success',
  props<{ data: TradeItemDto[] }>(),
);

export const loadLastTradesForSelectedPairFailure = createAction(
  '[TradingPlatform] Load last trades for selected pair: Failure',
  props<{ error: string }>(),
);

export const updateLastTradesForSelectedPairSuccess = createAction(
  '[TradingPlatform] Update last trades for selected pair: Success',
  props<{ data: TradeItemDto[] }>(),
);

export const updateLastTradesForSelectedPairFailure = createAction(
  '[TradingPlatform] Update last trades for selected pair: Failure',
  props<{ error: string }>(),
);
//endregion

//region Open Orders
export const loadOpenOrdersForSelectedPairLoading = createAction(
  '[TradingPlatform] Load open orders for selected pair: Loading',
);

export const loadOpenOrdersForSelectedPairSuccess = createAction(
  '[TradingPlatform] Load open orders for selected pai: Success',
  props<{ data: OpenOrderDto[] }>(),
);

export const loadOpenOrdersForSelectedPairFailure = createAction(
  '[TradingPlatform] Load open orders for selected pai: Failure',
  props<{ error: string }>(),
);

export const updateOpenOrdersForSelectedPairSuccess = createAction(
  '[TradingPlatform] Update open orders for selected pai: Success',
  props<{
    data: OpenOrder;
  }>(),
);

export const updateOpenOrdersForSelectedPairFailure = createAction(
  '[TradingPlatform] Update open orders for selected pai: Failure',
  props<{ error: string }>(),
);
//endregion

//region Order Book
export const loadOrderBookForSelectedPairLoading = createAction(
  '[TradingPlatform] Load order book for selected pair: Loading',
);

export const loadOrderBookForSelectedPairSuccess = createAction(
  '[TradingPlatform] Load order book for selected pair: Success',
  props<{ data: OrderBookDto }>(),
);

export const loadOrderBookForSelectedPairFailure = createAction(
  '[TradingPlatform] Load order book for selected pair: Failure',
  props<{ error: string }>(),
);

export const updateOrderBookForSelectedPairSuccess = createAction(
  '[TradingPlatform] Update order book for selected pair: Success',
  props<{ data: OrderBookDto }>(),
);

export const updateOrderBookForSelectedPairFailure = createAction(
  '[TradingPlatform] Update order book for selected pair: Failure',
  props<{ error: string }>(),
);
//endregion

//region Trades History
export const loadTradesHistoryForSelectedPairLoading = createAction(
  '[TradingPlatform] Load trades history for selected pair: Loading',
);

export const loadTradesHistoryForSelectedPairSuccess = createAction(
  '[TradingPlatform] Load trades history for selected pair: Success',
  props<{ data: TradeItemDto[] }>(),
);

export const loadTradesHistoryForSelectedPairFailure = createAction(
  '[TradingPlatform] Load trades history for selected pair: Failure',
  props<{ error: string }>(),
);

export const updateTradesHistoryForSelectedPairSuccess = createAction(
  '[TradingPlatform] Update trades history for selected: Success',
  props<{
    data: TradeItem[];
  }>(),
);

export const updateTradesHistoryForSelectedPairFailure = createAction(
  '[TradingPlatform] Update trades history for selected pair: Failure',
  props<{ error: string }>(),
);
//endregion

//region Trade Statistics
export const loadTradesStatisticsForSelectedPairLoading = createAction(
  '[TradingPlatform] Load trades statistics: Loading',
);

export const loadTradesStatisticsForSelectedPairSuccess = createAction(
  '[TradingPlatform] Load trades statistics: Success',
  props<{
    data: TradeStatisticsDto;
  }>(),
);

export const loadTradesStatisticsForSelectedPairFailure = createAction(
  '[TradingPlatform] Load trades statistics: Failure',
  props<{ error: string }>(),
);

export const updateTradesStatisticsForSelectedPairSuccess = createAction(
  '[TradingPlatform] Update trades statistics for selected pair: Success',
  props<{
    data: TradeStatisticsDto;
  }>(),
);

export const updateTradesStatisticsForSelectedPairFailure = createAction(
  '[TradingPlatform] Update trades statistics for selected pair: Failure',
  props<{ error: string }>(),
);
//endregion

//region Compute buy order
export const computeBuyOrderFormDataForSelectedPair = createAction(
  '[TradingPlatform] Compute buy order form data for selected pair',
  props<{
    accountId: AccountDto['id'];
    currencyPair: CurrencyPairDto;
    order: OrderFormDto;
    force?: boolean;
  }>(),
);

export const computeBuyOrderFormDataSelectedPairSuccess = createAction(
  '[TradingPlatform] Compute buy order form data for selected pair: Success',
  props<{ data: OrderFormValues | null }>(),
);

export const computeBuyOrderFormDataForSelectedPairFailure = createAction(
  '[TradingPlatform] Compute buy order form data for selected pair: Failure',
  props<{ error: string }>(),
);
//endregion

//region Compute sell order
export const computeSellOrderFormDataForSelectedPair = createAction(
  '[TradingPlatform] Compute sell order form data for selected pair',
  props<{
    accountId: AccountDto['id'];
    currencyPair: CurrencyPairDto;
    order: OrderFormDto;
    force?: boolean;
  }>(),
);

export const computeSellOrderFormDataSelectedPairSuccess = createAction(
  '[TradingPlatform] Compute sell order form data for selected pair: Success',
  props<{ data: OrderFormValues | null }>(),
);

export const computeSellOrderFormDataForSelectedPairFailure = createAction(
  '[TradingPlatform] Compute sell order form data for selected pair: Failure',
  props<{ error: string }>(),
);
//endregion
