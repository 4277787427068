import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rich-tooltip-body',
  standalone: true,
  imports: [],
  templateUrl: './rich-tooltip-body.component.html',
  styleUrl: '../../rich-tooltip.component.scss',
  host: {
    '[class.rich-tooltip-body]': 'true',
  },
  encapsulation: ViewEncapsulation.None,
})
export class RichTooltipBodyComponent {}
