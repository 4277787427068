import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { VideoPlayerComponent } from '@app/shared/components/video-player/video-player.component';

@Component({
  selector: 'homepage-youtube-video',
  templateUrl: './youtube-video-modal.component.html',
})
export class YoutubeVideoModalComponent implements AfterViewInit {
  @Input() modalId = '';
  @Input() modalData: {
    modalTitle: string;
    videoYoutubeId: string;
    thumbnailSrc: string;
  } = {
    modalTitle: '',
    videoYoutubeId: '',
    thumbnailSrc: '',
  };

  @ViewChild('videoPlayer') videoPlayerRef!: VideoPlayerComponent;

  ngAfterViewInit() {
    if (this.videoPlayerRef) {
      this.videoPlayerRef.play();
    }
  }
}
