import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { v4 as uuidv4 } from 'uuid';
import { ComponentPortal } from '@angular/cdk/portal';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  TooltipPosition,
  TooltipTriggerMode,
  TooltipVisibilityThreshold,
} from '@app/shared/components/tooltip/tooltip.enum';
import { DESKTOP_BREAKPOINT } from '@app/shared/components/tooltip/tooltip.const';

@Directive()
export abstract class BaseTooltipDirective<T> implements OnInit, OnDestroy {
  @Input() tooltipPosition: keyof typeof TooltipPosition = TooltipPosition.rightBottom;
  @Input() tooltipVisibilityThreshold!: keyof typeof TooltipVisibilityThreshold;
  @Input() disabledOnMobile = false;

  /**
   * stopEventPropagation - A custom attribute used to prevent the propagation of events
   * when the user interacts with a tooltip (e.g., mouseover or click).
   */
  @Input() stopEventPropagation? = false;

  protected injector = inject(Injector);
  protected renderer = inject(Renderer2);
  protected trigger = inject(ElementRef);
  protected overlay = inject(Overlay);
  protected viewContainerRef = inject(ViewContainerRef);
  protected elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  protected overlayRef: OverlayRef | null = null;
  protected tooltipTriggerMode: keyof typeof TooltipTriggerMode = TooltipTriggerMode.hover;
  protected tooltipId = uuidv4();
  protected tooltipComponentRef: ComponentRef<T> | null = null;
  protected tooltipComponentPortalRef: ComponentPortal<T> | null = null;
  protected mobileViewportSubject = new BehaviorSubject(false);
  protected isMobileDevice = false;

  private deviceService = inject(DeviceDetectorService);
  private modifiedAttributes: string[] = [];

  constructor() {
    this.isMobileDevice = !this.deviceService.isDesktop();
  }

  abstract createTooltip(): void;

  private openTooltip() {
    if (this.isTooltipDisabled()) {
      return;
    }

    this.createTooltip();

    this.updateAriaAttributes();
  }

  private readonly eventHandlers = {
    enter: () => {
      if (!this.isTooltipTriggeredByClick()) {
        this.openTooltip();
      }
    },

    leave: () => {
      if (!this.isTooltipTriggeredByClick()) {
        this.destroyTooltip();
      }
    },

    press: (event: Event) => {
      if (this.isTooltipTriggeredByClick()) {
        if (this.stopEventPropagation) {
          event.preventDefault();
          event.stopPropagation();
        }

        this.toggleTooltip();
      }
    },
  };

  @HostListener('mouseenter')
  @HostListener('focus')
  mouseenter() {
    this.eventHandlers.enter();
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  mouseleave() {
    this.eventHandlers.leave();
  }

  @HostListener('click', ['$event'])
  click(event: Event) {
    this.eventHandlers.press(event);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.eventHandlers.press(event);
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.destroyTooltip();
    this.updateAriaAttributes();

    if (typeof window !== 'undefined') {
      this.setMobileViewport(window.innerWidth);
    }
  }

  ngOnInit() {
    if (this.isMobileDevice) {
      this.tooltipTriggerMode = TooltipTriggerMode.click;
    }

    if (typeof window !== 'undefined') {
      this.setMobileViewport(window.innerWidth);
    }

    this.updateAriaAttributes();
  }

  ngOnDestroy() {
    this.destroyTooltip();
  }

  protected isTooltipAttached() {
    return !!this.overlayRef?.hasAttached();
  }

  protected isTooltipTriggeredByClick() {
    return this.tooltipTriggerMode === TooltipTriggerMode.click;
  }

  private isTooltipDisabled() {
    if (this.isMobileDevice && this.disabledOnMobile) {
      return true;
    }

    if (typeof window === 'undefined' || !this.tooltipVisibilityThreshold) {
      return false;
    }

    return TooltipVisibilityThreshold[this.tooltipVisibilityThreshold] > window.innerWidth;
  }

  protected destroyTooltip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.tooltipComponentRef = null;
      this.tooltipComponentPortalRef = null;

      this.updateAriaAttributes();
    }
  }

  protected updateAriaAttributes() {
    const el = this.elementRef.nativeElement;
    const isTooltipAttached = this.isTooltipAttached();

    if (this.isTooltipDisabled()) {
      this.renderer.removeAttribute(el, 'data-tooltip');
      this.renderer.removeAttribute(el, 'aria-describedby');
      this.renderer.removeAttribute(el, 'aria-expanded');
      this.renderer.removeAttribute(el, 'aria-haspopup');

      this.modifiedAttributes.forEach((attribute) => {
        this.renderer.removeAttribute(el, attribute);
      });

      return;
    }

    if (isTooltipAttached) {
      this.renderer.setAttribute(el, 'aria-describedby', this.tooltipId);
    } else {
      this.renderer.removeAttribute(el, 'aria-describedby');
    }

    if (this.isTooltipTriggeredByClick()) {
      this.renderer.setAttribute(el, 'data-tooltip', 'click');

      if (!el.hasAttribute('tabindex')) {
        this.modifiedAttributes.push('tabindex');
        this.renderer.setAttribute(el, 'tabindex', '0');
      }

      if (!el.hasAttribute('role')) {
        this.modifiedAttributes.push('role');
        this.renderer.setAttribute(el, 'role', 'button');
      }

      this.renderer.setAttribute(el, 'aria-expanded', isTooltipAttached ? 'true' : 'false');
      this.renderer.setAttribute(el, 'aria-haspopup', 'true');
    } else {
      this.renderer.setAttribute(el, 'data-tooltip', 'hover');

      this.modifiedAttributes.forEach((attribute) => {
        this.renderer.removeAttribute(el, attribute);
      });

      this.renderer.removeAttribute(el, 'aria-expanded');
      this.renderer.removeAttribute(el, 'aria-haspopup');
    }
  }

  private toggleTooltip() {
    if (this.isTooltipAttached()) {
      this.destroyTooltip();
    } else {
      this.openTooltip();
    }
  }

  private setMobileViewport(width: number) {
    const isMobileViewport = width < DESKTOP_BREAKPOINT;
    this.mobileViewportSubject.next(isMobileViewport);
  }
}
