<div class="cm-team-member">
  <div class="cm-team-member-inner">
    <div class="person">
      <img src="{{ teamMember.photo }}" alt="{{ teamMember.name }}" />
      <div class="name">
        <h3>{{ teamMember.name }}</h3>
        <span class="info">{{ teamMember.shortDescription | translate }}</span>
      </div>
    </div>
    <p class="cm-paragraph">{{ teamMember.longDescription | translate }}</p>
    <div class="w-full">
      <app-link-pair
        *ngFor="let social of teamMember.socials; last as isLast"
        appearance="no-label inline-block text-[16px] mb-0"
        linkAppearance="cm-team-member-link {{ isLast && 'last' }}"
        addressHref="{{ social.link }}"
        addressLabel="{{ social.name }}"
        addressIcon="/assets/img/arrow-right.svg"
        [openInNewWindow]="true"
      ></app-link-pair>
    </div>
  </div>
</div>
