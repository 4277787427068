import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailRegexp } from '@app/shared/util';
import { take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WordpressContentApiService } from '@app/shared/services/wordpress-content-api.service';

enum ApiStateStatus {
  pending = 'pending',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

@Component({
  selector: 'homepage-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  newsletterForm!: FormGroup;
  formState: ApiStateStatus = ApiStateStatus.pending;
  firstAttempt = true;

  constructor(private formBuilder: FormBuilder, protected wordpressService: WordpressContentApiService) {}

  ngOnInit() {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegexp)]],
    });

    this.newsletterForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.formState = ApiStateStatus.pending;
    });
  }

  onSubmit() {
    if (this.newsletterForm.invalid && this.formState !== ApiStateStatus.loading) return;
    this.formState = ApiStateStatus.loading;
    const { email } = this.newsletterForm.value;
    this.wordpressService
      .subscribeToNewsletter(email)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.formState = ApiStateStatus.success;
          this.firstAttempt = true;
        },
        error: () => {
          this.formState = ApiStateStatus.error;
          this.firstAttempt = false;
        },
      });
  }

  protected readonly ApiStateStatus = ApiStateStatus;
}
